/* tslint:disable */
/* eslint-disable */
/**
 * FakturyPlus APIs
 * REST APIs
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddressDetailResponse
 */
export interface AddressDetailResponse {
    /**
     * 
     * @type {string}
     * @memberof AddressDetailResponse
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressDetailResponse
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressDetailResponse
     */
    'streetNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressDetailResponse
     */
    'zip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressDetailResponse
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressDetailResponse
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressDetailResponse
     */
    'fullAddressString'?: string | null;
}
/**
 * 
 * @export
 * @interface AutofillResult
 */
export interface AutofillResult {
    /**
     * 
     * @type {string}
     * @memberof AutofillResult
     */
    'brn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AutofillResult
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AutofillResult
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AutofillResult
     */
    'streetNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AutofillResult
     */
    'zip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AutofillResult
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AutofillResult
     */
    'country'?: string | null;
}
/**
 * 
 * @export
 * @interface AutofillResultResult
 */
export interface AutofillResultResult {
    /**
     * 
     * @type {AutofillResult}
     * @memberof AutofillResultResult
     */
    'data'?: AutofillResult;
    /**
     * 
     * @type {string}
     * @memberof AutofillResultResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface BankAccountDetailResponse
 */
export interface BankAccountDetailResponse {
    /**
     * 
     * @type {string}
     * @memberof BankAccountDetailResponse
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BankAccountDetailResponse
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BankAccountDetailResponse
     */
    'iban'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BankAccountDetailResponse
     */
    'swift'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BankAccountDetailResponse
     */
    'invoicesCount'?: number;
}
/**
 * 
 * @export
 * @interface BankAccountDetailResponsePaginatedResult
 */
export interface BankAccountDetailResponsePaginatedResult {
    /**
     * 
     * @type {number}
     * @memberof BankAccountDetailResponsePaginatedResult
     */
    'currentPage'?: number;
    /**
     * 
     * @type {number}
     * @memberof BankAccountDetailResponsePaginatedResult
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof BankAccountDetailResponsePaginatedResult
     */
    'totalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof BankAccountDetailResponsePaginatedResult
     */
    'pageSize'?: number;
    /**
     * 
     * @type {Array<BankAccountDetailResponse>}
     * @memberof BankAccountDetailResponsePaginatedResult
     */
    'data'?: Array<BankAccountDetailResponse> | null;
    /**
     * 
     * @type {string}
     * @memberof BankAccountDetailResponsePaginatedResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface BankAccountDetailResponseResult
 */
export interface BankAccountDetailResponseResult {
    /**
     * 
     * @type {BankAccountDetailResponse}
     * @memberof BankAccountDetailResponseResult
     */
    'data'?: BankAccountDetailResponse;
    /**
     * 
     * @type {string}
     * @memberof BankAccountDetailResponseResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface ClientDetailResponse
 */
export interface ClientDetailResponse {
    /**
     * 
     * @type {string}
     * @memberof ClientDetailResponse
     */
    'contactPersonName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientDetailResponse
     */
    'brn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientDetailResponse
     */
    'taxId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientDetailResponse
     */
    'vatId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientDetailResponse
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientDetailResponse
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientDetailResponse
     */
    'website'?: string | null;
    /**
     * 
     * @type {AddressDetailResponse}
     * @memberof ClientDetailResponse
     */
    'billingAddress'?: AddressDetailResponse;
    /**
     * 
     * @type {boolean}
     * @memberof ClientDetailResponse
     */
    'usePostalAddress'?: boolean;
    /**
     * 
     * @type {AddressDetailResponse}
     * @memberof ClientDetailResponse
     */
    'postalAddress'?: AddressDetailResponse;
}
/**
 * 
 * @export
 * @interface FindResult
 */
export interface FindResult {
    /**
     * 
     * @type {string}
     * @memberof FindResult
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FindResult
     */
    'link'?: string | null;
}
/**
 * 
 * @export
 * @interface FindResultListResult
 */
export interface FindResultListResult {
    /**
     * 
     * @type {Array<FindResult>}
     * @memberof FindResultListResult
     */
    'data'?: Array<FindResult> | null;
    /**
     * 
     * @type {string}
     * @memberof FindResultListResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface GoogleTokenRequest
 */
export interface GoogleTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof GoogleTokenRequest
     */
    'idToken': string;
}
/**
 * 
 * @export
 * @interface InvoiceDetailResponse
 */
export interface InvoiceDetailResponse {
    /**
     * 
     * @type {string}
     * @memberof InvoiceDetailResponse
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDetailResponse
     */
    'intro'?: string | null;
    /**
     * 
     * @type {IssuerDetailResponse}
     * @memberof InvoiceDetailResponse
     */
    'issuer'?: IssuerDetailResponse;
    /**
     * 
     * @type {ClientDetailResponse}
     * @memberof InvoiceDetailResponse
     */
    'client'?: ClientDetailResponse;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDetailResponse
     */
    'issuanceDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDetailResponse
     */
    'deliveryDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDetailResponse
     */
    'dueDate'?: string;
    /**
     * 
     * @type {BankAccountDetailResponse}
     * @memberof InvoiceDetailResponse
     */
    'bankAccount'?: BankAccountDetailResponse;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDetailResponse
     */
    'variableSymbol'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceDetailResponse
     */
    'isLocked'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceDetailResponse
     */
    'isSent'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceDetailResponse
     */
    'isPaidOff'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceDetailResponse
     */
    'isOverdue'?: boolean;
    /**
     * 
     * @type {Array<InvoicedItemDetailResponse>}
     * @memberof InvoiceDetailResponse
     */
    'items'?: Array<InvoicedItemDetailResponse> | null;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDetailResponse
     */
    'totalPrice'?: number;
}
/**
 * 
 * @export
 * @interface InvoiceDetailResponsePaginatedResult
 */
export interface InvoiceDetailResponsePaginatedResult {
    /**
     * 
     * @type {number}
     * @memberof InvoiceDetailResponsePaginatedResult
     */
    'currentPage'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDetailResponsePaginatedResult
     */
    'totalPages'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDetailResponsePaginatedResult
     */
    'totalCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceDetailResponsePaginatedResult
     */
    'pageSize'?: number;
    /**
     * 
     * @type {Array<InvoiceDetailResponse>}
     * @memberof InvoiceDetailResponsePaginatedResult
     */
    'data'?: Array<InvoiceDetailResponse> | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDetailResponsePaginatedResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface InvoiceDetailResponseResult
 */
export interface InvoiceDetailResponseResult {
    /**
     * 
     * @type {InvoiceDetailResponse}
     * @memberof InvoiceDetailResponseResult
     */
    'data'?: InvoiceDetailResponse;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDetailResponseResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface InvoiceSettingsDetailResponse
 */
export interface InvoiceSettingsDetailResponse {
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceSettingsDetailResponse
     */
    'isLocked'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceSettingsDetailResponse
     */
    'isSent'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceSettingsDetailResponse
     */
    'isPaidOff'?: boolean;
}
/**
 * 
 * @export
 * @interface InvoiceSettingsDetailResponseResult
 */
export interface InvoiceSettingsDetailResponseResult {
    /**
     * 
     * @type {InvoiceSettingsDetailResponse}
     * @memberof InvoiceSettingsDetailResponseResult
     */
    'data'?: InvoiceSettingsDetailResponse;
    /**
     * 
     * @type {string}
     * @memberof InvoiceSettingsDetailResponseResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface InvoicedItemDetailResponse
 */
export interface InvoicedItemDetailResponse {
    /**
     * 
     * @type {string}
     * @memberof InvoicedItemDetailResponse
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InvoicedItemDetailResponse
     */
    'count'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InvoicedItemDetailResponse
     */
    'unit'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InvoicedItemDetailResponse
     */
    'unitPrice'?: number | null;
}
/**
 * 
 * @export
 * @interface IssuerDetailResponse
 */
export interface IssuerDetailResponse {
    /**
     * 
     * @type {string}
     * @memberof IssuerDetailResponse
     */
    'issuerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IssuerDetailResponse
     */
    'brn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IssuerDetailResponse
     */
    'taxId'?: string | null;
    /**
     * 
     * @type {TaxType}
     * @memberof IssuerDetailResponse
     */
    'taxType'?: TaxType;
    /**
     * 
     * @type {string}
     * @memberof IssuerDetailResponse
     */
    'vatId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IssuerDetailResponse
     */
    'registeredIn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IssuerDetailResponse
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IssuerDetailResponse
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IssuerDetailResponse
     */
    'website'?: string | null;
    /**
     * 
     * @type {AddressDetailResponse}
     * @memberof IssuerDetailResponse
     */
    'billingAddress'?: AddressDetailResponse;
}


/**
 * 
 * @export
 * @interface MonthPerformanceResponse
 */
export interface MonthPerformanceResponse {
    /**
     * 
     * @type {number}
     * @memberof MonthPerformanceResponse
     */
    'year'?: number;
    /**
     * 
     * @type {number}
     * @memberof MonthPerformanceResponse
     */
    'month'?: number;
    /**
     * 
     * @type {number}
     * @memberof MonthPerformanceResponse
     */
    'totalPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof MonthPerformanceResponse
     */
    'runningTotal'?: number;
}
/**
 * 
 * @export
 * @interface PerformanceResponse
 */
export interface PerformanceResponse {
    /**
     * 
     * @type {Array<number>}
     * @memberof PerformanceResponse
     */
    'availableYears'?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof PerformanceResponse
     */
    'total'?: number;
    /**
     * 
     * @type {number}
     * @memberof PerformanceResponse
     */
    'paidTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof PerformanceResponse
     */
    'waiting'?: number;
    /**
     * 
     * @type {number}
     * @memberof PerformanceResponse
     */
    'overdue'?: number;
    /**
     * 
     * @type {Array<MonthPerformanceResponse>}
     * @memberof PerformanceResponse
     */
    'months'?: Array<MonthPerformanceResponse> | null;
    /**
     * 
     * @type {Array<InvoiceDetailResponse>}
     * @memberof PerformanceResponse
     */
    'invoices'?: Array<InvoiceDetailResponse> | null;
}
/**
 * 
 * @export
 * @interface PerformanceResponseResult
 */
export interface PerformanceResponseResult {
    /**
     * 
     * @type {PerformanceResponse}
     * @memberof PerformanceResponseResult
     */
    'data'?: PerformanceResponse;
    /**
     * 
     * @type {string}
     * @memberof PerformanceResponseResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface PostAddressRequest
 */
export interface PostAddressRequest {
    /**
     * 
     * @type {string}
     * @memberof PostAddressRequest
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostAddressRequest
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostAddressRequest
     */
    'streetNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostAddressRequest
     */
    'zip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostAddressRequest
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostAddressRequest
     */
    'country'?: string | null;
}
/**
 * 
 * @export
 * @interface PostBankAccountRequest
 */
export interface PostBankAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof PostBankAccountRequest
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof PostBankAccountRequest
     */
    'iban': string;
    /**
     * 
     * @type {string}
     * @memberof PostBankAccountRequest
     */
    'swift': string;
}
/**
 * 
 * @export
 * @interface PostClientRequest
 */
export interface PostClientRequest {
    /**
     * 
     * @type {string}
     * @memberof PostClientRequest
     */
    'contactPersonName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostClientRequest
     */
    'brn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostClientRequest
     */
    'taxId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostClientRequest
     */
    'vatId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostClientRequest
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostClientRequest
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostClientRequest
     */
    'website'?: string | null;
    /**
     * 
     * @type {PostAddressRequest}
     * @memberof PostClientRequest
     */
    'billingAddress'?: PostAddressRequest;
    /**
     * 
     * @type {boolean}
     * @memberof PostClientRequest
     */
    'usePostalAddress'?: boolean;
    /**
     * 
     * @type {PostAddressRequest}
     * @memberof PostClientRequest
     */
    'postalAddress'?: PostAddressRequest;
}
/**
 * 
 * @export
 * @interface PostInvoiceRequest
 */
export interface PostInvoiceRequest {
    /**
     * 
     * @type {string}
     * @memberof PostInvoiceRequest
     */
    'intro'?: string | null;
    /**
     * 
     * @type {PostIssuerRequest}
     * @memberof PostInvoiceRequest
     */
    'issuer'?: PostIssuerRequest;
    /**
     * 
     * @type {PostClientRequest}
     * @memberof PostInvoiceRequest
     */
    'client'?: PostClientRequest;
    /**
     * 
     * @type {string}
     * @memberof PostInvoiceRequest
     */
    'issuanceDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostInvoiceRequest
     */
    'deliveryDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostInvoiceRequest
     */
    'dueDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostInvoiceRequest
     */
    'bankAccountId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostInvoiceRequest
     */
    'variableSymbol': string;
    /**
     * 
     * @type {Array<PostInvoicedItemRequest>}
     * @memberof PostInvoiceRequest
     */
    'items'?: Array<PostInvoicedItemRequest> | null;
}
/**
 * 
 * @export
 * @interface PostInvoicedItemRequest
 */
export interface PostInvoicedItemRequest {
    /**
     * 
     * @type {string}
     * @memberof PostInvoicedItemRequest
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PostInvoicedItemRequest
     */
    'count'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PostInvoicedItemRequest
     */
    'unit'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PostInvoicedItemRequest
     */
    'unitPrice'?: number | null;
}
/**
 * 
 * @export
 * @interface PostIssuerRequest
 */
export interface PostIssuerRequest {
    /**
     * 
     * @type {string}
     * @memberof PostIssuerRequest
     */
    'issuerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostIssuerRequest
     */
    'brn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostIssuerRequest
     */
    'taxId'?: string | null;
    /**
     * 
     * @type {TaxType}
     * @memberof PostIssuerRequest
     */
    'taxType': TaxType;
    /**
     * 
     * @type {string}
     * @memberof PostIssuerRequest
     */
    'vatId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostIssuerRequest
     */
    'registeredIn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostIssuerRequest
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostIssuerRequest
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostIssuerRequest
     */
    'website'?: string | null;
    /**
     * 
     * @type {PostAddressRequest}
     * @memberof PostIssuerRequest
     */
    'billingAddress'?: PostAddressRequest;
}


/**
 * 
 * @export
 * @interface RefreshTokenRequest
 */
export interface RefreshTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenRequest
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenRequest
     */
    'refreshToken': string;
}
/**
 * 
 * @export
 * @interface RegisterRequest
 */
export interface RegisterRequest {
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface Result
 */
export interface Result {
    /**
     * 
     * @type {string}
     * @memberof Result
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SettingProperty = {
    IsLocked: 'IsLocked',
    IsSent: 'IsSent',
    IsPaidOff: 'IsPaidOff'
} as const;

export type SettingProperty = typeof SettingProperty[keyof typeof SettingProperty];


/**
 * 
 * @export
 * @interface StringResult
 */
export interface StringResult {
    /**
     * 
     * @type {string}
     * @memberof StringResult
     */
    'data'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StringResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TaxType = {
    NoTaxes: 'NoTaxes',
    PaysTaxes: 'PaysTaxes',
    RegisteredForTaxes: 'RegisteredForTaxes'
} as const;

export type TaxType = typeof TaxType[keyof typeof TaxType];


/**
 * 
 * @export
 * @interface TokenRequest
 */
export interface TokenRequest {
    /**
     * 
     * @type {string}
     * @memberof TokenRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof TokenRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface TokenResponse
 */
export interface TokenResponse {
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    'token'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TokenResponse
     */
    'tokenTtl'?: number;
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    'refreshToken'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TokenResponse
     */
    'refreshTokenTtl'?: number;
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    'refreshTokenExpiryTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    'imageURL'?: string | null;
}
/**
 * 
 * @export
 * @interface TokenResponseResult
 */
export interface TokenResponseResult {
    /**
     * 
     * @type {TokenResponse}
     * @memberof TokenResponseResult
     */
    'data'?: TokenResponse;
    /**
     * 
     * @type {string}
     * @memberof TokenResponseResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateInvoiceSettingRequest
 */
export interface UpdateInvoiceSettingRequest {
    /**
     * 
     * @type {SettingProperty}
     * @memberof UpdateInvoiceSettingRequest
     */
    'settingProperty': SettingProperty;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateInvoiceSettingRequest
     */
    'settingValue': boolean;
}



/**
 * ApiStatusApi - axios parameter creator
 * @export
 */
export const ApiStatusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns 200
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statusGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiStatusApi - functional programming interface
 * @export
 */
export const ApiStatusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiStatusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns 200
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statusGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statusGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApiStatusApi - factory interface
 * @export
 */
export const ApiStatusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiStatusApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns 200
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statusGet(options?: any): AxiosPromise<Result> {
            return localVarFp.statusGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiStatusApi - object-oriented interface
 * @export
 * @class ApiStatusApi
 * @extends {BaseAPI}
 */
export class ApiStatusApi extends BaseAPI {
    /**
     * 
     * @summary Returns 200
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiStatusApi
     */
    public statusGet(options?: AxiosRequestConfig) {
        return ApiStatusApiFp(this.configuration).statusGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BankAccountsApi - axios parameter creator
 * @export
 */
export const BankAccountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all Bank Accounts for Logged User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BankaccountsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/bankaccounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Bank Account
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BankaccountsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1BankaccountsIdDelete', 'id', id)
            const localVarPath = `/v1/bankaccounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the Bank Account by Id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BankaccountsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1BankaccountsIdGet', 'id', id)
            const localVarPath = `/v1/bankaccounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Bank Account
         * @param {string} id 
         * @param {PostBankAccountRequest} [postBankAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BankaccountsIdPut: async (id: string, postBankAccountRequest?: PostBankAccountRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1BankaccountsIdPut', 'id', id)
            const localVarPath = `/v1/bankaccounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postBankAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new Bank Account for Logged User
         * @param {PostBankAccountRequest} [postBankAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BankaccountsPost: async (postBankAccountRequest?: PostBankAccountRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/bankaccounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postBankAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BankAccountsApi - functional programming interface
 * @export
 */
export const BankAccountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BankAccountsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all Bank Accounts for Logged User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BankaccountsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankAccountDetailResponsePaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BankaccountsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Bank Account
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BankaccountsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BankaccountsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the Bank Account by Id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BankaccountsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankAccountDetailResponseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BankaccountsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Bank Account
         * @param {string} id 
         * @param {PostBankAccountRequest} [postBankAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BankaccountsIdPut(id: string, postBankAccountRequest?: PostBankAccountRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankAccountDetailResponseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BankaccountsIdPut(id, postBankAccountRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new Bank Account for Logged User
         * @param {PostBankAccountRequest} [postBankAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1BankaccountsPost(postBankAccountRequest?: PostBankAccountRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BankAccountDetailResponseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1BankaccountsPost(postBankAccountRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BankAccountsApi - factory interface
 * @export
 */
export const BankAccountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BankAccountsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all Bank Accounts for Logged User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BankaccountsGet(options?: any): AxiosPromise<BankAccountDetailResponsePaginatedResult> {
            return localVarFp.v1BankaccountsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Bank Account
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BankaccountsIdDelete(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.v1BankaccountsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the Bank Account by Id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BankaccountsIdGet(id: string, options?: any): AxiosPromise<BankAccountDetailResponseResult> {
            return localVarFp.v1BankaccountsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Bank Account
         * @param {string} id 
         * @param {PostBankAccountRequest} [postBankAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BankaccountsIdPut(id: string, postBankAccountRequest?: PostBankAccountRequest, options?: any): AxiosPromise<BankAccountDetailResponseResult> {
            return localVarFp.v1BankaccountsIdPut(id, postBankAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new Bank Account for Logged User
         * @param {PostBankAccountRequest} [postBankAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1BankaccountsPost(postBankAccountRequest?: PostBankAccountRequest, options?: any): AxiosPromise<BankAccountDetailResponseResult> {
            return localVarFp.v1BankaccountsPost(postBankAccountRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BankAccountsApi - object-oriented interface
 * @export
 * @class BankAccountsApi
 * @extends {BaseAPI}
 */
export class BankAccountsApi extends BaseAPI {
    /**
     * 
     * @summary Get all Bank Accounts for Logged User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankAccountsApi
     */
    public v1BankaccountsGet(options?: AxiosRequestConfig) {
        return BankAccountsApiFp(this.configuration).v1BankaccountsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Bank Account
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankAccountsApi
     */
    public v1BankaccountsIdDelete(id: string, options?: AxiosRequestConfig) {
        return BankAccountsApiFp(this.configuration).v1BankaccountsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the Bank Account by Id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankAccountsApi
     */
    public v1BankaccountsIdGet(id: string, options?: AxiosRequestConfig) {
        return BankAccountsApiFp(this.configuration).v1BankaccountsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Bank Account
     * @param {string} id 
     * @param {PostBankAccountRequest} [postBankAccountRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankAccountsApi
     */
    public v1BankaccountsIdPut(id: string, postBankAccountRequest?: PostBankAccountRequest, options?: AxiosRequestConfig) {
        return BankAccountsApiFp(this.configuration).v1BankaccountsIdPut(id, postBankAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new Bank Account for Logged User
     * @param {PostBankAccountRequest} [postBankAccountRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankAccountsApi
     */
    public v1BankaccountsPost(postBankAccountRequest?: PostBankAccountRequest, options?: AxiosRequestConfig) {
        return BankAccountsApiFp(this.configuration).v1BankaccountsPost(postBankAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InvoiceSettingsApi - axios parameter creator
 * @export
 */
export const InvoiceSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Update Invoice Setting
         * @param {string} id 
         * @param {UpdateInvoiceSettingRequest} [updateInvoiceSettingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesettingsIdPost: async (id: string, updateInvoiceSettingRequest?: UpdateInvoiceSettingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1InvoicesettingsIdPost', 'id', id)
            const localVarPath = `/v1/invoicesettings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateInvoiceSettingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoiceSettingsApi - functional programming interface
 * @export
 */
export const InvoiceSettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvoiceSettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Update Invoice Setting
         * @param {string} id 
         * @param {UpdateInvoiceSettingRequest} [updateInvoiceSettingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvoicesettingsIdPost(id: string, updateInvoiceSettingRequest?: UpdateInvoiceSettingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceSettingsDetailResponseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1InvoicesettingsIdPost(id, updateInvoiceSettingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvoiceSettingsApi - factory interface
 * @export
 */
export const InvoiceSettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvoiceSettingsApiFp(configuration)
    return {
        /**
         * 
         * @summary Update Invoice Setting
         * @param {string} id 
         * @param {UpdateInvoiceSettingRequest} [updateInvoiceSettingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesettingsIdPost(id: string, updateInvoiceSettingRequest?: UpdateInvoiceSettingRequest, options?: any): AxiosPromise<InvoiceSettingsDetailResponseResult> {
            return localVarFp.v1InvoicesettingsIdPost(id, updateInvoiceSettingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvoiceSettingsApi - object-oriented interface
 * @export
 * @class InvoiceSettingsApi
 * @extends {BaseAPI}
 */
export class InvoiceSettingsApi extends BaseAPI {
    /**
     * 
     * @summary Update Invoice Setting
     * @param {string} id 
     * @param {UpdateInvoiceSettingRequest} [updateInvoiceSettingRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceSettingsApi
     */
    public v1InvoicesettingsIdPost(id: string, updateInvoiceSettingRequest?: UpdateInvoiceSettingRequest, options?: AxiosRequestConfig) {
        return InvoiceSettingsApiFp(this.configuration).v1InvoicesettingsIdPost(id, updateInvoiceSettingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InvoicesApi - axios parameter creator
 * @export
 */
export const InvoicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Clone Invoice
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesCloneIdPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1InvoicesCloneIdPost', 'id', id)
            const localVarPath = `/v1/invoices/clone/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all Invoices for Logged User
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesGet: async (dateFrom?: string, dateTo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Invoice
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1InvoicesIdDelete', 'id', id)
            const localVarPath = `/v1/invoices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the Invoice by Id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1InvoicesIdGet', 'id', id)
            const localVarPath = `/v1/invoices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Invoice
         * @param {string} id 
         * @param {PostInvoiceRequest} [postInvoiceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesIdPut: async (id: string, postInvoiceRequest?: PostInvoiceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1InvoicesIdPut', 'id', id)
            const localVarPath = `/v1/invoices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postInvoiceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Invoice Pdf by Id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesPdfIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1InvoicesPdfIdGet', 'id', id)
            const localVarPath = `/v1/invoices/pdf/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create new Invoice for Logged User
         * @param {PostInvoiceRequest} [postInvoiceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesPost: async (postInvoiceRequest?: PostInvoiceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postInvoiceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get next Variable Symbol for new Invoice
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesVsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/invoices/vs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoicesApi - functional programming interface
 * @export
 */
export const InvoicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvoicesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Clone Invoice
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvoicesCloneIdPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceDetailResponseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1InvoicesCloneIdPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all Invoices for Logged User
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvoicesGet(dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceDetailResponsePaginatedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1InvoicesGet(dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Invoice
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvoicesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1InvoicesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the Invoice by Id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvoicesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceDetailResponseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1InvoicesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Invoice
         * @param {string} id 
         * @param {PostInvoiceRequest} [postInvoiceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvoicesIdPut(id: string, postInvoiceRequest?: PostInvoiceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceDetailResponseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1InvoicesIdPut(id, postInvoiceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Invoice Pdf by Id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvoicesPdfIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1InvoicesPdfIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create new Invoice for Logged User
         * @param {PostInvoiceRequest} [postInvoiceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvoicesPost(postInvoiceRequest?: PostInvoiceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceDetailResponseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1InvoicesPost(postInvoiceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get next Variable Symbol for new Invoice
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1InvoicesVsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1InvoicesVsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvoicesApi - factory interface
 * @export
 */
export const InvoicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvoicesApiFp(configuration)
    return {
        /**
         * 
         * @summary Clone Invoice
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesCloneIdPost(id: string, options?: any): AxiosPromise<InvoiceDetailResponseResult> {
            return localVarFp.v1InvoicesCloneIdPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all Invoices for Logged User
         * @param {string} [dateFrom] 
         * @param {string} [dateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesGet(dateFrom?: string, dateTo?: string, options?: any): AxiosPromise<InvoiceDetailResponsePaginatedResult> {
            return localVarFp.v1InvoicesGet(dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Invoice
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesIdDelete(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.v1InvoicesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the Invoice by Id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesIdGet(id: string, options?: any): AxiosPromise<InvoiceDetailResponseResult> {
            return localVarFp.v1InvoicesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Invoice
         * @param {string} id 
         * @param {PostInvoiceRequest} [postInvoiceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesIdPut(id: string, postInvoiceRequest?: PostInvoiceRequest, options?: any): AxiosPromise<InvoiceDetailResponseResult> {
            return localVarFp.v1InvoicesIdPut(id, postInvoiceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Invoice Pdf by Id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesPdfIdGet(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.v1InvoicesPdfIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create new Invoice for Logged User
         * @param {PostInvoiceRequest} [postInvoiceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesPost(postInvoiceRequest?: PostInvoiceRequest, options?: any): AxiosPromise<InvoiceDetailResponseResult> {
            return localVarFp.v1InvoicesPost(postInvoiceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get next Variable Symbol for new Invoice
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1InvoicesVsGet(options?: any): AxiosPromise<StringResult> {
            return localVarFp.v1InvoicesVsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvoicesApi - object-oriented interface
 * @export
 * @class InvoicesApi
 * @extends {BaseAPI}
 */
export class InvoicesApi extends BaseAPI {
    /**
     * 
     * @summary Clone Invoice
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public v1InvoicesCloneIdPost(id: string, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).v1InvoicesCloneIdPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all Invoices for Logged User
     * @param {string} [dateFrom] 
     * @param {string} [dateTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public v1InvoicesGet(dateFrom?: string, dateTo?: string, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).v1InvoicesGet(dateFrom, dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Invoice
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public v1InvoicesIdDelete(id: string, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).v1InvoicesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the Invoice by Id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public v1InvoicesIdGet(id: string, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).v1InvoicesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Invoice
     * @param {string} id 
     * @param {PostInvoiceRequest} [postInvoiceRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public v1InvoicesIdPut(id: string, postInvoiceRequest?: PostInvoiceRequest, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).v1InvoicesIdPut(id, postInvoiceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Invoice Pdf by Id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public v1InvoicesPdfIdGet(id: string, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).v1InvoicesPdfIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create new Invoice for Logged User
     * @param {PostInvoiceRequest} [postInvoiceRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public v1InvoicesPost(postInvoiceRequest?: PostInvoiceRequest, options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).v1InvoicesPost(postInvoiceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get next Variable Symbol for new Invoice
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoicesApi
     */
    public v1InvoicesVsGet(options?: AxiosRequestConfig) {
        return InvoicesApiFp(this.configuration).v1InvoicesVsGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrsrApi - axios parameter creator
 * @export
 */
export const OrsrApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get autofill data from link
         * @param {string} [link] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OrsrAutofillGet: async (link?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/orsr/autofill`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (link !== undefined) {
                localVarQueryParameter['link'] = link;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search subjects by Business Name
         * @param {string} [businessName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OrsrBnGet: async (businessName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/orsr/bn`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (businessName !== undefined) {
                localVarQueryParameter['businessName'] = businessName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search subjects by BRN
         * @param {string} [brn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OrsrBrnGet: async (brn?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/orsr/brn`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (brn !== undefined) {
                localVarQueryParameter['brn'] = brn;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrsrApi - functional programming interface
 * @export
 */
export const OrsrApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrsrApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get autofill data from link
         * @param {string} [link] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OrsrAutofillGet(link?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AutofillResultResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1OrsrAutofillGet(link, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search subjects by Business Name
         * @param {string} [businessName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OrsrBnGet(businessName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindResultListResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1OrsrBnGet(businessName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search subjects by BRN
         * @param {string} [brn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1OrsrBrnGet(brn?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindResultListResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1OrsrBrnGet(brn, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrsrApi - factory interface
 * @export
 */
export const OrsrApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrsrApiFp(configuration)
    return {
        /**
         * 
         * @summary Get autofill data from link
         * @param {string} [link] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OrsrAutofillGet(link?: string, options?: any): AxiosPromise<AutofillResultResult> {
            return localVarFp.v1OrsrAutofillGet(link, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search subjects by Business Name
         * @param {string} [businessName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OrsrBnGet(businessName?: string, options?: any): AxiosPromise<FindResultListResult> {
            return localVarFp.v1OrsrBnGet(businessName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search subjects by BRN
         * @param {string} [brn] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1OrsrBrnGet(brn?: string, options?: any): AxiosPromise<FindResultListResult> {
            return localVarFp.v1OrsrBrnGet(brn, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrsrApi - object-oriented interface
 * @export
 * @class OrsrApi
 * @extends {BaseAPI}
 */
export class OrsrApi extends BaseAPI {
    /**
     * 
     * @summary Get autofill data from link
     * @param {string} [link] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrsrApi
     */
    public v1OrsrAutofillGet(link?: string, options?: AxiosRequestConfig) {
        return OrsrApiFp(this.configuration).v1OrsrAutofillGet(link, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search subjects by Business Name
     * @param {string} [businessName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrsrApi
     */
    public v1OrsrBnGet(businessName?: string, options?: AxiosRequestConfig) {
        return OrsrApiFp(this.configuration).v1OrsrBnGet(businessName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search subjects by BRN
     * @param {string} [brn] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrsrApi
     */
    public v1OrsrBrnGet(brn?: string, options?: AxiosRequestConfig) {
        return OrsrApiFp(this.configuration).v1OrsrBrnGet(brn, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PerformanceApi - axios parameter creator
 * @export
 */
export const PerformanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Performance for Logged User
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PerformanceGet: async (year?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/performance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PerformanceApi - functional programming interface
 * @export
 */
export const PerformanceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PerformanceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Performance for Logged User
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1PerformanceGet(year?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerformanceResponseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1PerformanceGet(year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PerformanceApi - factory interface
 * @export
 */
export const PerformanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PerformanceApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Performance for Logged User
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1PerformanceGet(year?: number, options?: any): AxiosPromise<PerformanceResponseResult> {
            return localVarFp.v1PerformanceGet(year, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PerformanceApi - object-oriented interface
 * @export
 * @class PerformanceApi
 * @extends {BaseAPI}
 */
export class PerformanceApi extends BaseAPI {
    /**
     * 
     * @summary Get Performance for Logged User
     * @param {number} [year] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformanceApi
     */
    public v1PerformanceGet(year?: number, options?: AxiosRequestConfig) {
        return PerformanceApiFp(this.configuration).v1PerformanceGet(year, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TokenApi - axios parameter creator
 * @export
 */
export const TokenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Tokens (Login using Google)
         * @param {GoogleTokenRequest} [googleTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityTokenGooglePost: async (googleTokenRequest?: GoogleTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/identity/token/google`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(googleTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Tokens (Login using Email and Password)
         * @param {TokenRequest} [tokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityTokenPost: async (tokenRequest?: TokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/identity/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refresh Token
         * @param {RefreshTokenRequest} [refreshTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityTokenRefreshPost: async (refreshTokenRequest?: RefreshTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/identity/token/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refreshTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TokenApi - functional programming interface
 * @export
 */
export const TokenApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TokenApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Tokens (Login using Google)
         * @param {GoogleTokenRequest} [googleTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityTokenGooglePost(googleTokenRequest?: GoogleTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityTokenGooglePost(googleTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Tokens (Login using Email and Password)
         * @param {TokenRequest} [tokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityTokenPost(tokenRequest?: TokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityTokenPost(tokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Refresh Token
         * @param {RefreshTokenRequest} [refreshTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityTokenRefreshPost(refreshTokenRequest?: RefreshTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponseResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityTokenRefreshPost(refreshTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TokenApi - factory interface
 * @export
 */
export const TokenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TokenApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Tokens (Login using Google)
         * @param {GoogleTokenRequest} [googleTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityTokenGooglePost(googleTokenRequest?: GoogleTokenRequest, options?: any): AxiosPromise<TokenResponseResult> {
            return localVarFp.identityTokenGooglePost(googleTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Tokens (Login using Email and Password)
         * @param {TokenRequest} [tokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityTokenPost(tokenRequest?: TokenRequest, options?: any): AxiosPromise<TokenResponseResult> {
            return localVarFp.identityTokenPost(tokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Refresh Token
         * @param {RefreshTokenRequest} [refreshTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityTokenRefreshPost(refreshTokenRequest?: RefreshTokenRequest, options?: any): AxiosPromise<TokenResponseResult> {
            return localVarFp.identityTokenRefreshPost(refreshTokenRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TokenApi - object-oriented interface
 * @export
 * @class TokenApi
 * @extends {BaseAPI}
 */
export class TokenApi extends BaseAPI {
    /**
     * 
     * @summary Get Tokens (Login using Google)
     * @param {GoogleTokenRequest} [googleTokenRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApi
     */
    public identityTokenGooglePost(googleTokenRequest?: GoogleTokenRequest, options?: AxiosRequestConfig) {
        return TokenApiFp(this.configuration).identityTokenGooglePost(googleTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Tokens (Login using Email and Password)
     * @param {TokenRequest} [tokenRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApi
     */
    public identityTokenPost(tokenRequest?: TokenRequest, options?: AxiosRequestConfig) {
        return TokenApiFp(this.configuration).identityTokenPost(tokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Refresh Token
     * @param {RefreshTokenRequest} [refreshTokenRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApi
     */
    public identityTokenRefreshPost(refreshTokenRequest?: RefreshTokenRequest, options?: AxiosRequestConfig) {
        return TokenApiFp(this.configuration).identityTokenRefreshPost(refreshTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Register a User
         * @param {RegisterRequest} [registerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityUsersPost: async (registerRequest?: RegisterRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/identity/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Register a User
         * @param {RegisterRequest} [registerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityUsersPost(registerRequest?: RegisterRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityUsersPost(registerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Register a User
         * @param {RegisterRequest} [registerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityUsersPost(registerRequest?: RegisterRequest, options?: any): AxiosPromise<StringResult> {
            return localVarFp.identityUsersPost(registerRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary Register a User
     * @param {RegisterRequest} [registerRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public identityUsersPost(registerRequest?: RegisterRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).identityUsersPost(registerRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



import React, {useEffect, useState} from "react";
import {
    ActionIcon,
    Box,
    Button,
    Container,
    Divider,
    Flex,
    Grid,
    Group, Loader,
    MediaQuery,
    NumberInput,
    Paper,
    Stack,
    Text,
    Textarea, TextInput,
    Title, Tooltip
} from '@mantine/core';
import {
    IconCalendar,
    IconCreditCard, IconDeviceFloppy,
    IconPdf,
    IconPlus, IconTrash
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { DateInput } from "@mantine/dates";
import {randomId, useDisclosure} from "@mantine/hooks";
import {useNavigate, useParams} from "react-router-dom";
import dayjs from "dayjs";
import {useFakturyPlusApi} from "../../api/FakturyPlusApiContext";
import {PostInvoiceRequest, SettingProperty, TaxType} from "../../api/faktury-plus";
import { showErrorNotification, showSuccessNotification } from "../../helpers/notifications";
import {dateParser, formatNumber} from "../../helpers/format";
import {downloadInvoicePdf} from "../../helpers/invoiceDownloads";
import {ClientDrawer} from "./ClientDrawer";
import { IssuerDrawer } from "./IssuerDrawer";
import {
    EditAddressFormProps,
    EditInvoiceFormProps,
    EditItemFormProps,
    initForm,
    mapInvoiceData
} from "./formDefinition";
import {BankAccountSelect, BankAccountSelectItemType} from "./BankAccountSelect";
import {ibanToBic} from "../../helpers/bicEctractor";
import {CreateBankAccountFormProps, CreateBankAccountModal, initCreateBankAccountForm } from "../BankAccounts";

export interface InvoiceFormProps {
    clone?: boolean;
}

export function InvoiceForm({ clone, ...others }: InvoiceFormProps) {
    const api = useFakturyPlusApi();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();

    const [paidOff, setPaidOff] = useState(false);
    const [clientDrawerOpened, { open: openClientDrawer, close: closeClientDrawer }] = useDisclosure(false);
    const [issuerDrawerOpened, { open: openIssuerDrawer, close: closeIssuerDrawer }] = useDisclosure(false);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);

    const form = initForm(t);

    const [bankAccounts, setBankAccounts] = useState<BankAccountSelectItemType[]>([]);
    const [bankAccountModalOpened, { open: openBankAccountModal, close: closeBankAccountModal }] = useDisclosure(false);
    const bankAccountForm = initCreateBankAccountForm(t);

    const openBankAccountCreationModal = (query: string) => {
        let values: CreateBankAccountFormProps = {
            title: '',
            iban: query,
            swift: ibanToBic(query)
        }

        bankAccountForm.setValues(values);
        // bankAccountForm.resetDirty(values); // netreba

        openBankAccountModal();
    }

    const closeBankAccountCreationModal = (reloadAccounts: boolean, selectedAccountId?: string) => {
        if (reloadAccounts && selectedAccountId) {
            loadBankAccounts(() => {
                form.setFieldValue('bankAccountId', selectedAccountId);
                closeBankAccountModal();
            });
        } else {
            closeBankAccountModal();
        }
    }

    const loadBankAccounts = (onLoaded?: () => void) => {
        api().bankAccountsApi().v1BankaccountsGet().then((response) => {
            setBankAccounts((response.data.data ?? []).map((bankAccount) => {
                return {
                    value: bankAccount.id!,
                    label: bankAccount.title!,
                    iban: bankAccount.iban!,
                }
            }));

            if (onLoaded) {
                onLoaded();
            }
        })
        .catch((error) => {
           console.log(error);
        });
    }

    useEffect(() => {
        if (!params.id) {
            navigate('/invoices');
            return;
        }

        form.setFieldValue('id', params.id ?? 'new');

        if (params.id == 'new') {
            api().invoicesApi().v1InvoicesVsGet()
                .then((response) => {
                    if (response.data.data) {
                        form.setFieldValue('variableSymbol', response.data.data);
                    } else {
                        form.setFieldValue('variableSymbol', `${dayjs().year}0001`);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            api().invoicesApi().v1InvoicesIdGet(params.id as string).then((response) => {
                console.log(response);
                mapInvoiceData(form, response.data.data, clone, !clone);
                setPaidOff(response.data.data?.isPaidOff ?? false);
            }).catch((error) => {
                if ([403, 404].includes(error.response.status)) {
                    navigate('/invoices');
                }

                showErrorNotification(t('Nastala neočakávaná chyba'), t('Nepodarilo sa načítať faktúru.'), 'invoiceform-load-error', () => navigate('/invoices'));
            }).finally(() => {
                setLoading(false);
            });
        }

        loadBankAccounts();
    }, []);


    const itemTotalPrice = (item: EditItemFormProps) => {
        let count = item.count ?? 0;
        let unitPrice = item.unitPrice ?? 0;
        return formatNumber(count * unitPrice);
    }

    const invoiceTotalPrice = () => {
        return formatNumber(form.values.items.reduce((o, i) => o + (i.count ?? 0) * (i.unitPrice ?? 0), 0));
    }

    const saveInvoice = ({id, ...values}: EditInvoiceFormProps) => {
        setSaving(true);

        let postInvoiceRequest: PostInvoiceRequest = {
            ...values,
            issuanceDate: dayjs(values.issuanceDate).format(),
            deliveryDate: values.deliveryDate ? dayjs(values.deliveryDate).format() : undefined,
            dueDate: dayjs(values.dueDate).format(),
            variableSymbol: values.variableSymbol,
        }

        if (id == 'new' || clone) { // || clone by asi nemalo byt treba, kedze ak clone, tak id nastavujem na 'new' v mapData
            api().invoicesApi().v1InvoicesPost(postInvoiceRequest)
                .then((response) => {
                    showSuccessNotification('', t('Faktúra bola úspešne uložená!'));
                    mapInvoiceData(form, response.data.data, clone, true);
                    setPaidOff(response.data.data?.isPaidOff ?? false);
                    navigate('/invoices/' + response.data!.data!.id!);
                })
                .catch((error) => {
                    if (error.response?.status == 400) {
                        // TODO:
                        // this.parseValidationErrors(error.response?.data?.errors ?? []);
                    } else {
                        if (error.response.data.message) {
                            showErrorNotification(t('Nepodarilo sa uložiť faktúru.'), error.response.data.message);
                        } else {
                            showErrorNotification('', t('Nepodarilo sa uložiť faktúru.'));
                        }
                    }
                })
                .finally(() => setSaving(false));
        } else {
            api().invoicesApi().v1InvoicesIdPut(id, postInvoiceRequest)
                .then((response) => {
                    showSuccessNotification('', t('Zmeny boli úspešne uložené!'));
                    mapInvoiceData(form, response.data.data, clone, true);
                    setPaidOff(response.data.data?.isPaidOff ?? false);
                })
                .catch((error) => {
                    if (error.response?.status == 400) {
                        // TODO:
                        // this.parseValidationErrors(error.response?.data?.errors ?? []);
                        console.log(error.response?.data?.errors ?? []);
                    } else {
                        if (error.response.data.message) {
                            showErrorNotification(t('Nepodarilo sa uložiť faktúru.'), error.response.data.message);
                        } else {
                            showErrorNotification('', t('Nepodarilo sa uložiť faktúru.'));
                        }
                    }
                })
                .finally(() => setSaving(false));
        }
    };

    const updatePaidOffStatus = (id: string, currentPaidOff: boolean) => {
        setPaidOff(!currentPaidOff);

        api().invoiceSettingsApi().v1InvoicesettingsIdPost(id, {
            settingProperty: SettingProperty.IsPaidOff,
            settingValue: !currentPaidOff
        }).then((error) => {
            showSuccessNotification('', t('Uložené!'), 'paid-off-changed', undefined, 1000);
        })
        .catch((error) => {
            if (error.response.data.message) {
                showErrorNotification(t('Nepodarilo sa zmeniť stav faktúry'), error.response.data.message);
            } else {
                showErrorNotification('', t('Nepodarilo sa zmeniť stav faktúry'));
            }
            setPaidOff(currentPaidOff);
        });
    }

    const preventEnterSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            e.preventDefault();
        }
    }

    const fullAddressString = (address: EditAddressFormProps) => {
        var addressString = address.street ?? '';

        if (!!address.streetNumber) {
            addressString += addressString.length > 0 ? ` ${address.streetNumber}` : address.streetNumber;
        }

        if (addressString.length > 0) {
            addressString += ',';
        }

        if (!!address.zip) {
            addressString += ` ${address.zip}`;
        }

        if (!!address.city) {
            addressString += ` ${address.city}`;
        }

        if (!!address.country) {
            addressString += addressString.length > 0 ? `, ${address.country}` : address.country;
        }

        return addressString;
    }

    const clientBillingAddress = fullAddressString(form.values.client.billingAddress);
    const clientPostalAddress = fullAddressString(form.values.client.postalAddress);
    const issuerBillingAddress = fullAddressString(form.values.issuer.billingAddress);

    const clientCard = (fullHeight?: boolean) => (
        <Paper shadow="xs" p={32} radius="md" h={fullHeight ? '100%' : undefined}>
            <Text fw={500} c="dark.2" fz="sm" transform="uppercase">{t('Odberateľ')}</Text>
            {!form.values.client.billingAddress.companyName ? (
                <Text fw={500} fz="md" fs="italic">{t('Nezadané')}</Text>
            ) : (
                <Text fw={500} fz="md">{form.values.client.billingAddress.companyName}</Text>
            )}
            {(!!form.values.client.contactPersonName) && (
                <Text fz="sm">{form.values.client.contactPersonName}</Text>
            )}
            {(!!form.values.client.brn && !!form.values.client.taxId) && (
                <Text fz="sm">IČO: {form.values.client.brn} <Text span c="dark.0" inherit>|</Text> DIČ: {form.values.client.taxId}</Text>
            )}
            {(!!form.values.client.brn && !form.values.client.taxId) && (
                <Text fz="sm">IČO: {form.values.client.brn}</Text>
            )}
            {(!form.values.client.brn && !!form.values.client.taxId) && (
                <Text fz="sm">DIČ: {form.values.client.taxId}</Text>
            )}
            {(!!form.values.client.vatId) && (
                <Text fz="sm">IČ DPH: {form.values.client.vatId}</Text>
            )}
            {(!!clientBillingAddress) && (
                <Text fz="sm" sx={{  whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{clientBillingAddress}</Text>
            )}
            {(form.values.client.usePostalAddress) && (
                <Text fz="sm" sx={{  whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}><Text span fz="sm" c="dark.3">{t('Poštová adresa')}:</Text> {clientPostalAddress}</Text>
            )}
            <Button size="md" mt="lg" radius="lg" color="gray.7" fullWidth onClick={openClientDrawer} disabled={loading}>{t('Upraviť')}</Button>
        </Paper>
    );

    const issuerCard = (fullHeight?: boolean) => (
        <Paper shadow="xs" p={32} radius="md" h={fullHeight ? '100%' : undefined}>
            <Text fw={500} c="dark.2" fz="sm" transform="uppercase">{t('Dodávateľ')}</Text>
            {!form.values.issuer.billingAddress.companyName ? (
                <Text fw={500} fz="md" fs="italic">{t('Nezadané')}</Text>
            ) : (
                <Text fw={500} fz="md">{form.values.issuer.billingAddress.companyName}</Text>
            )}
            {(!!form.values.issuer.issuerName) && (
                <Text fz="sm">{form.values.issuer.issuerName}</Text>
            )}
            {(!!form.values.issuer.brn && !!form.values.issuer.taxId) && (
                <Text fz="sm">IČO: {form.values.issuer.brn} <Text span c="dark.0" inherit>|</Text> DIČ: {form.values.issuer.taxId}</Text>
            )}
            {(!!form.values.issuer.brn && !form.values.issuer.taxId) && (
                <Text fz="sm">IČO: {form.values.issuer.brn}</Text>
            )}
            {(!form.values.issuer.brn && !!form.values.issuer.taxId) && (
                <Text fz="sm">DIČ: {form.values.issuer.taxId}</Text>
            )}
            {form.values.issuer.taxType == TaxType.NoTaxes ? (
                <Text fz="sm" c="dark.3">{t('Nie je platiteľ DPH')}</Text>
            ) : (
                <Text fz="sm">IČ DPH: {form.values.issuer.vatId}</Text>
            )}
            {(!!issuerBillingAddress) && (
                <Text fz="sm" sx={{  whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{issuerBillingAddress}</Text>
            )}
            <Button size="md" mt="lg" radius="lg" color="gray.7" fullWidth onClick={openIssuerDrawer} disabled={loading}>{t('Upraviť')}</Button>
        </Paper>
    );

    const downloadInvoiceButton = (disabled?: boolean) => (
        <Button compact leftIcon={<IconPdf size="1rem" />} color="blue.9" radius="md" disabled={disabled} onClick={() => {
            downloadInvoicePdf(api(), form.values.id!, t('Faktúra {{invoiceNo}}', { invoiceNo: form.values.variableSymbol }), window, (error) => {
                if (error.response.data.message) {
                    showErrorNotification(t('Nepodarilo sa vytvoriť PDF.'), error.response.data.message);
                } else {
                    showErrorNotification('', t('Nepodarilo sa vytvoriť PDF.'));
                }
            });
        }}>
            {t('Stiahnuť')}
        </Button>
    );

    return (
        <Container size="lg" pt="xl">
            <ClientDrawer form={form} opened={clientDrawerOpened} onClose={closeClientDrawer} />
            <IssuerDrawer form={form} opened={issuerDrawerOpened} onClose={closeIssuerDrawer} />
            <CreateBankAccountModal opened={bankAccountModalOpened} close={closeBankAccountCreationModal} form={bankAccountForm} />
            <form onSubmit={form.onSubmit(saveInvoice)}>
                <Grid>
                    <Grid.Col md={8}>
                        <Paper shadow="xs" p="xl" radius="md">
                            <Group position="apart" mb="md">
                                <Title order={1} size="h3">
                                    {t('Faktúra {{invoiceNo}}', { invoiceNo: form.values.variableSymbol })}
                                </Title>
                                <Group>
                                    <Button compact leftIcon={<IconCreditCard size="1rem" />} variant="light" color={paidOff ? "green.8" : "gray"} radius="md" disabled={loading || form.values.id == 'new' || clone} onClick={() => updatePaidOffStatus(form.values.id, paidOff)}>
                                        {paidOff ? t('Zaplatená') : t('Označiť ako zaplatenú')}
                                    </Button>
                                    {form.values.id == 'new' || clone || form.isDirty() ? (
                                        <Tooltip label={form.values.id == 'new' || clone ? t('Pre stiahnutie PDF je potrebné faktúru najprv uložiť.') : t('Vytvorené PDF nebude obsahovať neuložené zmeny.')}>
                                            {form.values.id == 'new' || clone
                                                ? (<Button
                                                    data-disabled
                                                    sx={{ '&[data-disabled]': { pointerEvents: 'all', cursor: 'default' } }}
                                                    onClick={(event) => event.preventDefault()}
                                                    compact leftIcon={<IconPdf size="1rem" />} radius="md"
                                                >
                                                    {t('Stiahnuť')}
                                                </Button>)
                                                : downloadInvoiceButton()}
                                        </Tooltip>
                                    ) : downloadInvoiceButton(loading || saving)}
                                </Group>
                            </Group>
                            <Grid gutterMd="md">
                                <Grid.Col span={12}>
                                    <Textarea
                                        label={t("Úvodný text")}
                                        // description={t("Úvodný text, zobrazený vo faktúre")}
                                        autosize
                                        minRows={2}
                                        maxRows={5}
                                        disabled={loading}
                                        {...form.getInputProps('intro')}
                                    />
                                </Grid.Col>
                                <Grid.Col xs={8}>
                                    <BankAccountSelect
                                        bankAccounts={bankAccounts}
                                        openCreationModal={openBankAccountCreationModal}
                                        disabled={loading}
                                        onKeyDown={preventEnterSubmit}
                                        selectProps={form.getInputProps('bankAccountId')} />
                                </Grid.Col>
                                <Grid.Col xs={4}>
                                    <TextInput
                                        label={t('Číslo faktúry / VS')}
                                        disabled={loading}
                                        onKeyDown={preventEnterSubmit}
                                        {...form.getInputProps('variableSymbol')}
                                    />
                                </Grid.Col>
                                <Grid.Col xs={4}>
                                    <DateInput
                                        icon={<IconCalendar size="1rem" />}
                                        valueFormat="DD.MM.YYYY"
                                        label={t('Dátum vystavenia')}
                                        dateParser={dateParser}
                                        disabled={loading}
                                        onKeyDown={preventEnterSubmit}
                                        {...form.getInputProps('issuanceDate')}
                                        // maw={400}
                                        // mx="auto"
                                    />
                                </Grid.Col>
                                <Grid.Col xs={4}>
                                    <DateInput
                                        icon={<IconCalendar size="1rem" />}
                                        valueFormat="DD.MM.YYYY"
                                        label={t('Dátum dodania')}
                                        dateParser={dateParser}
                                        disabled={loading}
                                        onKeyDown={preventEnterSubmit}
                                        {...form.getInputProps('deliveryDate')}
                                        // maw={400}
                                        // mx="auto"
                                    />
                                </Grid.Col>
                                <Grid.Col xs={4}>
                                    <DateInput
                                        icon={<IconCalendar size="1rem" />}
                                        valueFormat="DD.MM.YYYY"
                                        label={t('Dátum splatnosti')}
                                        dateParser={dateParser}
                                        disabled={loading}
                                        onKeyDown={preventEnterSubmit}
                                        {...form.getInputProps('dueDate')}
                                        // maw={400}
                                        // mx="auto"
                                    />
                                </Grid.Col>
                            </Grid>
                        </Paper>

                        <MediaQuery largerThan="md" styles={{display: 'none'}}>
                            <Grid mt="sm">
                                <Grid.Col xs={6}>
                                    {clientCard(true)}
                                </Grid.Col>
                                <Grid.Col xs={6}>
                                    {issuerCard(true)}
                                </Grid.Col>
                            </Grid>
                        </MediaQuery>

                        <Flex justify="space-between" align="end">
                            <Title order={3} size="h3" fw={500} mt="lg" mb="sm">{t('Položky')}</Title>
                            <Title order={3} size="h4" fw={500} mt="lg" mb="sm" color="gray.6">{invoiceTotalPrice()} €</Title>
                        </Flex>

                        <Paper shadow="xs" p="xl" radius="md" mb="md">
                            {loading ? (
                                <Group position="center">
                                    <Loader color="gray" variant="dots" />
                                </Group>
                            ) : (<Stack spacing="lg">
                                {form.values.items.map((item, index) => (
                                    <React.Fragment key={index}>
                                        {form.values.items.findIndex(i => true) != index &&
                                            <Divider/>
                                        }
                                        <Grid gutter="sm" key={item.key} columns={36}>
                                            <Grid.Col md={20}>
                                                <TextInput
                                                    label={t('Názov položky')}
                                                    onKeyDown={preventEnterSubmit}
                                                    {...form.getInputProps(`items.${index}.title`)} />
                                            </Grid.Col>
                                            <Grid.Col xs={13} md={5}>
                                                <NumberInput
                                                    label={t('Počet')}
                                                    onKeyDown={preventEnterSubmit}
                                                    {...form.getInputProps(`items.${index}.count`)} />
                                            </Grid.Col>
                                            <Grid.Col xs={10} md={3}>
                                                <TextInput
                                                    label={t('MJ')}
                                                    onKeyDown={preventEnterSubmit}
                                                    {...form.getInputProps(`items.${index}.unit`)} />
                                            </Grid.Col>
                                            <Grid.Col xs={13} md={5}>
                                                <NumberInput
                                                    label={t('Cena / MJ')}
                                                    onKeyDown={preventEnterSubmit}
                                                    {...form.getInputProps(`items.${index}.unitPrice`)} />
                                            </Grid.Col>
                                            <MediaQuery largerThan="md" styles={{display: 'none'}}>
                                                <Grid.Col md={3} mt={16}>
                                                    <Flex justify="space-between" align="center">
                                                        <Flex
                                                            gap="0.375rem"
                                                            justify="end"
                                                            align="end"
                                                            direction="row"
                                                        >
                                                            <Text fw={500} c="dark.2" fz="xs" transform="uppercase"
                                                                  lh={1}>{t('Výsledná cena')}</Text>
                                                            <Text fw={500} fz="sm"
                                                                  lh={1}>{itemTotalPrice(form.values.items[index])} €</Text>
                                                        </Flex>
                                                        <ActionIcon variant="filled" size="lg" radius="md"
                                                                    onClick={() => form.removeListItem('items', index)}>
                                                            <IconTrash size="1rem"/>
                                                        </ActionIcon>
                                                    </Flex>
                                                </Grid.Col>
                                            </MediaQuery>
                                            <MediaQuery smallerThan="md" styles={{display: 'none'}}>
                                                <Grid.Col md={3}>
                                                    <Flex justify="end" align="end" h={"100%"}>
                                                        <ActionIcon variant="filled" size="lg" radius="md" mb={1}
                                                                    onClick={() => form.removeListItem('items', index)}>
                                                            <IconTrash size="1rem"/>
                                                        </ActionIcon>
                                                    </Flex>
                                                </Grid.Col>
                                            </MediaQuery>
                                            <MediaQuery smallerThan="md" styles={{display: 'none'}}>
                                                <Grid.Col md={36}>
                                                    <Flex
                                                        gap="0.375rem"
                                                        justify="end"
                                                        align="end"
                                                        direction="row"
                                                    >
                                                        <Text fw={500} c="dark.2" fz="xs" transform="uppercase"
                                                              lh={1}>{t('Výsledná cena')}</Text>
                                                        <Text fw={500} fz="sm"
                                                              lh={1}>{itemTotalPrice(form.values.items[index])} €</Text>
                                                    </Flex>
                                                </Grid.Col>
                                            </MediaQuery>
                                            {/*<Grid.Col span={4}>*/}
                                            {/*    <Flex*/}
                                            {/*        gap="xs"*/}
                                            {/*        justify="flex-start"*/}
                                            {/*        align="flex-end"*/}
                                            {/*        direction="column"*/}
                                            {/*    >*/}
                                            {/*        <Text fw={500} c="dark.2" fz="sm" transform="uppercase">{t('Výsledná cena')}</Text>*/}
                                            {/*        <Text fw={500} fz="md">{itemTotalPrice(form.values.items[index])} €</Text>*/}
                                            {/*    </Flex>*/}
                                            {/*</Grid.Col>*/}
                                            {/*<Grid.Col span={12}>*/}
                                            {/*    <Divider my="lg" />*/}
                                            {/*</Grid.Col>*/}
                                        </Grid>
                                    </React.Fragment>
                                ))}
                            </Stack>)}
                        </Paper>

                        <Button leftIcon={<IconPlus />} size="lg" radius="md" color="gray.7" uppercase fullWidth disabled={loading} onClick={() =>
                            form.insertListItem('items', { key: randomId() })
                        }>{t('Nová položka')}</Button>
                    </Grid.Col>
                    <Grid.Col md={4}>
                        <Stack justify="flex-start" spacing="md">
                            <MediaQuery smallerThan="md" styles={{display: 'none'}}>
                                {loading ? (
                                    <Button fullWidth size="lg" radius="md" color="green.8" disabled>
                                        <Loader size="1.5rem" color="white" />
                                    </Button>
                                ) : (
                                    <Button fullWidth type="submit" size="lg" radius="md" color="green.8" leftIcon={saving ? <Loader size="1rem" color="white" /> : <IconDeviceFloppy />} uppercase disabled={!form.isDirty() || saving}>
                                        {form.values.id == 'new' ? t('Uložiť') : t('Uložiť zmeny')}
                                    </Button>
                                )}
                            </MediaQuery>

                            <MediaQuery smallerThan="md" styles={{display: 'none'}}>
                                {clientCard()}
                            </MediaQuery>
                            <MediaQuery smallerThan="md" styles={{display: 'none'}}>
                                {issuerCard()}
                            </MediaQuery>
                            {/*<Skeleton visible={loading}>*/}
                            {/*    <Paper shadow="xs" p={32} radius="md">*/}
                            {/*        <Text fw={500} c="dark.2" fz="sm" transform="uppercase">{t('Nastavenia')}</Text>*/}
                            {/*    </Paper>*/}
                            {/*</Skeleton>*/}
                        </Stack>
                    </Grid.Col>
                </Grid>

                <MediaQuery largerThan="md" styles={{display: 'none'}}>
                    <Box pos="sticky" bottom={8}>
                        {loading ? (
                            <Button fullWidth size="lg" radius="md" color="green.8" disabled>
                                <Loader size="1.5rem" color="white" />
                            </Button>
                        ) : (
                            <Button fullWidth type="submit" size="lg" radius="md" color="green.8" leftIcon={saving ? <Loader size="1rem" color="white" /> : <IconDeviceFloppy />} uppercase disabled={!form.isDirty() || saving}>
                                {form.values.id == 'new' ? t('Uložiť') : t('Uložiť zmeny')}
                            </Button>
                        )}
                    </Box>
                </MediaQuery>
            </form>
        </Container>
    );
}

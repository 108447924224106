import React, {useEffect, useState} from "react";
import {
    Button,
    Container,
    Flex,
    Paper,
    Title
} from '@mantine/core';
import { DataTable } from "mantine-datatable";
import {useTranslation} from "react-i18next";
import {
    IconPlus
} from "@tabler/icons-react";
import {useFakturyPlusApi} from "../../api/FakturyPlusApiContext";
import { InvoiceDetailResponse } from "../../api/faktury-plus";
import {useNavigate} from "react-router-dom";
import {showErrorNotification} from "../../helpers/notifications";
import {createInvoiceTableStyles, getTableProps} from "./invoicesTableProps";

const useStyles = createInvoiceTableStyles();

export interface InvoicesProps {
}

export function Invoices({ ...others }: InvoicesProps) {
    const api = useFakturyPlusApi();
    const { t } = useTranslation();
    const { classes } = useStyles();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    const [records, setRecords] = useState<InvoiceDetailResponse[]>([]);

    const getData = () => {
        setLoading(true);

        api().invoicesApi().v1InvoicesGet().then((response) => {
            if (response.data.data && response.data.data.length) {
                setRecords(response.data.data ?? []);
            } else {
                setRecords([])
            }
            setLoading(false);
        })
        .catch((error) => {
            setRecords([])
            setLoading(false);

            if (error.response.data.message) {
                showErrorNotification(t('Nepodarilo sa načítať faktúry'), error.response.data.message, 'load-invoices');
            } else {
                showErrorNotification('', t('Nepodarilo sa načítať faktúry'), 'load-invoices');
            }
        });
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <Container size="xl" pt="xl">
            <Paper shadow="xs" p="xl" radius="md" mb="md">
                <Flex justify="space-between" align="baseline" mb="xl">
                    <Title order={1} size="h3">
                        {t('Faktúry')}
                    </Title>
                    <Button size="xs" color="green.9" component="a" href="/invoices/new" leftIcon={<IconPlus size="1rem" />}>{t('Nová faktúra')}</Button>
                </Flex>

                <DataTable
                    // withBorder
                    // borderRadius="sm"
                    // withColumnBorders
                    striped
                    highlightOnHover
                    classNames={classes}
                    minHeight={150}
                    fetching={loading}
                    records={records}
                    {...getTableProps(t, api, navigate, getData)}
                />
            </Paper>
        </Container>
    );
}

import React from 'react';
import { rem } from '@mantine/core';

interface BrandIconProps extends React.ComponentPropsWithoutRef<'svg'> {
    size?: number | string;
}

export function BrandIcon({ size, ...others }: BrandIconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 184 184"
            width={rem(size)}
            height={rem(size)}
            fill="currentColor"
            {...others}
        >
            <rect fill="#4a647b" height="160" id="svg_1" rx="2" ry="2" stroke="#4a647b" transform="matrix(1 0 0 1 0 0)" width="160" x="12" y="12" />
            <rect fill="#ffffff" height="20" id="svg_2" stroke="#ffffff" width="80" x="43" y="92" />
            <rect fill="#ffffff" height="80" id="svg_3" stroke="#ffffff" width="20" x="73" y="62" />
            <rect fill="#EDF1F4" height="20" id="svg_4" stroke="#EDF1F4" width="65" x="73" y="42" />
        </svg>
  );
}

import React from "react";
import {Avatar, createStyles, Group, Menu, Text, UnstyledButton, UnstyledButtonProps} from "@mantine/core";
import {IconChevronRight, IconLogout} from "@tabler/icons-react";
import {useAuthUser, useSignOut} from "react-auth-kit";
import {UserAuthState} from "../auth/UserAuthState";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useMediaQuery} from "@mantine/hooks";

const useStyles = createStyles((theme) => ({
    user: {
        display: 'block',
        width: '100%',
        padding: theme.spacing.md,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
        },
    },
}));

interface UserButtonProps extends UnstyledButtonProps {
}

export function UserButton({ ...others }: UserButtonProps) {
    const { classes } = useStyles();
    const { t } = useTranslation();
    const authUser = useAuthUser();
    const signOut = useSignOut();
    const navigate = useNavigate();

    const isMobile = useMediaQuery("(max-width: 50em)");

    // https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=255&q=80
    const userData: UserAuthState = { ...authUser() };

    return (
        <Menu shadow="md" width={200} position={isMobile ? 'top' :'right'} offset={2}>
            <Menu.Target>
                <UnstyledButton className={classes.user} {...others}>
                    <Group>
                        <Avatar src={userData.imageUrl} radius="xl" />

                        <div style={{ flex: 1 }}>
                            {userData.name?.length ? (
                                <>
                                    <Text size="sm" weight={500}>
                                        {userData.name}
                                    </Text>

                                    <Text color="dimmed" size="xs">
                                        {userData.email}
                                    </Text>
                                </>
                            ) : (
                                <Text size="sm" weight={500}>
                                    {userData.email}
                                </Text>
                            )}
                        </div>

                        <IconChevronRight size="0.9rem" stroke={1.5} />
                    </Group>
                </UnstyledButton>
            </Menu.Target>

            <Menu.Dropdown>
                <Menu.Item icon={<IconLogout size={14} />} onClick={() => { signOut(); navigate('/'); }}>{t('Odhlásiť')}</Menu.Item>
                {/*<Menu.Label>Application</Menu.Label>*/}
                {/*<Menu.Item icon={<IconSettings size={14} />}>Settings</Menu.Item>*/}
                {/*<Menu.Item icon={<IconMessageCircle size={14} />}>Messages</Menu.Item>*/}
                {/*<Menu.Item icon={<IconPhoto size={14} />}>Gallery</Menu.Item>*/}
                {/*<Menu.Item*/}
                {/*    icon={<IconSearch size={14} />}*/}
                {/*    rightSection={<Text size="xs" color="dimmed">⌘K</Text>}*/}
                {/*>*/}
                {/*    Search*/}
                {/*</Menu.Item>*/}

                {/*<Menu.Divider />*/}

                {/*<Menu.Label>Danger zone</Menu.Label>*/}
                {/*<Menu.Item icon={<IconArrowsLeftRight size={14} />}>Transfer my data</Menu.Item>*/}
                {/*<Menu.Item color="red" icon={<IconTrash size={14} />}>Delete my account</Menu.Item>*/}
            </Menu.Dropdown>
        </Menu>
    );}

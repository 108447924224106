export function formatNumber(value: any) {
    const numberFormatter = new Intl.NumberFormat('sk-SK');
    return numberFormatter.format(value);
}

export function formatDate(date: Date) {
    return date.toLocaleDateString("sk-SK").replaceAll(' ', '');
}

export function dateParser(input: string): Date {
    return new Date(input.replace(/(\d{2})\.(\d{2})\.(\d{4})/,'$3-$2-$1'));
}

export function isnum(val: string) { return /^\d+$/.test(val); }

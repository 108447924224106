import React from "react";
import { createStyles, Title, Text, Button, Container, Group, rem } from '@mantine/core';
import {useTranslation} from "react-i18next";
import {useIsAuthenticated} from "react-auth-kit";

const useStyles = createStyles((theme) => ({
    root: {
        paddingTop: rem(80),
        paddingBottom: rem(80),
    },

    label: {
        textAlign: 'center',
        fontWeight: 900,
        fontSize: rem(220),
        lineHeight: 1,
        marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2],

        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(120),
        },
    },

    title: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        textAlign: 'center',
        fontWeight: 900,
        fontSize: rem(38),

        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(32),
        },
    },

    description: {
        maxWidth: rem(500),
        margin: 'auto',
        marginTop: theme.spacing.xl,
        marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
    },
}));

export function Page404() {
    const { classes } = useStyles();
    const { t } = useTranslation();
    const isAuthenticated = useIsAuthenticated();

    return (
        <Container className={classes.root}>
            <div className={classes.label}>404</div>
            <Title className={classes.title}>{t('Stránka neexistuje')}</Title>
            <Text color="dimmed" size="lg" align="center" className={classes.description}>
                {t('Je nám to ľúto, ale požadovaná stránka nebola nájdená.')}
            </Text>
            <Group position="center">
                {isAuthenticated() ? (
                    <Button variant="subtle" size="md" component="a" href="/dashboard">
                        {t('Naspäť na prehľad')}
                    </Button>
                ) : (
                    <Button variant="subtle" size="md" component="a" href="/">
                        {t('Naspäť domov')}
                    </Button>
                )}
            </Group>
        </Container>
    );
}

import FakturyPlusApi from "../api/FakturyPlusApi";

function downloadInvoicePdf(api: FakturyPlusApi, invoiceId: string, filename: string, window: any, onError: (error: any) => void) {
    downloadInvoice(api, invoiceId, window, onError, (fileUrl: string) => {
        let alink = window.document.createElement('a');
        alink.href = fileUrl;
        alink.download = `${filename}.pdf`;
        alink.click();
    });
}

function openInvoicePdf(api: FakturyPlusApi, invoiceId: string, window: any, onError: (error: any) => void) {
    downloadInvoice(api, invoiceId, window, onError, (fileUrl: string) => {
        window.open(fileUrl, '_blank');
    });
}

function downloadInvoice(api: FakturyPlusApi, invoiceId: string, window: any, onError: (error: any) => void, handleFile: (fileUrl: string) => void) {
    api.invoicesApi().v1InvoicesPdfIdGet(invoiceId, { responseType: 'arraybuffer' })
        .then((response) => {
            let blob = new Blob([response.data], {
                type: 'application/pdf'
            });

            const fileUrl = window.URL.createObjectURL(blob);
            handleFile(fileUrl);

        })
        .catch(onError);
}

export { downloadInvoicePdf, openInvoicePdf };

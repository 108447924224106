import React from "react";
import {createStyles, Group, Paper, Text, rem, SimpleGrid} from '@mantine/core';
import {
    IconUserPlus,
    IconDiscount2,
    IconReceipt2,
    IconCoin,
    IconFileEuro, IconCurrencyEuro, IconCurrencyEuroOff, IconHourglassOff,
} from '@tabler/icons-react';
import {PerformanceResponse} from "../../api/faktury-plus";
import {useTranslation} from "react-i18next";
import {formatNumber} from "../../helpers/format";

const useStyles = createStyles((theme) => ({
    root: {
        // padding: `calc(${theme.spacing.xl} * 1.5)`,
    },

    value: {
        fontSize: rem(24),
        fontWeight: 700,
        lineHeight: 1,
    },

    diff: {
        lineHeight: 1,
        display: 'flex',
        alignItems: 'center',
    },

    icon: {
        color: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[4],
    },

    title: {
        fontWeight: 700,
        textTransform: 'uppercase',
    },
}));

const icons = {
    user: IconUserPlus,
    discount: IconDiscount2,
    receipt: IconReceipt2,
    coin: IconCoin,
};

interface StatsGridProps {
    performance?: PerformanceResponse;
}

export function StatsGrid({ performance }: StatsGridProps) {
    const { t } = useTranslation();
    const { classes } = useStyles();

    const stats = [
        { title: t('Vystavené spolu'), value: performance?.total ?? 0, color: undefined, icon: IconFileEuro },
        { title: t('Uhradená suma'), value: performance?.paidTotal ?? 0, color: 'green', icon: IconCurrencyEuro },
        { title: t('Neuhradená suma'), value: performance?.waiting ?? 0, color: undefined, icon: IconCurrencyEuroOff },
        { title: t('Suma po splatnosti'), value: performance?.overdue ?? 0, color: 'red', icon: IconHourglassOff },
    ];

    return (
        <div className={classes.root}>
            {/*<Stack/>*/}
            <SimpleGrid cols={1} breakpoints={[ { maxWidth: 'xl', cols: 2 } ]}>
                {stats.map((stat) => (
                    <Paper shadow="xs" p="md" radius="md" key={stat.title}>
                        <Group position="apart">
                            <Text size="xs" color="dimmed" className={classes.title}>
                                {stat.title}
                            </Text>
                            <stat.icon className={classes.icon} size="1.4rem" stroke={1.5} />
                        </Group>

                        <Group align="flex-end" spacing="xs" mt={16}> {/*mt={25}*/}
                            <Text className={classes.value} color={stat.color}>{formatNumber(stat.value)}</Text>
                            <Text fz="sm" fw={500} className={classes.diff} color={stat.color}>
                                {'\u20AC'}
                            </Text>
                        </Group>
                    </Paper>
                ))}
            </SimpleGrid>
            {/*</Stack>*/}
        </div>
    );
}

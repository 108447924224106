import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {DevSupport} from "@react-buddy/ide-toolbox";
import {ComponentPreviews, useInitial} from "./dev";
import { Chart as ChartJS, Tooltip, Legend, LineElement, PointElement, LineController, BarElement, BarController, CategoryScale, LinearScale, Filler } from "chart.js";

ChartJS.register(Tooltip, Legend, LineElement, PointElement, LineController, BarElement, BarController, CategoryScale, LinearScale, Filler);
ChartJS.register({
   id: 'customSpacingLegend',
   beforeInit(chart) {
       // Get reference to the original fit function
       const originalFit = (chart.legend as any).fit;
       // Override the fit function
       (chart.legend as any).fit = function fit() {
           // Call original function and bind scope in order to use `this` correctly inside it
           originalFit.bind(chart.legend)();
           this.height += 20;
       };
   }
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <DevSupport ComponentPreviews={ComponentPreviews}
                    useInitialHook={useInitial}
        >
            <App/>
        </DevSupport>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

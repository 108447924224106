import React from "react";
import {notifications} from "@mantine/notifications";
import {IconCheck, IconX} from "@tabler/icons-react";

export function showErrorNotification(title: string, message: string, id?: string, onClose?: () => void) {
    notifications.show({
        id: id,
        withCloseButton: true,
        onClose: onClose,
        // onOpen: () => console.log('mounted'),
        autoClose: 3000,
        title: title,
        message: message,
        color: 'red',
        icon: <IconX />,
        withBorder: true,
        // loading: false,
    });
}

export function showSuccessNotification(title: string, message: string, id?: string, onClose?: () => void, delay: number = 3000) {
    notifications.show({
        id: id,
        withCloseButton: true,
        onClose: onClose,
        autoClose: 3000,
        title: title,
        message: message,
        color: 'teal',
        icon: <IconCheck />,
        withBorder: true,
    });
}

import React from "react";
import {
    Anchor,
    AppShell,
    Button,
    Container,
    createStyles,
    Divider, Flex,
    Footer,
    Group, Header, List,
    MediaQuery,
    rem, Stack, Table,
    Text,
    Title,
    useMantineTheme
} from '@mantine/core';
import {useMediaQuery} from "@mantine/hooks";
import {useTranslation} from "react-i18next";
import {Notifications} from "@mantine/notifications";
import {LanguagePicker} from "../../Layout/LanguagePicker";
import {BrandIcon} from "../../icons/BrandIcon";
import {useNavigate} from "react-router-dom";

const useStyles = createStyles((theme) => ({
    headerInner: {
        height: rem(56),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    inner: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: `calc(${theme.spacing.xl} * 2)`,
        paddingBottom: `calc(${theme.spacing.xl} * 2)`,

        // [theme.fn.smallerThan('md')]: {
        //     paddingTop: `calc(${theme.spacing.xl} * 2)`,
        // },
    },

    footer: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        borderTop: `${rem(1)} solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
        }`,
        paddingTop: theme.spacing.sm,
        paddingBottom: theme.spacing.sm,
        margin: '2rem -1rem -1rem -1rem',
        // marginTop: rem(120),
        // paddingTop: `calc(${theme.spacing.xl} * 2)`,
        // paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    }
}));

export enum ArticleEnum {
    TermsOfService,
    PrivacyPolicy
}

interface StaticArticleProps {
    article: ArticleEnum;
}
export function StaticArticle({ article }: StaticArticleProps) {
    const theme = useMantineTheme();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { classes } = useStyles();

    const termsOfServiceListsSk: { title: string, content: string[] }[] = [
        {
            title: 'Úvodné ustanovenia',
            content: [
                'Tieto všeobecné obchodné podmienky podrobne upravujú vzťah medzi spoločnosťou a návštevníkom pri prehliadaní obsahu internetovej stránky a vzťah medzi spoločnosťou a používateľom pri využívaní služby používateľom. Od okamihu nadobudnutia postavenia návštevníka a/alebo používateľa sú návštevník a používateľ zaviazaní pravidlami upravenými týmito všeobecnými obchodnými podmienkami.',
                'Právne vzťahy, ktoré nie sú upravené týmito všeobecnými obchodnými podmienkami, sa riadia príslušnými všeobecne záväznými právnymi predpismi, ktoré platia a sú účinné na území Slovenskej republiky. Medzi tieto predpisy patrí najmä zákon č. 513/1991 Zb. Obchodný zákonník v znení neskorších predpisov. Toto platí aj v prípade, že návštevník alebo používateľ sú podnikateľské subjekty registrované v zahraničí alebo sú fyzickými osobami, ktoré sú občanmi iných štátov ako Slovenská republika.',
                'Internetová stránka fakturyplus.sk je webová stránka, ktorá je prístupná prostredníctvom internetu a zobrazuje obsah a služby pre návštevníkov a používateľov. Pre účely týchto všeobecných obchodných podmienok sa internetovou stránkou rozumie každá stránka, ktorá je identifikovaná cez adresu subdomén k doméne fakturyplus.sk, ako napríklad www.fakturyplus.sk. Spoločnosť má právo vlastniť a používať túto doménu a jej subdomény.',
                'Obsah internetovej stránky zahŕňa všetky grafické, textové alebo iné prvky, ktoré sa zobrazujú návštevníkom a/alebo používateľom pri prehliadaní internetovej stránky a/alebo pri využívaní služby, s výnimkou obsahu používateľa. Rovnako platí, že obsahom internetovej stránky sú aj všetky softvérové prvky, ktoré návštevník môže používať pri prehliadaní stránky a/alebo používateľ pri využívaní služby.',
                'Obsah používateľa je pre účely týchto všeobecných obchodných podmienok definovaný ako dátový alebo iný obsah vytvorený používateľom prostredníctvom osobitného programového prostredia internetovej stránky.    Tento obsah je vytvorený prostredníctvom používateľského účtu a uložený na úložisku dát poskytnutom spoločnosťou v rámci služby. Je dôležité zdôrazniť, že obsah používateľa nie je súčasťou obsahu internetovej stránky. Obsah používateľa patrí výhradne používateľovi a používateľ je plne zodpovedný za zaručenie súladu tohto obsahu s príslušnými všeobecne záväznými právnymi predpismi platnými a účinnými na území Slovenskej republiky.',
                'Návštevník je v rámci týchto všeobecných obchodných podmienok označenie pre fyzickú alebo právnickú osobu, ktorá prostredníctvom štandardného internetového prehliadača navštívi adresu domény fakturyplus.sk alebo adresu jednej zo subdomén domény fakturyplus.sk.',
                'Používateľom sa v týchto podmienkach rozumie fyzická alebo právnická osoba, ktorá pravdivo a úplne vyplnila registračný formulár a zaregistrovala sa pre využívanie služby poskytovanej spoločnosťou prostredníctvom internetovej stránky. Ak používateľ iba prezerá internetovú stránku a nevyužíva službu, považuje sa za návštevníka.',
                'Používateľský účet predstavuje pracovné prostredie používateľa, ku ktorému získava prístup po vykonaní registrácie a slúži na využívanie služby. Každý používateľský účet bude mať unikátne spojenie prihlasovacieho mena a hesla, ktoré sa viaže k prihlasovaciemu menu, alebo bude založený na autentifikácii treťou stranou (napríklad prostredníctvom služieb Google alebo Facebook). Pri vytváraní používateľského účtu je povinné vyjadriť súhlas s týmito všeobecnými obchodnými podmienkami spoločnosti.',
                // 'Služba je pre účely týchto všeobecných obchodných podmienok služba správy administratívno-ekonomickej agendy podnikateľských subjektov v rozsahu podľa ponuky sprístupnenej verejnosti na internetovej stránke a určenej spoločnosťou. Súčasťou služby je aj uchovávanie záznamov a informácií, ktoré používateľ zadá do služby. Toto uchovávanie záznamov nemá charakter archivácie dokladov používateľa v súlade so zákonom č. 395/2002 Z. z. o archívoch a registratúrach v znení neskorších predpisov. Službu poskytuje spoločnosť bezodplatne. Spoločnosť si vyhradzuje právo kedykoľvek ukončiť poskytovanie tejto služby. Rovnako si vyhradzuje právo spoplatniť túto službu, alebo jej časti.',
                'Služba, v súlade s týmito všeobecnými obchodnými podmienkami, predstavuje správu administratívno-ekonomickej agendy podnikateľských subjektov v rozsahu ponuky, ktorá je verejne prístupná na internetovej stránke a je určená spoločnosťou. Služba zahŕňa aj uchovávanie záznamov a informácií, ktoré používateľ zada do služby. Je potrebné zdôrazniť, že toto uchovávanie záznamov nemá charakter archivácie dokladov používateľa podľa zákona č. 395/2002 Z. z. o archívoch a registratúrach v znení neskorších predpisov. Spoločnosť poskytuje túto službu bezplatne, avšak si vyhradzuje právo kedykoľvek ukončiť jej poskytovanie. Taktiež si vyhradzuje právo zaviesť poplatky za túto službu alebo jej časti.',
                'Spoločnosť je obchodným subjektom, ktorý bol založený, vznikol a je zaregistrovaný v príslušných registračných úradoch v súlade s platnými všeobecne záväznými právnymi predpismi Slovenskej republiky. Má oprávnenie vykonávať podnikateľskú činnosť s cieľom byť prevádzkovateľom internetovej stránky a poskytovať služby.'
            ]
        },
        {
            title: 'Pravidlá pre prezeranie internetovej stránky',
            content: [
                'Pri prezeraní internetovej stránky a akéhokoľvek jej obsahu je návštevník povinný konať takým spôsobom, aby nezasahoval do práv spoločnosti, iných návštevníkov, používateľov alebo tretích osôb.',
            ]
        },
        {
            title: 'Registrácia',
            content: [
                'Ak má návštevník záujem využívať službu, je povinný sa zaregistrovať prostredníctvom verejne dostupného registračného formulára na internetovej stránke.',
                'V registračnom formulári musí návštevník uviesť všetky povinné údaje v rozsahu stanovenom spoločnosťou a zároveň sú povinný uviesť správne, presné a pravdivé údaje. Ak návštevník poskytne nesprávne, nepresné alebo nepravdivé údaje a neoprávnene sa stane používateľom na základe týchto údajov, zodpovedá za akúkoľvek vzniknutú škodu na majetku spoločnosti, iných návštevníkov, používateľov alebo tretích osôb, a to samostatne bez účasti spoločnosti.',
                'Po odoslaní registračného formulára obsahujúceho všetky povinné údaje, získa návštevník postavenie používateľa. Podmienkou pre odoslanie registračného formulára je výslovný súhlas s týmito všeobecnými obchodnými podmienkami, ktorý sa vyjadruje zaškrtnutím príslušného políčka.',
                'Na základe predpokladu správne vyplneného formulára spoločnosť vytvorí používateľský účet, ktorý môže používateľ používať pri využívaní služby. Ako prihlasovacie údaje platí prihlasovacie meno a heslo, ktoré pri registrácii určí používateľ. Používateľ je zodpovedný za bezpečnosť svojho účtu a musí preto dbať na voľbu hesla, ktoré je bezpečné a ku ktorému nemajú prístup iné osoby. Spoločnosť nezodpovedá za stratu údajov uložených na úložisku dát poskytnutom spoločnosťou v rámci služby, ani za žiadne škody vzniknuté prelomením sa do účtu používateľa treťou stranou.',
                'Používateľský účet je oprávnený používať iba konkrétny používateľ, čo je osoba, ktorá vyplnila a odoslala správne vyplnený formulár spoločnosti. Ak používateľ poskytne svoje prihlasovacie údaje tretej osobe, nesie samostatnú zodpovednosť za akúkoľvek škodu, ktorá môže vzniknúť spoločnosti, inému používateľovi, návštevníkovi alebo tretej osobe v súvislosti s neoprávneným využívaním služby touto treťou osobou.',
                'Spoločnosť si vyhradzuje právo zrušiť používateľský účet v prípade, ak registračný formulár nebol vyplnený správne a/alebo pravdivo. V takomto prípade spoločnosť nezodpovedá za vzniknutú škodu.',
                'Spoločnosť si tiež vyhradzuje právo jednostranne zmeniť spôsob registrácie používateľov v súlade s obsahom internetovej stránky alebo na základe potrieb spoločnosti, návštevníkov, používateľov alebo tretích osôb a za účelom ochrany ich práv alebo oprávnených záujmov.'
            ]
        },
        {
            title: 'Obsah služby a práva a povinnosti spoločnosti',
            content: [
                'Spoločnosť poskytuje používateľom službu prostredníctvom internetovej stránky buď odplatne alebo bezodplatne. Rozhodnutie o bezplatnom poskytovaní služby a o jej trvaní je výhradne v kompetencii spoločnosti.',
                'Spoločnosť pri poskytovaní služby používateľovi zabezpečuje podmienky, ktoré umožňujú vytváranie faktúr, ich sťahovanie a ďalšie možnosti podľa aktuálnej ponuky spoločnosti, ktorá je dostupná verejnosti prostredníctvom internetovej stránky.',
                'S cieľom odstrániť akékoľvek pochybnosti sa ustanovuje, že služba poskytovaná spoločnosťou nie je poradenskou službou, službou zabezpečujúcou archiváciu dokladov používateľa v súlade so zákonom č. 395/2002 Z. z. o archívoch a registratúrach v znení neskorších predpisov (ďalej len „Zákon“), službou účtovníka alebo finančného poradcu alebo advokáta alebo inej osoby podľa osobitného všeobecne záväzného právneho predpisu platného a účinného na území Slovenskej republiky. Cieľom služby je zlepšenie prehľadnosti a efektívnosti administratívno-ekonomickej agendy používateľa. Používateľ nesie výhradnú zodpovednosť za obsah a správnosť konkrétnych administratívnych a ekonomických úkonov vykonávaných voči tretím osobám, vrátane orgánov verejnej moci.',
                'Používateľ berie na vedomie a súhlasí s tým, že ak sa na spoločnosť obráti orgán verejnej moci v súvislosti s vedením konkrétneho občianskeho, obchodného, správneho (vrátane daňového a registratúrneho), trestného alebo iného konania, spoločnosť môže poskytnúť tomuto orgánu všetky informácie, ktoré bude mať k dispozícii a poskytnutie týchto informácií sa nepovažuje za porušenie povinností spoločnosti podľa týchto všeobecných obchodných podmienok.',
                'Spoločnosť má právo poskytovať službu vo vybraných balíčkoch stanovených spoločnosťou za ceny určené spoločnosťou. Taktiež si vyhradzuje právo ponúkať doplnkové služby podľa cenníka doplnkových služieb.',
                // Spoločnosť je povinná zabezpečiť, aby bola služba poskytovaná nepretržite po dobu jej poskytovania, a teda bez jej výpadkov alebo iných prerušení. Za porušenie povinností spoločnosti sa nepovažuje prerušenie poskytovania služby v dôsledku okolností vylučujúcich zodpovednosť v súlade s čl. VII týchto všeobecných obchodných podmienok. Za porušenie povinností sa rovnako nepovažuje prerušenie poskytovania služby z dôvodu servisných zásahov spoločnosti.
                'Spoločnosť nezodpovedá za škodu na majetku návštevníka a/alebo používateľa, ktorá vznikla v dôsledku porušenia jej povinností.',
                // V prípade, že dôjde ku zmene skutočností, ktoré budú mať vplyv na poskytovanie služby používateľovi sa spoločnosť zaväzuje, že o týchto skutočnostiach bude informovať používateľov, ktorých sa konkrétna zmena týka, a to formou e-mailovej správy, ak bude mať spoločnosť k dispozícii e-mailovú adresu používateľa alebo písomne na adresu sídla alebo miesta podnikania používateľa, ktoré používateľ oznámi spoločnosti prostredníctvom riadne vyplneného formulára v procese registrácie používateľa; v prípade, ak dôjde k zmene údajov na strane používateľa a používateľ o tejto skutočnosti spoločnosť neinformuje a v dôsledku tejto skutočnosti nebude možné zo strany spoločnosti riadne si splniť svoju povinnosť podľa tohto odseku, spoločnosť nezodpovedá za škodu, ktorá môže byť používateľovi spôsobená v dôsledku neinformovania používateľa.
                'Spoločnosť má ďalšie práva a povinnosti v súlade s ďalšími ustanoveniami týchto všeobecných obchodných podmienok.',
                'Spoločnosť si vyhradzuje právo jednostranne upraviť alebo modifikovať tieto všeobecné obchodné podmienky, aj v prípade, že to bude nutné na ochranu práv, oprávnených záujmov spoločnosti, návštevníkov, používateľov alebo tretích osôb.',
                'Ak používateľ poruší akúkoľvek zo svojich dôležitých povinností uvedených v týchto všeobecných obchodných podmienkach alebo opakovane poruší menej vážnu povinnosť podľa týchto podmienok, spoločnosť má právo jednostranne zrušiť používateľský účet používateľa a nenávratne odstrániť obsah používateľa z úložiska dát. V takomto prípade spoločnosť nezodpovedá za škodu, ktorá by mohla vzniknúť používateľovi v súvislosti s odstránením používateľského účtu a jeho obsahu.',
                'Používateľ berie na vedomie, že služba poskytnutá spoločnosťou nezabezpečuje archiváciu registratúrnych záznamov/uchovávanie účtovných dokladov vložených alebo vytvorených používateľom v rámci poskytnutia služby podľa Zákona, resp. podľa príslušných ustanovení daňových všeobecne záväzných právnych predpisov. Spoločnosť nezodpovedá za škodu, ktorá prípadne vznikne používateľovi v súvislosti s povinnosťami stanovenými všeobecne záväznými predpismi v oblasti registratúry a archívnictva, resp. v oblasti uchovávania účtovných dokladov.'
            ]
        },
        {
            title: 'Práva a povinnosti používateľa',
            content: [
                'Používateľovi sú uložené všetky povinnosti, ktoré platia pre návštevníka podľa článku 2. týchto všeobecných obchodných podmienok. Zároveň má spoločnosť voči používateľovi rovnaké práva ako voči návštevníkovi.',
                'Používateľ má povinnosť zabezpečiť, aby nedošlo k neoprávnenému prístupu osoby, ktorá nie je oprávnená, do jeho používateľského účtu. Je najmä povinný podniknúť všetky opatrenia na zabránenie úniku prihlasovacieho mena a hesla. V prípade porušenia tejto povinnosti je používateľ zodpovedný za akúkoľvek škodu, ktorú v dôsledku toho utrpí spoločnosť, iný používateľ, návštevník alebo tretia osoba.',
                'Pokiaľ používateľ zistí, že existuje riziko porušenia práv spoločnosti a/alebo jeho vlastných práv a/alebo práv ostatných používateľov a/alebo návštevníkov a/alebo tretích strán, je povinný bezodkladne informovať spoločnosť o tejto skutočnosti. Rovnaká povinnosť platí aj v prípade, keď používateľ získa informácie o už existujúcom porušení práv osôb podľa predchádzajúcej vety tohto odseku.',
                'Používateľ má právo požiadať spoločnosť o okamžité zrušenie svojho používateľského účtu a/alebo odstránenie obsahu, pričom spoločnosť je povinná vyhovieť tejto žiadosti a nenávratne odstrániť obsah používateľa bezodkladne po prijatí žiadosti. Tento proces zahŕňa vymazanie všetkých dát súvisiacich s obsahom používateľa. Spoločnosť však nie je povinná vymazať údaje, ktoré je povinná uchovávať v súlade s platnými právnymi predpismi na území Slovenskej republiky. Za odstránenie obsahu používateľa spoločnosť neúčtuje žiadne poplatky alebo ceny. Používateľ má tiež možnosť samostatne vymazať obsah svojho používateľského účtu tým spôsobom, že vymaže všetky ním vyplnené údaje.',
                'Používateľ má právo požiadať spoločnosť o likvidáciu alebo výmaz osobných údajov, ktoré poskytol spoločnosti (ďalej len „likvidácia osobných údajov“). Pri likvidácii osobných údajov spoločnosť vymaže alebo zlikviduje všetky osobné údaje, ktoré používateľ poskytol spoločnosti, s výnimkou tých, ktoré spoločnosť musí zákonne uchovávať v súlade s všeobecne záväznými právnymi predpismi platnými na území Slovenskej republiky.',
                'Používateľ má právo používať obsah internetovej stránky s cieľom riadne využívať službu, a spoločnosť vyslovuje svoj nevýhradný súhlas s takýmto používaním obsahu internetovej stránky. Toto ustanovenie neovplyvňuje povinnosti používateľa podľa týchto všeobecných obchodných podmienok.',
                'Ak spoločnosť zistí, že existuje objektívny dôvod domnievať sa, že používateľ ukladá na úložisko dát, ktoré spoločnosť poskytuje v rámci služby, obsah, ktorý porušuje práva spoločnosti a/alebo iných používateľov a/alebo návštevníkov a/alebo tretích osôb, spoločnosť má právo jednostranne a nenávratne odstrániť tento obsah z úložiska dát používateľa. V takom prípade spoločnosť nezodpovedá za prípadnú škodu, ktorá môže používateľovi vzniknúť v dôsledku tohto opatrenia.',
                'Používateľ si uvedomuje, že v súlade s princípmi férového využívania služby (FUP) existuje maximálny počet API volaní (requestov) na servery služby (ďalej len „limit“). Tento limit je stanovený na 1 000 volaní za deň alebo 30 000 volaní za mesiac. Používateľ a spoločnosť sa však môžu dohodnúť na navýšení tohto limitu za dohodnutú cenu. V prípade prekročenia týchto limitov si spoločnosť vyhradzuje právo obmedziť poskytovanú službu alebo okamžite odstúpiť od zmluvy.',
                'Používateľ má povinnosť kontrolovať správnosť všetkých údajov ktoré sú mu v rámci používania služby poskytnuté a/alebo zobrazované, alebo ktoré sú zobrazené na výstupných dokumentoch. Týmto údajmi môžu byť najmä, ale nie výlučne, všetky údaje vyzobrazené na faktúre vrátane QR kódu, automaticky doplnené údaje firiem, všetky zobrazované a exportované výpočty a prehľady. Spoločnosť nezodpovedá za vzniknutú škodu vzniknutú poskytnutím nesprávnych údajov.'
            ]
        },
        {
            title: 'Platobné podmienky',
            content: [
                'Spoločnosť si vyhradzuje právo individuálne prispôsobiť ceny jednotlivých častí služby, ktoré budú ponúkané návštevníkom a používateľom. Taktiež má právo jednostranne meniť ceny za poskytovanú službu.'
            ]
        },
        {
            title: 'Okolnosti vylučujúce zodpovednosť',
            content: [
                'Pre účely tejto zmluvy sa pod okolnosťami vylučujúcimi zodpovednosť spoločnosti rozumejú mimoriadne a neodvrátiteľné udalosti alebo okolnosti a ich priame následky, ktoré sú mimo kontrolu spoločnosti a bránia jej schopnosti riadne a úplne splniť povinnosti vyplývajúce z týchto všeobecných obchodných podmienok. Týmito udalosťami môžu byť najmä, ale nie výlučne, živelné pohromy, vojna, sabotáž, teroristické akcie, blokáda, požiar, štrajk, epidémia, zlyhanie subdodávateľa (vrátane dodávateľov energií) a podobné. Rovnaké právne následky môže mať zmena všeobecne záväzných právnych predpisov, pokiaľ zásadným spôsobom ovplyvní plynulé pokračovanie právnych vzťahov regulovaných týmito všeobecnými obchodnými podmienkami a nebude existovať ekonomicky únosná alternatíva.'
            ]
        },
        // {
        //     title: 'Predchádzanie škodám a náhrada škody',
        //     content: [
        //     ]
        // },
        {
            title: 'Osobné údaje a ich ochrana',
            content: [
                'Spoločnosť zhromažďuje osobné údaje používateľa v rozsahu podľa údajov, ktoré vyžaduje registrácia, za účelom plnenia zmluvy, v ktorej požívateľ vystupuje ako jedna zo zmluvných strán. Na spracúvanie osobných údajov používateľa podľa predchádzajúcej vety sa v súlade s ustanovením § 13 ods. 1 písm. b) zákona č. 18/2018 Z. z. o ochrane osobných údajov a o zmene a doplnení niektorých zákonov v znení neskorších predpisov (ďalej len „zákon o ochrane osobných údajov“) nevyžaduje súhlas používateľa ako dotknutej osoby.',
                'Návštevník a používateľ majú právo na základe písomnej žiadosti od spoločnosti vyžadovať informácie týkajúce sa osobných údajov a ich spracovania.',
                'Návštevník a používateľ, na základe písomnej žiadosti, majú právo u spoločnosti namietať voči spracúvaniu jeho osobných údajov, využívaniu osobných údajov a poskytovaniu osobných údajov.',
                'Používateľ berie na vedomie, že spoločnosť spracúva osobné údaje, ktoré používateľ zadá do svojho používateľského konta za účelom poskytovania služby používateľovi v zmysle týchto všeobecných obchodných podmienok. Používateľ berie tiež na vedomie, že spoločnosť môže poveriť spracúvaním osobných údajov ďalších sprostredkovateľov.',
                'Spoločnosť v prípade, ak spracúva osobné údaje pri poskytovaní služby pre návštevníka a/alebo používateľa, koná tak najmä v postavení sprostredkovateľa v zmysle zákona o ochrane osobných údajov. Návštevník a/alebo používateľ sú pri využívaní služby primárne a najmä v postavení prevádzkovateľa v zmysle zákona o ochrane osobných údajov.',
                'Spoločnosť spracúva osobné údaje výlučne za účelom poskytovania služby pre používateľa v zmysle týchto všeobecných obchodných podmienok. Osobné údaje, ktoré spracúva spoločnosť pre používateľa nie sú osobnými údajmi týkajúcimi sa zdravia, biometrickými údajmi, genetickými údajmi ani osobnými údajmi týkajúcimi sa rozsudkov v trestných veciach alebo trestných činov v zmysle zákona o ochrane osobných údajov.',
                'Spoločnosť zachováva mlčanlivosť o všetkých údajoch a osobných údajoch, ktoré používateľ zadá do používateľského konta. V prípade, ak spoločnosť poverí ďalšie osoby spracovaním osobných údajov, deje sa tak len za účelom poskytovania služby v zmysle týchto všeobecných obchodných podmienok.',
                'Spoločnosť za účelom zabezpečenia bezpečnosti spracúvania osobných údajov prijala so zreteľom na najnovšie poznatky, na náklady na vykonanie opatrení, na povahu, rozsah, kontext a účel spracúvania osobných údajov a na riziká s rôznou pravdepodobnosťou a závažnosťou pre práva dotknutých osôb primerané technické a organizačné opatrenia na zaistenie úrovne bezpečnosti primeranej tomuto riziku.',
                'Spoločnosť po zohľadnení povahy a rozsahu spracúvania osobných údajov pre konkrétneho používateľa môže poskytnúť súčinnosť používateľovi vhodnými technickými a organizačnými opatreniami pri plnení povinností prevádzkovateľa voči dotknutým osobám.',
            ]
        },
        {
            title: 'Ukončenie právneho vzťahu',
            content: [
                'Účastníci právneho vzťahu sa môžu dohodnúť na jeho ukončení osobitnou dohodou.',
                'Spoločnosť má právo právny vzťah ukončiť jednostranne v súlade s ustanoveniami týchto všeobecných obchodných podmienok.'
            ]
        }
    ];

    const renderList = (items: string[], numberPrefix?: number, withPadding?: boolean) => (
        <List type="ordered" listStyleType="none" spacing="sm" withPadding={withPadding}>
            {items.map((listItem, i) => (
                <List.Item>
                    {numberPrefix ? `${numberPrefix}.${i+1}.` : `${i+1}.`} {listItem}
                </List.Item>
            ))}
        </List>
    )
    const renderLists = (lists: { title: string, content: string[] }[]) => (<List type="ordered" listStyleType="none" spacing="xl">
        {lists.map(((list, i) => (
            <List.Item>
                <Title order={3} size="h4" weight={500} mb="sm" align="center">{i+1}. {list.title}</Title>
                {renderList(list.content, i+1)}
            </List.Item>
        )))}
    </List>);

    const articles: {[key in ArticleEnum]: { title: string, content: JSX.Element }} = {
        [ArticleEnum.TermsOfService]: {
            title: t('Všeobecné obchodné podmienky'),
            content: (<Stack mt={rem(-8)}>
                <Stack align="center" spacing={0} mb="md">
                    <Text c="dimmed" mb="xs">obchodnej spoločnosti</Text>
                    <Text c="dimmed" fw={500}>Codelity s. r. o.</Text>
                    <Text c="dimmed">zapísanej do Obchodného registra Mestského súdu Košice, oddiel Sro,</Text>
                    <Text c="dimmed">vložka č. 55910/V, IČO 55186157, DIČ 2121891002</Text>
                    <Text c="dimmed">so sídlom Húskova 1294/87, 040 23 Košice - mestská časť Sídlisko KVP</Text>
                    <Text c="dimmed" mb="xs">(ďalej len „spoločnosť“)</Text>
                    <Text c="dimmed">platné a účinné pri prevádzkovaní a používaní internetovej stránky www.fakturyplus.sk a poskytovaní služby FaktúryPlus.sk</Text>
                </Stack>
                {renderLists(termsOfServiceListsSk)}
                <Text mt="lg" color="dimmed">Podmienky sú platné od 7.6.2023</Text>
            </Stack>),
            // contentEn: (<Group position="center" py={rem(60)}><Loader color="gray" variant="oval" /></Group>)
        },
        [ArticleEnum.PrivacyPolicy]: {
            title: t('Ochrana osobných údajov'),
            content: (<Stack mt={"xl"}>
                <Text>
                    Vaše práva na ochranu osobných údajov sú plne zaručené a dodržiavané. Spracovanie osobných údajov prebieha v súlade s Nariadením Európskeho parlamentu a Rady EÚ 2016/679 a zákonom č. 18/2018 Z. z. o ochrane osobných údajov a o zmene a doplnení niektorých predpisov. Pri spracovaní osobných údajov dodržiavame princípy zákonnosti a transparentnosti, obmedzenia účelu spracovania, minimalizácie rozsahu a uchovávania. Venujeme sa riadeniu informačnej bezpečnosti s cieľom zabezpečiť dôvernosť, dostupnosť a integritu osobných údajov.
                </Text>
                <Text>
                    Spracovávame iba tie osobné údaje, ktoré nám užívatelia dobrovoľne poskytnú, a to len pre konkrétny účel, na ktorý boli tieto údaje poskytnuté. Vaše osobné údaje budeme spracovávať len po dobu nevyhnutnú na splnenie účelu spracovania súvisiaceho s otázkou alebo problémom, na základe ktorého sa na nás obraciate. Poskytovanie osobných údajov je dobrovoľné.
                </Text>
                <Text>
                    Právny vzťah medzi nami, ako prevádzkovateľom internetovej stránky www.fakturyplus.sk a poskytovateľom služby FaktúryPlus.sk, a Vami ako návštevníkom a používateľom sa riadi platnými <Anchor href="terms-of-service">Všeobecnými obchodnými podmienkami</Anchor>.
                </Text>
                {/*<Title order={3} size="h4" weight={500}>*/}
                {/*    Prevádzkovateľ*/}
                {/*</Title>*/}
                {/*<Text>*/}
                {/*    Codelity s. r. o., Húskova 1294/87, 040 23 Košice - mestská časť Sídlisko KVP, IČO: 55186157, email: codelitysro@gmail.com*/}
                {/*</Text>*/}
                <Title order={3} size="h4" weight={500}>
                    Základné pojmy a informácie
                </Title>
                <Text>
                    <Text span fw={500}>Osobným údajom</Text> je každá informácia o identifikovanej alebo identifikovateľnej fyzickej osobe (subjekt údajov). Identifikovateľnou fyzickou osobou je fyzická osoba, ktorú možno priamo alebo nepriamo identifikovať, najmä odkazom na určitý identifikátor (meno, priezvisko, číslo, sieťový identifikátor) alebo jeden či viacero špecifických prvkov fyzickej, fyziologickej, genetickej, mentálnej, ekonomickej, kultúrnej alebo spoločenskej identity tejto fyzickej osoby (ďalej len ako „osobné údaje“).
                </Text>
                <Text>
                    Tieto informácie môžu obsahovať Vaše osobné údaje ako návštevníka internetovej stránky a používateľa služby, ako aj osobné údaje Vašich klientov, teda klientov návštevníkov internetovej stránky a používateľov služby. Bežné osobné údaje zahŕňajú obvyklé informácie, ako napríklad:
                </Text>
                <List spacing="sm" withPadding>
                    <List.Item>meno, priezvisko a titul</List.Item>
                    <List.Item>adresa trvalého pobytu a korešpondenčná adresa</List.Item>
                    <List.Item>telefónne číslo</List.Item>
                    <List.Item>adresa elektronickej pošty</List.Item>
                    <List.Item>údaje o bankovom účte a iné</List.Item>
                </List>
                <Title order={3} size="h4" weight={500}>
                    Spracovanie osobných údajov
                </Title>
                <Text>
                    Osobné údaje spracúvame v dvoch prípadoch:
                </Text>
                <List type="ordered" spacing="sm" withPadding>
                    <List.Item>
                        V prípade, že ste používateľom v pozícii prevádzkovateľa a Vaši klienti sú dotknutými osobami, a taktiež nám ako spoločnosti poskytujete osobné údaje týchto dotknutých osôb, vykonávame spracúvanie osobných údajov na účely fakturácie. V tejto situácii vystupujeme ako sprostredkovateľ. Toto spracúvanie osobných údajov je založené na právnom základe podľa § 13 ods. 1 písm. b. zákona o ochrane osobných údajov, čo znamená, že je nevyhnutné na plnenie zmluvy. Spracúvame tiež osobné údaje Vašich klientov s cieľom poskytovať službu a prevádzkovať internetovú stránku. Účelom spracúvania osobných údajov na fakturáciu je správa administratívno-ekonomickej agendy Vašej podnikateľskej subjektivity, ktorá zahŕňa napríklad vytváranie faktúr, ich odosielanie e-mailom a poštou.
                    </List.Item>
                    <List.Item>
                        V prípade, že ste používateľom, naša spoločnosť spracúva Vaše osobné údaje v pozícii prevádzkovateľa a Vy ako používatelia ste dotknutými osobami. Hlavným dôvodom spracúvania Vašich osobných údajov je poskytovať Vám služby našej spoločnosti, či už ste registrovaný alebo neregistrovaný zákazník. Vaše osobné údaje sú potrebné na správu Vašich používateľských účtov a poskytnutie služieb. Toto spracúvanie osobných údajov je založené na právnom základe podľa článku 6 ods. 1 písm. b) Nariadenia, čo znamená, že spracúvanie osobných údajov je nevyhnutné na plnenie zmluvy, ktorej ste zmluvnou stranou.
                    </List.Item>
                </List>
                <Title order={3} size="h4" weight={500}>
                    Služby partnerov
                </Title>
                <Text>
                    Poskytovateľom serverového, dátového a databázového úložiska je spoločnosť DigitalOcean, LLC.
                </Text>
                <Text>
                    Pre prihlásenie je možné využiť prihlásenia pomocou účtu Google. Pri využití služby sa zvlášť aplikujú <Anchor href="https://policies.google.com/privacy" target="_blank">Pravidlá ochrany osobných údajov</Anchor> spoločnosti Google a ich <Anchor href="https://policies.google.com/terms" target="_blank">Zmluvné podmienky</Anchor>.
                </Text>
                {/*{renderList([*/}
                {/*    'V prípade, že ste používateľom v pozícii prevádzkovateľa a Vaši klienti sú dotknutými osobami, a taktiež nám ako spoločnosti poskytujete osobné údaje týchto dotknutých osôb, vykonávame spracúvanie osobných údajov na účely fakturácie. V tejto situácii vystupujeme ako sprostredkovateľ. Toto spracúvanie osobných údajov je založené na právnom základe podľa § 13 ods. 1 písm. b. zákona o ochrane osobných údajov, čo znamená, že je nevyhnutné na plnenie zmluvy. Spracúvame tiež osobné údaje Vašich klientov s cieľom poskytovať službu a prevádzkovať internetovú stránku. Účelom spracúvania osobných údajov na fakturáciu je správa administratívno-ekonomickej agendy Vašej podnikateľskej subjektivity, ktorá zahŕňa napríklad vytváranie faktúr, ich odosielanie e-mailom a poštou.',*/}
                {/*    'V prípade, že ste používateľom, naša spoločnosť spracúva Vaše osobné údaje v pozícii prevádzkovateľa a Vy ako používatelia ste dotknutými osobami. Hlavným dôvodom spracúvania Vašich osobných údajov je poskytovať Vám služby našej spoločnosti, či už ste registrovaný alebo neregistrovaný zákazník. Vaše osobné údaje sú potrebné na správu Vašich používateľských účtov a poskytnutie služieb. Toto spracúvanie osobných údajov je založené na právnom základe podľa článku 6 ods. 1 písm. b) Nariadenia, čo znamená, že spracúvanie osobných údajov je nevyhnutné na plnenie zmluvy, ktorej ste zmluvnou stranou.',*/}
                {/*], undefined, true)}*/}
                <Title order={3} size="h4" weight={500}>
                    Práva dotknutej osoby
                </Title>
                <Text>
                    Ako osoba, ktorej sa týkajú osobné údaje, máte nasledujúce práva v súlade s príslušnými ustanoveniami zákona č. 18/2018 Z. z. o ochrane osobných údajov a o zmene a doplnení niektorých zákonov a Nariadenia Európskeho parlamentu a Rady (EÚ) 2016/679 o ochrane fyzických osôb pri spracúvaní osobných údajov a o voľnom pohybe takýchto údajov, ktorým sa zrušuje smernica 95/46/ES:
                </Text>
                {renderList([
                    'Právo kedykoľvek odvolať súhlas so spracúvaním.',
                    'Právo na prístup k Vašim osobným údajom.',
                    'Právo žiadať o opravu Vašich osobných údajov.',
                    'Právo vznášať námietky voči spracúvaniu Vašich osobných údajov.',
                    'Právo na vymazanie Vašich osobných údajov (tzv. "právo byť zabudnutý").',
                    'Právo na prenositeľnosť Vašich osobných údajov.',
                    'Právo požiadať o obmedzenie spracúvania Vašich osobných údajov.',
                    'Právo podať sťažnosť príslušnému orgánu dozoru, ktorým na Slovensku je Úrad na ochranu osobných údajov Slovenskej republiky, Hraničná 12, 820 07 Bratislava 27, Slovenská republika.',
                    'Ak máte podozrenie, že Vaše osobné údaje sa spracúvajú v rozpore s platnými právnymi predpismi alebo že sú nepresné, máte právo žiadať vysvetlenie alebo odstránenie takéhoto stavu, vrátane blokovania, opravy, doplnenia alebo likvidácie Vašich osobných údajov.',
                ], undefined, true)}
                <Title order={3} size="h4" weight={500}>
                    Uplatnenie práv
                </Title>
                <Text>
                    Pre uplatnenie vyššie uvedených práv môžete kontaktovať našu spoločnosť Codelity s. r. o. prostredníctvom: codelitysro@gmail.com, alebo poštou na adrese spoločnosti Húskova 1294/87, 040 23 Košice - mestská časť Sídlisko KVP.
                </Text>
                <Title order={3} size="h4" weight={500}>
                    Cookies
                </Title>
                <Text>
                    Webové stránky používajú súbory cookies na zlepšenie užívateľského zážitku, personalizáciu obsahu a sledovanie návštevnosti. Cookies sú malé textové súbory, ktoré sa ukladajú do prehliadača užívateľa a uchovávajú informácie o jeho interakcii s webovou stránkou.
                </Text>
                <Text>
                    V našom prípade využívame iba nevyhnutné technické cookies, ktoré zabezpečujú správne fungovanie služby. Konkrétne nasledovné cookies:
                </Text>
                <Table withBorder>
                    <thead>
                        <tr>
                            <th>Názov</th>
                            <th>Prístup</th>
                            <th>Expirácia</th>
                            <th>Popis</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>.*.Identity.Application</td>
                            <td>fakturyplus.sk</td>
                            <td>Sedenie</td>
                            <td>Nevyhnutný pre identifikáciu používateľa a správne fungovanie autentifikácie a autorizácie v aplikácii. Tento cookie môže uchovávať informácie o prihlásenom používateľovi a jeho oprávneniach, čo umožňuje poskytovať personalizovaný obsah a zabezpečiť prístup k rôznym častiam aplikácie v súlade s nastaveniami používateľa.</td>
                        </tr>
                        <tr>
                            <td>.*.Antiforgery.*</td>
                            <td>fakturyplus.sk</td>
                            <td>Sedenie</td>
                            <td>Slúži na zabezpečenie ochrany proti útokom typu "Cross-Site Request Forgery" (CSRF). Tento cookie obsahuje token, ktorý je použitý na overenie autenticity požiadaviek odosielaných z webového prehliadača na server. {/*Pomocou tohto mechanizmu sa zabezpečuje, že požiadavky pochádzajú od dôveryhodného zdroja a nie sú manipulované tretími stranami, čím sa zvyšuje bezpečnosť aplikácie a ochraňujú sa dôverné údaje používateľov.*/}</td>
                        </tr>
                        <tr>
                            <td>_auth_*</td>
                            <td>.fakturyplus.sk</td>
                            <td style={{whiteSpace: "nowrap"}}>2 dni</td>
                            <td>Nevyhnutný pre identifikáciu používateľa a správne fungovanie autentifikácie a autorizácie v aplikácii.</td>
                        </tr>
                        <tr>
                            <td>g_state</td>
                            <td>fakturyplus.sk</td>
                            <td style={{whiteSpace: "nowrap"}}>6 mesiacov</td>
                            <td>Potrebný pre správne fungovanie prihlásenia pomocou Google.</td>
                        </tr>
                        <tr>
                            <td>lang</td>
                            <td>fakturyplus.sk</td>
                            <td>Sedenie</td>
                            <td>Slúži na uchovávanie jazykového nastavenia používateľa na webovej stránke.</td>
                        </tr>
                        <tr>
                            <td>newYear</td>
                            <td>fakturyplus.sk</td>
                            <td>Sedenie</td>
                            <td>Slúži na jednorazové zobrazenie upozornenia používateľovi o možnosti filtrovania zobrazovaných údajov.</td>
                        </tr>
                    </tbody>
                </Table>
            </Stack>),
            // contentEn: (<Group position="center" py={rem(60)}><Loader color="gray" variant="oval" /></Group>)
        }
    };

    const isSomehowSmall = useMediaQuery("(max-width: 32em)");
    const articleDef = articles[article];
    return (
        <AppShell
            styles={{
                main: {
                    background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
                },
            }}
            layout="alt"
            navbarOffsetBreakpoint="sm"
            asideOffsetBreakpoint="sm"
            footer={
                <Footer height="auto" p="xs">
                    <Container>
                        <Flex justify="space-between" align="center" direction="row" gap={isSomehowSmall ? 6 : 0}>
                            <Flex justify={isSomehowSmall ? 'center' : 'start'} align={isSomehowSmall ? 'start' : 'center'} gap={isSomehowSmall ? 0 : 12} direction={isSomehowSmall ? 'column' : 'row'}>
                                <Anchor color="gray.5" href="/terms-of-service">
                                    {t('Pravidlá používania')}
                                </Anchor>
                                {!isSomehowSmall && (
                                    <Divider orientation="vertical" />
                                )}
                                <Anchor color="gray.5" href="/privacy-policy">
                                    {t('Ochrana osobných údajov')}
                                </Anchor>
                            </Flex>
                            <LanguagePicker width="auto" hideLabel compact />
                        </Flex>
                    </Container>
                </Footer>
            }
            header={
                <Header height={56} mb={120}>
                    <Container>
                        <div className={classes.headerInner}>
                            <Group spacing="xs" noWrap={true} onClick={() => navigate('/')} sx={{ cursor: 'pointer' }}> {/*, '&:hover .mantine-Title-root': { filter: 'opacity(75%)' }*/}
                                <BrandIcon size={28} />
                                <MediaQuery smallerThan="xs" styles={{ display: 'none' }}>
                                    <Title order={2} size="h3">FaktúryPlus<sup>+</sup></Title>
                                </MediaQuery>
                            </Group>

                            <Button component="a" href="/">{t('Naspäť')}</Button>
                        </div>
                    </Container>
                </Header>
            }
        >
            <Notifications position="top-right" />
            <Container pb={isSomehowSmall ? rem(100) : rem(80)}>
                <div className={classes.inner}>
                    <Title order={1} size="h2" align="center" mb="lg">
                        {articleDef.title}
                    </Title>

                    {/*{i18n.language == 'sk' ? articleDef.contentSk : articleDef.contentEn}*/}
                    {articleDef.content}
                </div>
            </Container>
        </AppShell>
    );
}

import React from 'react';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { AuthProvider, useIsAuthenticated } from 'react-auth-kit';
import { FakturyPlusApiProvider } from './api/FakturyPlusApiContext';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Layout } from './Layout';
import { Home } from './pages/Home';
import { Dashboard } from './pages/Dashboard';
import { Invoices } from './pages/Invoices';
import { InvoiceForm } from "./pages/InvoiceForm";
import { Page404 } from "./pages/static/Page404";
import {ModalsProvider} from "@mantine/modals";
import {BankAccounts} from "./pages/BankAccounts";
import {ArticleEnum, StaticArticle} from "./pages/static/StaticArticle";

export default function App() {
    // https://github.com/react-auth-kit/react-auth-kit/issues/1193
    // https://github.com/react-auth-kit/react-auth-kit/issues/1023
    const PrivateRoute = ({ component, loginPath }: { component: JSX.Element, loginPath: string }) => {
        const isAuthenticated = useIsAuthenticated();
        const auth = isAuthenticated();
        return auth ? component : <Navigate to={loginPath} />;
    };

    const redirectPath = (redirectUrl: string) => {
        return `/?fplr=${encodeURIComponent(redirectUrl)}`;
    }

    return (
        <MantineProvider withGlobalStyles withNormalizeCSS>
            <ModalsProvider>
                {/*TODO: refresh token (1) https://authkit.arkadip.dev/integration/#props */}
                <AuthProvider authType = {'cookie'}
                              authName={'_auth'}
                              cookieDomain={window.location.hostname}
                              cookieSecure={window.location.protocol === "https:"}>
                    <FakturyPlusApiProvider>
                        <BrowserRouter>
                            <Routes>
                                {/*<Route path="/" element={<Layout />}>*/}
                                <Route index element={<Home />} />
                                <Route path="login" element={<Home showLogin={true} />}></Route>
                                <Route path="register" element={<Home showRegister={true} />}></Route>
                                {/*</Route>*/}
                                <Route path="/" element={<Layout />}>
                                    <Route path="dashboard" element={<PrivateRoute component={<Dashboard />} loginPath={redirectPath('/dashboard')} />}></Route>
                                    <Route path="invoices" element={<PrivateRoute component={<Invoices />} loginPath={redirectPath('/invoices')} />}></Route>
                                    <Route path="invoices/:id" element={<PrivateRoute component={<InvoiceForm />} loginPath={redirectPath('/invoices')} />}></Route>
                                    <Route path="invoices/clone/:id" element={<PrivateRoute component={<InvoiceForm clone={true} />} loginPath={redirectPath('/invoices')} />}></Route>
                                    <Route path="bank-accounts" element={<PrivateRoute component={<BankAccounts />} loginPath={redirectPath('/bank-accounts')} />}></Route>
                                </Route>
                                <Route path="terms-of-service" element={<StaticArticle article={ArticleEnum.TermsOfService} />} />
                                <Route path="privacy-policy" element={<StaticArticle article={ArticleEnum.PrivacyPolicy} />} />
                                <Route path="*" element={<Page404 />} />
                            </Routes>
                        </BrowserRouter>
                    </FakturyPlusApiProvider>
                </AuthProvider>
            </ModalsProvider>
        </MantineProvider>
    );
}

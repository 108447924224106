import React, { useState } from "react";
import {UseFormReturnType} from "@mantine/form";
import {EditInvoiceFormProps} from "./formDefinition";
import {
    ActionIcon, Alert,
    Collapse,
    Divider,
    Drawer,
    Grid,
    Group,
    rem,
    ScrollArea,
    Switch,
    Text,
    TextInput
} from "@mantine/core";
import {IconAlertCircle, IconAt, IconPhone, IconSearch, IconSearchOff, IconWorld, IconX} from "@tabler/icons-react";
import {useTranslation} from "react-i18next";
import {AutofillResult} from "../../api/faktury-plus";
import {OrsrAutofill} from "./OrsrAutofill";

export interface ClientDrawerProps {
    form: UseFormReturnType<EditInvoiceFormProps>;
    opened: boolean;
    onClose: () => void;
}

export function ClientDrawer({ form, opened, onClose, ...others }: ClientDrawerProps) {
    const { t } = useTranslation();

    const [showSearch, setShowSearch] = useState(true);
    const [isFilling, setIsFilling] = useState(false);

    const fillData = (data?: AutofillResult) => {
        form.setValues({
            ...form.values,
            client: {
                // ...form.values.client,
                contactPersonName: '',
                taxId: '',
                vatId: '',
                phone: '',
                email: '',
                website: '',
                usePostalAddress: false,
                postalAddress: { },
                brn:  data?.brn ?? '',
                billingAddress: {
                    companyName: data?.companyName ?? '',
                    street: data?.street ?? '',
                    streetNumber: data?.streetNumber ?? '',
                    zip: data?.zip ?? '',
                    city: data?.city ?? '',
                    country: data?.country ?? '',
                }
            }
        });
    }

    return (
        <Drawer
            opened={opened}
            onClose={onClose}
            position="right"
            title={t('Odberateľ')}
            size="md"
            scrollAreaComponent={ScrollArea.Autosize}
            sx={{
                '& .mantine-Drawer-header': { paddingLeft: rem(24), paddingRight: rem(19) },
                '& .mantine-Drawer-body': { paddingLeft: rem(24), paddingRight: rem(24) },
            }}
        >
            <Divider mb={rem(16)} />
            <Grid gutter="sm">
                <Grid.Col span={12} pb={0}>
                    <Group position="apart">
                        <Text size="md" color="dimmed">{t('Fakturačná adresa')}</Text>
                        <ActionIcon variant="light" color="blue" onClick={() => setShowSearch(!showSearch)}>
                            {showSearch ? (
                                <IconSearchOff size="1rem" />
                            ) : (
                                <IconSearch size="1rem" />
                            )}
                        </ActionIcon>
                    </Group>
                </Grid.Col>
                <Grid.Col span={12} py={showSearch ? undefined : '0'}>
                    <Collapse in={showSearch} transitionDuration={500}>
                        <OrsrAutofill
                            showSearch={showSearch}
                            setShowSearch={setShowSearch}
                            isFilling={isFilling}
                            setIsFilling={setIsFilling}
                            fillData={fillData}
                        />
                        <Alert icon={<IconAlertCircle size="1rem" />} color="gray">
                            {t('Automaticky doplnené údaje skontrolujte a doplňte daňové informácie (DIČ, IČ DPH).')}
                        </Alert>
                    </Collapse>
                </Grid.Col>
                <Grid.Col span={12}>
                    <TextInput
                        label={t('Meno / Názov spoločnosti')}
                        disabled={isFilling}
                        {...form.getInputProps(`client.billingAddress.companyName`)} />
                </Grid.Col>
                <Grid.Col span={12}>
                    <TextInput
                        label={t('Kontaktná osoba')}
                        disabled={isFilling}
                        {...form.getInputProps(`client.contactPersonName`)} />
                </Grid.Col>
                <Grid.Col span={8}>
                    <TextInput
                        label={t('Ulica')}
                        disabled={isFilling}
                        {...form.getInputProps(`client.billingAddress.street`)} />
                </Grid.Col>
                <Grid.Col span={4}>
                    <TextInput
                        label={t('Číslo')}
                        disabled={isFilling}
                        {...form.getInputProps(`client.billingAddress.streetNumber`)} />
                </Grid.Col>
                <Grid.Col span={8}>
                    <TextInput
                        label={t('Mesto')}
                        disabled={isFilling}
                        {...form.getInputProps(`client.billingAddress.city`)} />
                </Grid.Col>
                <Grid.Col span={4}>
                    <TextInput
                        label={t('PSČ')}
                        disabled={isFilling}
                        {...form.getInputProps(`client.billingAddress.zip`)} />
                </Grid.Col>
                <Grid.Col span={12}>
                    <TextInput
                        label={t('Štát')}
                        disabled={isFilling}
                        {...form.getInputProps(`client.billingAddress.country`)} />
                </Grid.Col>
                <Grid.Col span={6}>
                    <TextInput
                        label={t('IČO')}
                        disabled={isFilling}
                        {...form.getInputProps(`client.brn`)} />
                </Grid.Col>
                <Grid.Col span={6}>
                    <TextInput
                        label={t('DIČ')}
                        disabled={isFilling}
                        {...form.getInputProps(`client.taxId`)} />
                </Grid.Col>
                <Grid.Col span={12}>
                    <TextInput
                        label={t('IČ DPH')}
                        disabled={isFilling}
                        {...form.getInputProps(`client.vatId`)} />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Group mt={16}>
                        <Text size="md" color="dimmed">{t('Poštová adresa')}</Text>
                        <Switch
                            {...form.getInputProps('client.usePostalAddress', { type: 'checkbox' })} />
                    </Group>
                </Grid.Col>
            </Grid>
            {form.values.client.usePostalAddress && (
                <Grid gutter="sm">
                    <Grid.Col span={12}>
                        <TextInput
                            label={t('Meno / Názov spoločnosti')}
                            {...form.getInputProps(`client.postalAddress.companyName`)} />
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <TextInput
                            label={t('Ulica')}
                            {...form.getInputProps(`client.postalAddress.street`)} />
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <TextInput
                            label={t('Číslo')}
                            {...form.getInputProps(`client.postalAddress.streetNumber`)} />
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <TextInput
                            label={t('Mesto')}
                            {...form.getInputProps(`client.postalAddress.city`)} />
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <TextInput
                            label={t('PSČ')}
                            {...form.getInputProps(`client.postalAddress.zip`)} />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <TextInput
                            label={t('Štát')}
                            {...form.getInputProps(`client.postalAddress.country`)} />
                    </Grid.Col>
                </Grid>
            )}
            <Grid gutter="sm" mt={16} mb={8}>
                <Grid.Col span={12} pb={0}>
                    <Text size="md" color="dimmed">{t('Kontakt')}</Text>
                </Grid.Col>
                <Grid.Col span={12}>
                    <TextInput
                        label={t('Telefón')}
                        icon={<IconPhone size="1rem" />}
                        {...form.getInputProps(`client.phone`)} />
                </Grid.Col>
                <Grid.Col span={12}>
                    <TextInput
                        label={t('E-mail')}
                        icon={<IconAt size="1rem" />}
                        {...form.getInputProps(`client.email`)} />
                </Grid.Col>
                <Grid.Col span={12}>
                    <TextInput
                        label={t('Webstránka')}
                        icon={<IconWorld size="1rem" />}
                        {...form.getInputProps(`client.website`)} />
                </Grid.Col>
            </Grid>
        </Drawer>
    );
}

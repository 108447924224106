import React, {forwardRef, useRef, useState} from 'react';
import {ActionIcon, Autocomplete, AutocompleteItem, Group, Loader, Text} from "@mantine/core";
import {IconSearch} from "@tabler/icons-react";
import {isnum} from "../../helpers/format";
import {AutofillResult, FindResult} from "../../api/faktury-plus";
import {useFakturyPlusApi} from "../../api/FakturyPlusApiContext";
import {useTranslation} from "react-i18next";

const AutoCompleteItem = forwardRef<HTMLDivElement, FindResult>(
    ({ title, link, ...others }: FindResult, ref) => (
        <div ref={ref} {...others}>
            <Text>{title}</Text>
        </div>
    )
);

interface OrsrAutofillProps {
    showSearch: boolean;
    setShowSearch: (showSearch: boolean) => void;
    isFilling: boolean;
    setIsFilling: (isFilling: boolean) => void;
    fillData: (data?: AutofillResult) => void;
}
export function OrsrAutofill({ showSearch, setShowSearch, isFilling, setIsFilling, fillData }: OrsrAutofillProps) {
    const { t } = useTranslation();
    const api = useFakturyPlusApi();

    const [searchValue, setSearchValue] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [searchData, setSearchData] = useState<FindResult[]>([]);

    const autocompleteRef = useRef<HTMLInputElement>(null);

    const searchOrsr = () => {
        if (autocompleteRef.current) {
            autocompleteRef.current.focus();
        }

        setSearchData([]);

        let search = searchValue.trim();
        if (!search || !search.length) {
            return;
        }

        setIsSearching(true);

        const searchFunction = (query: string) => {
            return query.length == 8 && isnum(search)
                ? api().orsrApi().v1OrsrBrnGet(query)
                : api().orsrApi().v1OrsrBnGet(query);
        }

        searchFunction(search).then((response) => {
            setSearchData(response.data.data ?? []);
        })
        .catch((error) => {
            // TODO
            console.log(error);
        })
        .finally(() => {
            setIsSearching(false);
        });
    }

    const getSubjectAddress = (item: AutocompleteItem | FindResult) => {
        console.log(item);
        const oldSearchData = searchData;
        setSearchData([]);
        setIsFilling(true);

        api().orsrApi().v1OrsrAutofillGet(item.link).then((response) => {
            fillData(response.data.data);
            setSearchValue('');
            setShowSearch(false);
        })
        .catch((error) => {
            // TODO
            console.log(error);
            setSearchData(oldSearchData);
        })
        .finally(() => {
            setIsFilling(false);
        });
    }

    const autocompleteData = searchData.map((item) =>
        ({ ...item, value: item.title ?? '' }));

    return (
        <Autocomplete
            ref={autocompleteRef}
            data-autofocus
            placeholder={t<string>('Vyhľadávanie podľa obchodného mena / IČO')}
            variant="filled"
            itemComponent={AutoCompleteItem}
            data={autocompleteData}
            limit={5}
            // nothingFound={"YAA"}
            value={searchValue}
            onChange={(value) => {
                setSearchData([]);
                setSearchValue(value);
            }}
            filter={(value, item) => true}
            onItemSubmit={item => {
                getSubjectAddress(item);
            }}
            rightSection={
                <div>
                    <Group>
                        {(isSearching || isFilling) ? (
                            <Loader variant="dots" size="1rem" />
                        ) : (
                            <ActionIcon color="blue" variant="filled" onClick={searchOrsr}>
                                <IconSearch size="1rem" />
                            </ActionIcon>
                        )}
                    </Group>
                </div>
            }
            onKeyDown={event => {
                if (event.key === 'Enter' && !isSearching && !isFilling) {
                    searchOrsr();
                }
            }}
        />
    );
}

import React, {useEffect, useState} from 'react';
import {createStyles, UnstyledButton, Menu, Group, rem, Space} from '@mantine/core';
import {IconChevronDown} from '@tabler/icons-react';
import {useCookies} from "react-cookie";
import {useTranslation} from "react-i18next";

const data = [
    {
        key: 'sk',
        label: 'Slovensky',
        image: (width: number, height: number) => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 6" width={rem(width)} height={rem(height)}>
            <path fill="#ee1c25" d="M0 0h9v6H0z"></path><path fill="#0b4ea2" d="M0 0h9v4H0z"></path><path fill="#fff" d="M0 0h9v2H0z"></path><path fill="#fff" d="M3.163 4.635c-.538-.259-1.308-.773-1.308-1.79s.05-1.48.05-1.48H4.42s.049.463.049 1.48-.77 1.53-1.307 1.79z"></path><path fill="#ee1c25" d="M3.163 4.5c-.494-.238-1.2-.71-1.2-1.643S2.008 1.5 2.008 1.5h2.309s.045.424.045 1.357c0 .934-.706 1.405-1.2 1.643z"></path><path fill="#fff" d="M3.268 2.613c.134.002.394.007.626-.07 0 0-.006.083-.006.18 0 .096.006.18.006.18-.212-.072-.475-.074-.626-.072v.516h-.211V2.83c-.15-.002-.413 0-.626.071 0 0 .006-.083.006-.18 0-.096-.006-.18-.006-.18.232.078.492.073.626.07V2.29a1.481 1.481 0 00-.496.071s.007-.083.007-.18c0-.096-.007-.18-.007-.18.199.067.374.073.496.072-.007-.205-.066-.464-.066-.464s.122.01.172.01c.049 0 .172-.01.172-.01s-.06.259-.066.464A1.48 1.48 0 003.764 2s-.006.083-.006.18c0 .096.006.18.006.18a1.481 1.481 0 00-.496-.072v.324z"></path><path fill="#0b4ea2" d="M3.163 3.29c-.249 0-.382.346-.382.346s-.074-.164-.277-.164c-.137 0-.238.122-.302.235.25.397.648.642.96.793.313-.15.712-.396.961-.793-.064-.113-.165-.235-.302-.235-.203 0-.277.164-.277.164s-.133-.345-.382-.345z"></path>
        </svg>)
    },
    {
        key: 'en',
        label: 'English',
        image: (width: number, height: number) => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55.2 38.4" width={rem(width)} height={rem(height)}>
            <g><path fill="#fefefe" d="M2.87,38.4h49.46c1.59-0.09,2.87-1.42,2.87-3.03V3.03c0-1.66-1.35-3.02-3.01-3.03H3.01 C1.35,0.01,0,1.37,0,3.03v32.33C0,36.98,1.28,38.31,2.87,38.4L2.87,38.4z"></path><polygon fill="#c8102e" points="23.74,23.03 23.74,38.4 31.42,38.4 31.42,23.03 55.2,23.03 55.2,15.35 31.42,15.35 31.42,0 23.74,0 23.74,15.35 0,15.35 0,23.03 23.74,23.03"></polygon><path fill="#012169" d="M33.98,12.43V0h18.23c1.26,0.02,2.34,0.81,2.78,1.92L33.98,12.43L33.98,12.43z"></path><path fill="#012169" d="M33.98,25.97V38.4h18.35c1.21-0.07,2.23-0.85,2.66-1.92L33.98,25.97L33.98,25.97z"></path><path fill="#012169" d="M21.18,25.97V38.4H2.87c-1.21-0.07-2.24-0.85-2.66-1.94L21.18,25.97L21.18,25.97z"></path><path fill="#012169" d="M21.18,12.43V0H2.99C1.73,0.02,0.64,0.82,0.21,1.94L21.18,12.43L21.18,12.43z"></path><polygon fill="#012169" points="0,12.8 7.65,12.8 0,8.97 0,12.8"></polygon><polygon fill="#012169" points="55.2,12.8 47.51,12.8 55.2,8.95 55.2,12.8"></polygon><polygon fill="#012169" points="55.2,25.6 47.51,25.6 55.2,29.45 55.2,25.6"></polygon><polygon fill="#012169" points="0,25.6 7.65,25.6 0,29.43 0,25.6"></polygon><polygon fill="#c8102e" points="55.2,3.25 36.15,12.8 40.41,12.8 55.2,5.4 55.2,3.25"></polygon><polygon fill="#c8102e" points="19.01,25.6 14.75,25.6 0,32.98 0,35.13 19.05,25.6 19.01,25.6"></polygon><polygon fill="#c8102e" points="10.52,12.81 14.78,12.81 0,5.41 0,7.55 10.52,12.81"></polygon><polygon fill="#c8102e" points="44.63,25.59 40.37,25.59 55.2,33.02 55.2,30.88 44.63,25.59"></polygon></g>
        </svg>)
    },
];

const useStyles = createStyles((theme, { opened, width, compact }: { opened: boolean, width: string, compact?: boolean }) => ({
    control: {
        // width: rem(200),
        width: width,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: compact ? `${rem(6)} ${theme.spacing.md}` : `${theme.spacing.xs} ${theme.spacing.md}`,
        borderRadius: theme.radius.md,
        border: `${rem(1)} solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2]
        }`,
        transition: 'background-color 150ms ease',
        backgroundColor:
            theme.colorScheme === 'dark'
                ? theme.colors.dark[opened ? 5 : 6]
                : opened
                    ? theme.colors.gray[0]
                    : theme.white,

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[0],
        },
    },

    label: {
        fontWeight: 500,
        fontSize: theme.fontSizes.sm,
    },

    icon: {
        transition: 'transform 150ms ease',
        transform: opened ? 'rotate(180deg)' : 'rotate(0deg)',
    },
}));

interface LanguagePickerProps {
    width?: string;
    hideLabel?: boolean;
    compact?: boolean;
}
export function LanguagePicker({ width = '100%', hideLabel, compact }: LanguagePickerProps) {
    const { i18n } = useTranslation();
    const [opened, setOpened] = useState(false);
    const { classes } = useStyles({ opened, width, compact });
    // const [selected, setSelected] = useState(data[0]);

    const [cookies, setCookie] = useCookies(['lang']);

    useEffect(() => {
        if (cookies.lang && cookies.lang != i18n.language) {
            i18n.changeLanguage(cookies.lang);
        }
    }, [cookies]);

    if (cookies.lang && cookies.lang != i18n.language) {
        i18n.changeLanguage(cookies.lang);
    }

    const changeLanguage = (lang: string) => {
        i18n.changeLanguage(lang);
        setCookie('lang', lang)
    }

    const selected = data.find(i => i.key == i18n.language) ?? data[0];

    return (
        <Menu
            onOpen={() => setOpened(true)}
            onClose={() => setOpened(false)}
            radius="md"
            width="target"
            withinPortal
        >
            <Menu.Target>
                <UnstyledButton className={classes.control}>
                    <Group spacing="xs">
                        {selected.image(22, 22)}
                        {hideLabel ? (
                            <Space />
                        ) : (
                            <span className={classes.label}>{selected.label}</span>
                        )}
                    </Group>
                    <IconChevronDown size="1rem" className={classes.icon} stroke={0.5} />
                </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown>{data.map((item) => (
                <Menu.Item
                    icon={item.image(18, 18)}
                    onClick={() => changeLanguage(item.key)}
                    key={item.key}
                >
                    {!hideLabel && (
                        item.label
                    )}
                </Menu.Item>
            ))}</Menu.Dropdown>
        </Menu>
    );
}

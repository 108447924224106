import * as React from 'react';
import { useEffect, useState } from 'react';
import { useAuthHeader } from 'react-auth-kit';
import FakturyPlusApi from './FakturyPlusApi';

const ApiContext = React.createContext<() => FakturyPlusApi>(() => new FakturyPlusApi());

interface ApiProviderProps {
    children?: React.ReactNode;
}
const ApiProvider = (props: ApiProviderProps) => {
    const authHeader = useAuthHeader();

    // const [api, setApi] = useState<FakturyPlusApi>(new FakturyPlusApi(authHeader()));

    // useEffect(() => {
    //     setApi(new FakturyPlusApi(authHeader()));
    // }, [authHeader()])

    return(
        <ApiContext.Provider value={() => new FakturyPlusApi(authHeader())}>
            {props.children}
        </ApiContext.Provider>
    )
}

function useFakturyPlusApi(): () => FakturyPlusApi {
    const context = React.useContext(ApiContext);
    if (context === undefined) {
        throw new Error('Method \'useFakturyPlusApi\' must be used within a FakturyPlusApiProvider.');
    }
    return context;
}

export interface WithFakturyPlusApiProps {
    api: () => FakturyPlusApi;
}
const withFakturyPlusApi = <P extends object>(
    Component: React.ComponentType<P>,
): React.FunctionComponent<Omit<P, keyof WithFakturyPlusApiProps>> => props => (
    <ApiContext.Consumer>
        {(api) => <Component {...props as P} api={api} />}
    </ApiContext.Consumer>
);

export { ApiContext as FakturyPlusApiContext, ApiProvider as FakturyPlusApiProvider, useFakturyPlusApi, withFakturyPlusApi };

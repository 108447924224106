import React, {useEffect, useState} from "react";
import {
    ActionIcon,
    Anchor,
    AppShell, Box,
    Button,
    Checkbox,
    Container,
    createStyles,
    Divider, Flex,
    Footer,
    Group,
    Image,
    List, Loader,
    MediaQuery,
    Modal,
    PasswordInput,
    rem,
    Stack,
    Text,
    TextInput,
    ThemeIcon,
    Title,
    useMantineTheme
} from '@mantine/core';
import { HeaderMenu } from "./HeaderMenu";
import {IconCheck, IconX} from "@tabler/icons-react";
import {useDisclosure, useMediaQuery, useToggle} from "@mantine/hooks";
import {hasLength, isEmail, isNotEmpty, useForm} from "@mantine/form";
import {useTranslation} from "react-i18next";
import {GoogleLoginButton} from "./GoogleLoginButton";
import {TokenResponseResult} from "../../api/faktury-plus";
import {useIsAuthenticated, useSignIn} from "react-auth-kit";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {useFakturyPlusApi} from "../../api/FakturyPlusApiContext";
import {UserAuthState} from "../../auth/UserAuthState";
import {Notifications} from "@mantine/notifications";
import {LanguagePicker} from "../../Layout/LanguagePicker";
import {BrandIcon} from "../../icons/BrandIcon";
import imgDashboard from './img/sm-dashboard2.png';
import imgForm from './img/sm-form2.png';
import imgInvoice from './img/sm-invoice.png';

const useStyles = createStyles((theme) => ({
    inner: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: `calc(${theme.spacing.xl} * 2)`,
        paddingBottom: `calc(${theme.spacing.xl} * 2)`,

        [theme.fn.largerThan('md')]: {
            paddingTop: `calc(${theme.spacing.xl} * 3)`,
        },
        [`@media (min-width: 121em)`]: {
            paddingTop: `calc(${theme.spacing.xl} * 4)`,
        },
    },

    content: {
        maxWidth: rem(480),
        marginRight: `calc(${theme.spacing.xl} * 3)`,

        [theme.fn.smallerThan('md')]: {
            maxWidth: '100%',
            marginRight: 0,
        },
    },

    footer: {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        borderTop: `${rem(1)} solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
        }`,
        paddingTop: theme.spacing.sm,
        paddingBottom: theme.spacing.sm,
        margin: '2rem -1rem -1rem -1rem',
        // marginTop: rem(120),
        // paddingTop: `calc(${theme.spacing.xl} * 2)`,
        // paddingBottom: `calc(${theme.spacing.xl} * 2)`,
    },

    title: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontSize: rem(44),
        lineHeight: 1.2,
        fontWeight: 900,

        [theme.fn.smallerThan('xs')]: {
            fontSize: rem(28),
        },
    },

    control: {
        [theme.fn.smallerThan('xs')]: {
            flex: 1,
        },
    },

    image: {
        flex: 1,

        [theme.fn.smallerThan('md')]: {
            display: 'none',
        },
    },

    // highlight: {
    //     position: 'relative',
    //     // backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
    //     color: theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6],
    //     borderRadius: theme.radius.sm,
    //     // padding: `${rem(4)} ${rem(12)}`,
    // },
}));

export interface LoginFormProps {
    email: string;
    password: string;
}

export interface RegisterFormProps {
    name: string;
    email: string;
    password: string;
    terms: boolean;
}

export interface HomeProps {
    showLogin?: boolean;
    showRegister?: boolean;
}

export function Home({ showLogin, showRegister, ...others }: HomeProps) {
    const theme = useMantineTheme();
    const api = useFakturyPlusApi();
    const { t } = useTranslation();
    const signIn = useSignIn();
    const { classes } = useStyles();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    let location = useLocation();
    const isAuthenticated = useIsAuthenticated();

    const [authLoading, setAuthLoading] = useState(false);
    const [googleError, setGoogleError] = useState('');
    const [authError, setAuthError] = useState('');
    const [showSuccessfulRegistration, setShowSuccessfulRegistration] = useState(false);
    const [loginRedirectUrl, setLoginRedirectUrl] = React.useState('/dashboard');

    useEffect(() => {
        let params = new URLSearchParams(searchParams.toString());
        // const params = new URLSearchParams(location.search);
        if (params.has('fplr')) {
            setLoginRedirectUrl(params.get("fplr") ?? '');
            params.delete('fplr');
            setSearchParams(params.toString());

            closeAuthModal(); // WHY?
        }
    }, [location]);

    const [authModalOpened, { open: openAuthModal, close: closeAuthModal }] = useDisclosure(showLogin || showRegister);
    const [authModalType, toggleAuthModalType] = useToggle(showRegister ? ['register', 'login'] : ['login', 'register']);

    console.log(loginRedirectUrl);
    const closeAuthModalWithRedirect = () => {
        closeAuthModal();
        if (location.pathname.includes('/login') || location.pathname.includes('/register')) {
            navigate('/');
        }
    }

    const openLogin = () => {
        toggleAuthModalType('login');
        openAuthModal();
    }

    const openRegister = () => {
        toggleAuthModalType('register');
        openAuthModal();
    }

    const loginForm = useForm<LoginFormProps>({
        initialValues: {
            email: '',
            password: ''
        },
        validate: {
            email: isNotEmpty(t('Prosím vyplňte')),
            password: isNotEmpty(t('Prosím vyplňte')),
        }
    });

    const registerForm = useForm<RegisterFormProps>({
        initialValues: {
            name: '',
            email: '',
            password: '',
            terms: false
        },
        validate: {
            email: isEmail(t('Prosím zadajte e-mailovú adresu v správnom formáte')),
            password: hasLength({ min: 6, max: 100 }, t('Prosím zadajte aspoň 6 znakov')),
            terms: value => value ? null : t('Je potrebný súhlas')
        }
    });

    const handleLoginResponse = (response: TokenResponseResult) => {
        let authStateData: UserAuthState = {
            email: response.data?.email,
            name: response.data?.name,
            imageUrl: response.data?.imageURL
        };

        if (authStateData.email != null && !authStateData.email.length) {
            authStateData.email = null;
        }
        if (authStateData.name != null && !authStateData.name.length) {
            authStateData.name = null;
        }
        if (authStateData.imageUrl != null && !authStateData.imageUrl.length) {
            authStateData.imageUrl = null;
        }

        if(signIn({
            token: response.data!.token!,
            expiresIn: response.data!.tokenTtl!,
            tokenType: "Bearer",
            authState: authStateData,
            // TODO: refresh token (2)
            // refreshToken: response.data.data!.refreshToken!,
            // refreshTokenExpireIn: response.data.data!.refreshTokenTtl!
        })) {
            if (loginRedirectUrl) {
                navigate(loginRedirectUrl);
            }
            // window.location.reload();
            closeAuthModal();
        } else {
            console.error('Unexpected error in react-auth-kit!');
            setAuthError(t<string>('Nastala neočakávaná chyba'));
        }
    }

    const submitAuthForm = authModalType == 'login'
        ? loginForm.onSubmit((values) => {
            setAuthError('');
            setAuthLoading(true);

            api().tokenApi().identityTokenPost(values)
                .then((response) => {
                    loginForm.reset();
                    handleLoginResponse(response.data);
                })
                .catch((error) => {
                    if (error.response?.status == 400) {
                        if (error.response?.data?.message) {
                            setAuthError(error.response?.data?.message);
                        }
                        // TODO:
                        // parseLoginValidationErrors(error.response?.data?.errors ?? []);
                        console.log(error.response?.data?.errors ?? []);
                    } else {
                        setAuthError(t<string>('Nepodarilo sa prihlásiť.'));
                        console.log(error);
                    }
                })
                .finally(() => setAuthLoading(false));
        })
        : registerForm.onSubmit(({terms, ...values }) => {
            setAuthError('');
            setAuthLoading(true);

            api().usersApi().identityUsersPost(values)
                .then((response) => {
                    setShowSuccessfulRegistration(true);
                    registerForm.reset();
                    toggleAuthModalType();
                })
                .catch((error) => {
                    if (error.response?.status == 400) {
                        // TODO:
                        // parseRegistrationValidationErrors(error.response?.data?.errors ?? []);
                        console.log(error.response?.data?.errors ?? []);
                    } else {
                        setAuthError(t<string>('Nepodarilo sa vytvoriť účet.'));
                        console.log(error);
                    }
                })
                .finally(() => setAuthLoading(false));
        })

    const images = [imgDashboard, imgForm, imgInvoice];
    const [imageIndex, setImageIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            let newImageIndex = imageIndex + 1;
            if (newImageIndex >= images.length) {
                newImageIndex = 0;
            }
            setImageIndex(newImageIndex);
        }, 2000);

        return () => {
            clearInterval(interval);
        };
    }, [imageIndex]);

    const isSomehowSmall = useMediaQuery("(max-width: 32em)");

    return (
        <AppShell
            styles={{
                main: {
                    background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
                },
            }}
            layout="alt"
            navbarOffsetBreakpoint="sm"
            asideOffsetBreakpoint="sm"
            footer={
                // <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
                    <Footer height="auto" p="xs">
                        <Container>
                            <Flex justify="space-between" align="center" direction="row" gap={isSomehowSmall ? 6 : 0}>
                                <Flex justify={isSomehowSmall ? 'center' : 'start'} align={isSomehowSmall ? 'start' : 'center'} gap={isSomehowSmall ? 0 : 12} direction={isSomehowSmall ? 'column' : 'row'}>
                                    <Anchor color="gray.5" href="/terms-of-service">
                                        {t('Pravidlá používania')}
                                    </Anchor>
                                    {!isSomehowSmall && (
                                        <Divider orientation="vertical" />
                                    )}
                                    {/*<MediaQuery smallerThan="xs" styles={{ display: 'none' }}>*/}
                                    {/*</MediaQuery>*/}
                                    <Anchor color="gray.5" href="/privacy-policy">
                                        {t('Ochrana osobných údajov')}
                                    </Anchor>
                                </Flex>
                                <LanguagePicker width="auto" hideLabel compact />
                            </Flex>
                        </Container>
                    </Footer>
                // </MediaQuery>
            }
            header={
                <HeaderMenu openLogin={openLogin} openRegister={openRegister} />
            }
        >
            <Notifications position="top-right" />
            <Modal size={authModalType == 'login' ? 'sm' : 'md'} opened={authModalOpened} onClose={closeAuthModalWithRedirect} withCloseButton={false}>
                <Flex align="baseline" px={rem(8)} mb="xl">
                    {/*<Title order={1} size="h3" align="center" ml="1.125rem" sx={{ flexGrow: 1 }}>*/}
                    <Text size="1.6rem" weight={500} align="center" ml="1.125rem" sx={{ flexGrow: 1 }}>
                        {authModalType == 'login' ? t('Prihlásenie') : t('Registrácia')}
                    </Text>
                    {/*</Title>*/}
                    <ActionIcon size="sm">
                        <IconX size="1rem" onClick={closeAuthModalWithRedirect} />
                    </ActionIcon>
                </Flex>
                <Box px="sm" pb="md" component="form" onSubmit={submitAuthForm}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }} pb="sm">
                        <GoogleLoginButton setLoading={setAuthLoading} handleLoginResponse={handleLoginResponse} setError={setGoogleError} />
                        {googleError.length > 0 &&
                            <Text fz="sm" color="red" align="center">{googleError}</Text>
                        }
                    </Box>

                    <Divider label={t('alebo')} labelPosition="center" my="lg" />

                    {authModalType == 'login' ? (
                        <Stack>
                            <TextInput
                                withAsterisk
                                name="email"
                                label={t('E-mail')}
                                {...loginForm.getInputProps('email')} />
                            <PasswordInput
                                withAsterisk
                                name="password"
                                label={t('Heslo')}
                                {...loginForm.getInputProps('password')} />
                            {authError.length > 0 &&
                                <Text fz="sm" color="red" align="center">{authError}</Text>
                            }
                            {showSuccessfulRegistration &&
                                <Text fz="sm" color="green" align="center">{t('Registrácia prebehla úspešne! Teraz sa možete prihlásiť.')}</Text>
                            }
                        </Stack>
                    ) : (
                        <Stack>
                            <TextInput
                                label={t('Meno')}
                                name="name"
                                {...registerForm.getInputProps('name')} />
                            <TextInput
                                withAsterisk
                                label={t('E-mail')}
                                name="email"
                                {...registerForm.getInputProps('email')} />
                            <PasswordInput
                                withAsterisk
                                label={t('Heslo')}
                                name="password"
                                {...registerForm.getInputProps('password')} />
                            <Checkbox
                                label={
                                    <>
                                        {t('Súhlasím s')}{' '}
                                        <Anchor href="/terms-of-service" target="_blank">
                                            {t('pravidlami používania')}
                                        </Anchor> {t('a udeľujem súhlas so')}{' '}
                                        <Anchor href="/privacy-policy" target="_blank">
                                            {t('spracovaním osobných údajov')}
                                        </Anchor>.
                                    </>
                                }

                                {...registerForm.getInputProps('terms', { type: 'checkbox' })}
                            />
                            {authError.length > 0 &&
                                <Text fz="sm" color="red" align="center">{authError}</Text>
                            }
                            {showSuccessfulRegistration &&
                                <Text fz="sm" color="green" align="center">{t('Registrácia prebehla úspešne! Teraz sa možete prihlásiť.')}</Text>
                            }
                        </Stack>
                    )}

                    <Group position="apart" mt="xl">
                        <Anchor
                            component="button"
                            type="button"
                            color="dimmed"
                            onClick={() => { setAuthError(''); toggleAuthModalType(); }}
                            size="xs"
                        >
                            {authModalType === 'register'
                                ? t('Už máte účet? Prihláste sa')
                                : t('Nemáte účet? Zaregistrujte sa')}
                        </Anchor>
                        <Button type="submit" radius="xl" leftIcon={authLoading ? <Loader size="1rem" color="white" /> : undefined} disabled={authLoading}>
                            {authModalType == 'login' ? t('Prihlásiť') : t('Registrovať')}
                        </Button>
                    </Group>
                </Box>
            </Modal>
            <Container pb={isSomehowSmall ? rem(100) : rem(80)}>
                <div className={classes.inner}>
                    <div className={classes.content}>

                        <MediaQuery largerThan="xs" styles={{ display: 'none' }}>
                            <Group spacing="xs" noWrap={true} mb="sm">
                                <BrandIcon size={28} />
                                <Title order={1} size="h1">FaktúryPlus<sup>+</sup></Title>
                            </Group>
                        </MediaQuery>
                        <MediaQuery smallerThan="xs" styles={{ display: 'none' }}>
                            <Title className={classes.title}>
                                {/*Nástroj pre jednoduchú fakturáciu*/}
                                {t('Jednoduchá a pohodlná fakturácia')}
                                {/*A <span className={classes.highlight}>modern</span> React <br /> components library*/}
                            </Title>
                        </MediaQuery>


                        {/*<Title className={classes.title}>*/}
                        {/*    /!*Nástroj pre jednoduchú fakturáciu*!/*/}
                        {/*    Jednoduchý fakturačný nástroj*/}
                        {/*    /!*A <span className={classes.highlight}>modern</span> React <br /> components library*!/*/}
                        {/*</Title>*/}
                        <Text color="dimmed" mt="md">
                            {t('Vytvárajte faktúry odkiaľkoľvek s webovou aplikáciou FaktúryPlus.')}
                        </Text>

                        <List
                            mt={30}
                            spacing="sm"
                            size="sm"
                            icon={
                                <ThemeIcon size={20} radius="xl">
                                    <IconCheck size={rem(12)} stroke={1.5} />
                                </ThemeIcon>
                            }
                        >
                            <List.Item>
                                <b>PAY by square</b> – {t('QR kód vo faktúre pre jednoduchú platbu')}
                            </List.Item>
                            <List.Item>
                                <b>{t('Jednoduché ovládanie')}</b> – {t('vďaka čistému a intuitívnemu dizajnu je používanie aplikácie veľmi jednoduché')}
                            </List.Item>
                            {/*<List.Item>*/}
                            {/*    <b>Bez inštalácie</b> – pre vytváranie faktúr nepotrebujete nič inštalovať*/}
                            {/*</List.Item>*/}
                            <List.Item>
                                <b>{t('Žiadne otravné upozornenia')}</b> – {t('už sa nemusíte pozastavovať nad upozornením na nevyplatené faktúry, jednoducho si zaznačte ktoré faktúry už boli vyplatené')}
                            </List.Item>
                        </List>

                        <Group mt={30}>
                            {isAuthenticated() ? (
                                <Button radius="xl" size="md" className={classes.control} component="a" href="/dashboard">
                                    {t('Prejsť na faktúry')}
                                </Button>
                            ) : (
                                <>
                                    <Button size="md" className={classes.control} onClick={openRegister}>
                                        {t('Vytvoriť účet')}
                                    </Button>
                                    <Button variant="default" size="md" className={classes.control} onClick={openLogin}>
                                        {t('Prihlásiť sa')}
                                    </Button>
                                </>
                            )}
                        </Group>
                        {/* {!isAuthenticated() && (
                            <Box mt="sm">
                                <Anchor href="asd" color="black">Vytvoriť faktúru bez prihlásenia</Anchor>
                            </Box>
                        )} */}
                    </div>
                    <Flex align="center">
                        <Image src={images[imageIndex]} className={classes.image} />
                    </Flex>
                </div>
                <MediaQuery largerThan="md" styles={{ display: 'none' }}>
                    <Flex justify="center">
                        <Image src={images[imageIndex]} maw={rem(400)} />
                    </Flex>
                </MediaQuery>
            </Container>
            {/*<div className={classes.footer}>*/}
            {/*    <Container>*/}
            {/*        <Flex justify="space-between" align="center" direction="row" gap={isSomehowSmall ? 6 : 0}>*/}
            {/*            <Flex justify={isSomehowSmall ? 'center' : 'start'} align={isSomehowSmall ? 'start' : 'center'} gap={isSomehowSmall ? 0 : 12} direction={isSomehowSmall ? 'column' : 'row'}>*/}
            {/*                <Anchor color="gray.5" href="https://mantine.dev/" target="_blank">*/}
            {/*                    Pravidlá používania*/}
            {/*                </Anchor>*/}
            {/*                {!isSomehowSmall && (*/}
            {/*                    <Divider orientation="vertical" />*/}
            {/*                )}*/}
            {/*                /!*<MediaQuery smallerThan="xs" styles={{ display: 'none' }}>*!/*/}
            {/*                /!*</MediaQuery>*!/*/}
            {/*                <Anchor color="gray.5" href="https://mantine.dev/" target="_blank">*/}
            {/*                    Ochrana osobných údajov*/}
            {/*                </Anchor>*/}
            {/*            </Flex>*/}
            {/*            <LanguagePicker width="auto" hideLabel compact />*/}
            {/*        </Flex>*/}
            {/*    </Container>*/}
            {/*</div>*/}
        </AppShell>
    );
}

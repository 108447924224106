import React, {forwardRef} from 'react';
import { Select, Text } from '@mantine/core';
import {useTranslation} from "react-i18next";

export interface BankAccountSelectItemType {
    value: string;
    label: string;
    iban: string;
}

const SelectItem = forwardRef<HTMLDivElement, BankAccountSelectItemType>(
    ({ label, iban, ...others }: BankAccountSelectItemType, ref) => (
        <div ref={ref} {...others}>
            <Text size="sm">{label}</Text>
            <Text size="sm" opacity={0.65}>{iban}</Text>
        </div>
    )
);

interface BankAccountSelectProps {
    bankAccounts: BankAccountSelectItemType[],
    openCreationModal: (query: string) => void,
    disabled?: boolean;
    onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    selectProps: { value: any, onChange: any, checked?: any, error?: any, onFocus?: any, onBlur?: any }
}

export function BankAccountSelect({ bankAccounts, openCreationModal, disabled, onKeyDown, selectProps }: BankAccountSelectProps) {
    const { t } = useTranslation();

    return (
        <Select
            label={t('Bankový účet')}
            data={bankAccounts}
            searchable
            creatable
            getCreateLabel={(query) => `+ ${t('Vytvoriť')} ${query}`}
            itemComponent={SelectItem}
            filter={(value, item: BankAccountSelectItemType) =>
                item.label.toLowerCase().includes(value.toLowerCase().trim()) ||
                item.iban.toLowerCase().includes(value.toLowerCase().trim())
            }
            onCreate={(query) => {
                openCreationModal(query);
                return query;
            }}
            disabled={disabled}
            onKeyDown={onKeyDown}
            {...selectProps}
        />
    );
}

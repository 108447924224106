import {isNotEmpty, useForm, UseFormReturnType} from "@mantine/form";
import dayjs from "dayjs";
import {InvoiceDetailResponse, TaxType} from "../../api/faktury-plus";
import {randomId} from "@mantine/hooks";
import {TFunction} from "i18next";


export interface EditAddressFormProps {
    companyName?: string;
    street?: string;
    streetNumber?: string;
    zip?: string;
    city?: string;
    country?: string;
}

export interface EditIssuerFormProps {
    issuerName?: string;
    brn?: string;
    taxId?: string;
    taxType: TaxType;
    vatId?: string;
    registeredIn?: string;
    phone?: string;
    email?: string;
    website?: string;
    billingAddress: EditAddressFormProps;
}

export interface EditClientFormProps {
    contactPersonName?: string;
    brn?: string;
    taxId?: string;
    vatId?: string;
    phone?: string;
    email?: string;
    website?: string;
    billingAddress: EditAddressFormProps;
    usePostalAddress?: boolean;
    postalAddress: EditAddressFormProps;
}

export interface EditItemFormProps {
    title?: string;
    count?: number;
    unit?: string;
    unitPrice?: number;
    key: string;
}
export interface EditInvoiceFormProps {
    id: string;
    variableSymbol: string;
    intro: string;
    issuanceDate: Date | null;
    deliveryDate: Date | null;
    dueDate: Date | null;
    bankAccountId: string | null;
    client: EditClientFormProps;
    issuer: EditIssuerFormProps;
    items: EditItemFormProps[];
}

export function initForm(t: TFunction): UseFormReturnType<EditInvoiceFormProps> {
    return useForm<EditInvoiceFormProps>({
        initialValues: {
            id: 'new',
            variableSymbol: '',
            intro: '',
            issuanceDate: new Date(),
            deliveryDate: null,
            dueDate: dayjs().add(14, 'day').toDate(),
            bankAccountId: null,
            client: {
                billingAddress: { country: 'Slovenská republika' },
                postalAddress: { }
            },
            issuer: {
                taxType: TaxType.NoTaxes,
                billingAddress: { country: 'Slovenská republika' }
            },
            items: [
                {
                    key: randomId(),
                    title: '',
                    count: 0,
                    unit: '',
                    unitPrice: 0
                }
            ]
        },

        validate: {
            variableSymbol: isNotEmpty(t('Prosím vyplňte'))
        },

        // transformValues: (values) => ({
        //     variableSymbol: values.variableSymbol,
        //     intro: values.intro,
        //     email: values.email,
        //     test: `${values.variableSymbol} ${values.intro}`,
        //     issuanceDate: values.issuanceDate,
        //     deliveryDate: values.deliveryDate,
        //     dueDate: values.dueDate,
        //     items: values.items
        // }),
    });
}

export function mapInvoiceData(form: UseFormReturnType<EditInvoiceFormProps>, invoice?: InvoiceDetailResponse, isClone?: boolean, setAsInitial = false) {
    let newValues: EditInvoiceFormProps = {
        ...form.values,
        id: isClone ? 'new' : invoice?.id!,
        variableSymbol: invoice?.variableSymbol ?? '',
        intro: invoice?.intro ?? '',
        issuanceDate: invoice?.issuanceDate ? dayjs(invoice?.issuanceDate).toDate() : null,
        deliveryDate: invoice?.deliveryDate ? dayjs(invoice?.deliveryDate).toDate() : null,
        dueDate: invoice?.dueDate ? dayjs(invoice?.dueDate).toDate() : null,
        bankAccountId: invoice?.bankAccount?.id ?? null,
        client: {
            contactPersonName: invoice?.client?.contactPersonName ?? undefined,
            brn: invoice?.client?.brn ?? undefined,
            taxId: invoice?.client?.taxId ?? undefined,
            vatId: invoice?.client?.vatId ?? undefined,
            phone: invoice?.client?.phone ?? undefined,
            email: invoice?.client?.email ?? undefined,
            website: invoice?.client?.website ?? undefined,
            billingAddress: {
                companyName: invoice?.client?.billingAddress?.companyName ?? undefined,
                street: invoice?.client?.billingAddress?.street ?? undefined,
                streetNumber: invoice?.client?.billingAddress?.streetNumber ?? undefined,
                zip: invoice?.client?.billingAddress?.zip ?? undefined,
                city: invoice?.client?.billingAddress?.city ?? undefined,
                country: invoice?.client?.billingAddress?.country ?? undefined,
            },
            usePostalAddress: invoice?.client?.usePostalAddress ?? false,
            postalAddress: {
                companyName: invoice?.client?.postalAddress?.companyName ?? undefined,
                street: invoice?.client?.postalAddress?.street ?? undefined,
                streetNumber: invoice?.client?.postalAddress?.streetNumber ?? undefined,
                zip: invoice?.client?.postalAddress?.zip ?? undefined,
                city: invoice?.client?.postalAddress?.city ?? undefined,
                country: invoice?.client?.postalAddress?.country ?? undefined,
            }
        },
        issuer: {
            issuerName: invoice?.issuer?.issuerName ?? undefined,
            brn: invoice?.issuer?.brn ?? undefined,
            taxId: invoice?.issuer?.taxId ?? undefined,
            taxType: invoice?.issuer?.taxType ?? TaxType.NoTaxes,
            vatId: invoice?.issuer?.vatId ?? undefined,
            registeredIn: invoice?.issuer?.registeredIn ?? undefined,
            phone: invoice?.issuer?.phone ?? undefined,
            email: invoice?.issuer?.email ?? undefined,
            website: invoice?.issuer?.website ?? undefined,
            billingAddress: {
                companyName: invoice?.issuer?.billingAddress?.companyName ?? undefined,
                street: invoice?.issuer?.billingAddress?.street ?? undefined,
                streetNumber: invoice?.issuer?.billingAddress?.streetNumber ?? undefined,
                zip: invoice?.issuer?.billingAddress?.zip ?? undefined,
                city: invoice?.issuer?.billingAddress?.city ?? undefined,
                country: invoice?.issuer?.billingAddress?.country ?? undefined,
            }
        },
        // isSent: invoice?.isSent ?? false,
        // isPaidOff: invoice?.isPaidOff ?? false,
        // isLocked: invoice?.isLocked ?? false,
        items: (invoice?.items ?? []).map(item => {
            return {
                key: randomId(),
                title: item.title ?? '',
                count: item.count ?? 0,
                unit: item.unit ?? '',
                unitPrice: item.unitPrice ?? 0
            }
        })
    };

    form.setValues(newValues);
    if (setAsInitial) {
        form.resetDirty(newValues);
    }
}

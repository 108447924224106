import React from "react";
import {CredentialResponse, GoogleLogin, GoogleOAuthProvider} from "@react-oauth/google";
import {useFakturyPlusApi} from "../../api/FakturyPlusApiContext";
import {useTranslation} from "react-i18next";
import {TokenResponseResult} from "../../api/faktury-plus";

export interface GoogleLoginButtonProps {
    setLoading: (loading: boolean) => void;
    handleLoginResponse: (response: TokenResponseResult) => void;
    setError: (error: string) => void;
}

export function GoogleLoginButton({ setLoading, setError, handleLoginResponse }: GoogleLoginButtonProps) {
    const api = useFakturyPlusApi();
    const { t } = useTranslation();

    function googleLogin(credentialResponse: CredentialResponse) {
        if (credentialResponse.credential) {
            api().tokenApi().identityTokenGooglePost({ idToken: credentialResponse.credential })
                .then((response) => handleLoginResponse(response.data))
                .catch((error) => {
                    setError(t<string>('Nepodarilo sa prihlásiť.'));
                    console.log(error);
                })
                .finally(() => setLoading(false));
        }
    }

    function googleLoginFail() {
        setError(t<string>('Nepodarilo sa prihlásiť.'));
        console.log('googleLoginFail()');
    }

    return (
        <GoogleOAuthProvider clientId="699963447205-dceak937qmlh5g562sg3a1er5iehgub4.apps.googleusercontent.com">
            <GoogleLogin
                type="standard"
                shape="rectangular"
                theme="filled_blue"
                text="continue_with"
                size="large"
                logo_alignment="left"
                width="286px"
                click_listener={() => { setError(''); setLoading(true); }}
                onSuccess={googleLogin}
                onError={googleLoginFail}
                useOneTap
            />
        </GoogleOAuthProvider>
    );
}

import React from 'react';
import { createStyles, Navbar, Group,  getStylesRef, rem, NavbarProps, ScrollArea, MediaQuery, Burger, useMantineTheme, Title } from '@mantine/core';
import {
    IconGauge,
    IconFileDollar,
    TablerIconsProps, IconBuildingBank
} from '@tabler/icons-react';
import { BrandIcon } from '../icons/BrandIcon';
import { useTranslation } from 'react-i18next';
import {NavLink, useNavigate} from 'react-router-dom';
import {UserButton} from "./UserButton";
import {LanguagePicker} from "./LanguagePicker";

const useStyles = createStyles((theme) => ({
    header: {
        paddingBottom: theme.spacing.md,
        marginBottom: `calc(${theme.spacing.md} * 1.5)`,
        borderBottom: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]}`,
    },

    additionalLinks: {
        // padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
        padding: `${theme.spacing.xs} 0`,
    },

    footer: {
        // paddingTop: theme.spacing.md,
        // marginTop: theme.spacing.md,
        // borderTop: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]}`,
        marginLeft: `calc(${theme.spacing.md} * -1)`,
        marginRight: `calc(${theme.spacing.md} * -1)`,
        borderTop: `${rem(1)} solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
        }`,
    },

    link: {
        ...theme.fn.focusStyles(),
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        fontSize: theme.fontSizes.sm,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[7],
        padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
        borderRadius: theme.radius.sm,
        fontWeight: 500,

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
            color: theme.colorScheme === 'dark' ? theme.white : theme.black,

            [`& .${getStylesRef('icon')}`]: {
                color: theme.colorScheme === 'dark' ? theme.white : theme.black,
            },
        },
    },

    linkIcon: {
        ref: getStylesRef('icon'),
        color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[6],
        marginRight: theme.spacing.sm,
    },

    linkActive: {
        '&, &:hover': {
            backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
            color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
            [`& .${getStylesRef('icon')}`]: {
                color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
            },
        },
    },
}));

export interface NavbarSideProps extends Partial<NavbarProps> {
    opened: boolean;
    setOpened: (o: any) => void;
}

export function NavbarSide({ opened, setOpened, ...navbarProps }: NavbarSideProps) {
    const theme = useMantineTheme();
    const navigate = useNavigate();
    const { classes, cx } = useStyles();
    //const [active, setActive] = useState('Billing');

    const { t } = useTranslation();

    const pages: { icon: (props: TablerIconsProps) => JSX.Element, path: string; title: string, matchPatterns?: RegExp[] | undefined }[] = [
        { icon: IconGauge, path: '/dashboard', title: t('Prehľad') },
        { icon: IconFileDollar, path: '/invoices', title: t('Faktúry'), matchPatterns: [/^\/invoices$/, /^\/invoices\/[\w-]*$/, /^\/invoices\/new$/] },
        { icon: IconBuildingBank, path: '/bank-accounts', title: t('Bankové účty') },
        // { icon: IconUsers, path: '/clients', title: t('Odberatelia'), matchPatterns: [/^\/clients$/, /^\/clients\/[\w-]*$/] },
        // { icon: IconTorii, path: '/issuers', title: t('Dodávatelia') },
    ];
    //const pageIndexOffset: number = 0;

    //const [activePageIndex, setActivePageIndex] = useState(getActivePageIndex(global.location.pathname));

    //let location = useLocation();

    //useEffect(
    //    () => {
    //        setActivePageIndex(getActivePageIndex(location.pathname))
    //    },
    //    [location]
    //)

    //function getActivePageIndex(locationPathname: string): number {
    //    let pageIndex = pages.findIndex(page => page.path == locationPathname || (page.matchPatterns && page.matchPatterns.map(pattern => pattern.test(locationPathname)).includes(true)));
    //    return pageIndex + pageIndexOffset;
    //}

    //const links = data.map((item) => (
    //    <a
    //        className={cx(classes.link, { [classes.linkActive]: item.label === active })}
    //        href={item.link}
    //        key={item.label}
    //        onClick={(event) => {
    //            event.preventDefault();
    //            setActive(item.label);
    //        }}
    //    >
    //        <item.icon className={classes.linkIcon} stroke={1.5} />
    //        <span>{item.label}</span>
    //    </a>
    //));

    const links = pages.map((item) => (
        //<a
        //    className={classes.link}
        //    href={item.path}
        //    key={item.title}
        //>
        //    <item.icon className={classes.linkIcon} stroke={1.5} />
        //    <span>{item.title}</span>
        //</a>
        <NavLink
            to={item.path}
            onClick={() => setOpened(false)}
            key={item.title}
            className={({ isActive, isPending }) =>
                cx(classes.link, { [classes.linkActive]: isActive })
            }
        >
            <item.icon className={classes.linkIcon} stroke={1.5} />
            <span>{item.title}</span>
        </NavLink>
    ));

    return (
        <Navbar p="md" pb="0" hiddenBreakpoint="sm" width={{ sm: 220, lg: 300 }} hidden={!opened} {...navbarProps}>
            <Navbar.Section>
                <Group className={classes.header} spacing="xs" noWrap={true} onClick={() => navigate('/dashboard')} sx={{ cursor: 'pointer' }}>
                    <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
                        <Burger
                            opened={opened}
                            onClick={() => setOpened((o: any) => !o)}
                            size="sm"
                            color={theme.colors.gray[6]}
                        />
                    </MediaQuery>
                    <BrandIcon size={28} />
                    <Title order={2} size="h3">FaktúryPlus<sup>+</sup></Title>
                    {/*<Code sx={{ fontWeight: 700 }}>v3.1.2</Code>*/}
                </Group>
            </Navbar.Section>

            <Navbar.Section grow component={ScrollArea}>
                {links}
            </Navbar.Section>
            <Navbar.Section className={classes.additionalLinks}>
                <LanguagePicker />
            </Navbar.Section>

            <Navbar.Section className={classes.footer}>
                {/*<a href="#" className={classes.link} onClick={(event) => event.preventDefault()}>*/}
                {/*    <IconSwitchHorizontal className={classes.linkIcon} stroke={1.5} />*/}
                {/*    <span>Change account</span>*/}
                {/*</a>*/}

                {/*<a href="#" className={classes.link} onClick={(event) => event.preventDefault()}>*/}
                {/*    <IconLogout className={classes.linkIcon} stroke={1.5} />*/}
                {/*    <span>Logout</span>*/}
                {/*</a>*/}
                <UserButton />
            </Navbar.Section>
        </Navbar>
    );
}

import React from 'react';
import {
    createStyles,
    Header,
    Group,
    Container,
    rem,
    Button,
    MediaQuery,
    Title, Menu, ActionIcon, Text, Avatar
} from '@mantine/core';
import { BrandIcon } from '../../icons/BrandIcon';
import {useTranslation} from "react-i18next";
import {useAuthUser, useIsAuthenticated, useSignOut} from "react-auth-kit";
import { IconLogout } from "@tabler/icons-react";
import {UserAuthState} from "../../auth/UserAuthState";
import {useNavigate} from "react-router-dom";

const useStyles = createStyles((theme) => ({
    inner: {
        height: rem(56),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    links: {
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    link: {
        display: 'block',
        lineHeight: 1,
        padding: `${rem(8)} ${rem(12)}`,
        borderRadius: theme.radius.sm,
        textDecoration: 'none',
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
        fontSize: theme.fontSizes.sm,
        fontWeight: 500,

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        },
    },

    linkLabel: {
        marginRight: rem(5),
    },
}));

interface HeaderMenuProps {
    openLogin: () => void;
    openRegister: () => void;
}

export function HeaderMenu({ openLogin, openRegister }: HeaderMenuProps) {
    const { classes } = useStyles();
    const { t } = useTranslation();
    const isAuthenticated = useIsAuthenticated();
    const authUser = useAuthUser();
    const signOut = useSignOut();
    const navigate = useNavigate();

    const userData: UserAuthState = { ...authUser() };

    return (
        <Header height={56} mb={120}>
            <Container>
                <div className={classes.inner}>
                    <Group spacing="xs" noWrap={true} onClick={() => navigate('/')} sx={{ cursor: 'pointer' }}>
                        <BrandIcon size={28} />
                        <MediaQuery smallerThan="xs" styles={{ display: 'none' }}>
                            <Title order={2} size="h3">FaktúryPlus<sup>+</sup></Title>
                        </MediaQuery>
                    </Group>
                    {/*<Group spacing={5} className={classes.links}>*/}
                    {/*    {items}*/}
                    {/*</Group>*/}
                    {isAuthenticated() ? (
                        <Group>
                            <Button component="a" href="/dashboard">{t('Prejsť na faktúry')}</Button>
                            <Menu
                                transitionProps={{ transition: 'pop' }}
                                withArrow
                                position="bottom-end"
                                withinPortal
                            >
                                <Menu.Target>
                                    <ActionIcon variant="transparent">
                                        <Avatar src={userData.imageUrl} radius="xl" />
                                    </ActionIcon>
                                </Menu.Target>
                                <Menu.Dropdown>
                                    <Menu.Item icon={<IconLogout size={14} />} onClick={() => { signOut(); navigate('/'); }}>{t('Odhlásiť')}</Menu.Item>
                                </Menu.Dropdown>
                            </Menu>
                        </Group>
                    ) : (
                        <Group>
                            {/*<Button variant="default">Log in</Button>*/}
                            {/*<Button>Sign up</Button>*/}
                            {/*<MediaQuery smallerThan="xs" styles={{ display: 'none' }}>*/}
                            <Button variant="default" onClick={openLogin}>{t('Prihlásiť sa')}</Button>
                            {/*</MediaQuery>*/}
                            <Button onClick={openRegister}>{t('Vytvoriť účet')}</Button>
                        </Group>
                    )}
                </div>
            </Container>
        </Header>
    );
}

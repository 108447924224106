import React, { useState } from "react";
import {AppShell, Burger, createStyles, Group, Header, Text, Title, useMantineTheme} from '@mantine/core';
import { NavbarSide } from "./NavbarSide";
import {Outlet, useNavigate} from "react-router-dom";
import {Notifications} from "@mantine/notifications";
import {BrandIcon} from "../icons/BrandIcon";

const useStyles = createStyles((theme) => ({
    appShell: {
        [theme.fn.largerThan("sm")]: {
            paddingTop: theme.spacing.md
        }
    },
    header: {
        [theme.fn.largerThan("sm")]: {
            display: "none"
        }
    }
}));

export interface LayoutProps {
}

export function Layout({ ...others }: LayoutProps) {
    const theme = useMantineTheme();
    const navigate = useNavigate();
    const { classes } = useStyles();

    const [opened, setOpened] = useState(false);

    //const matches = useMediaQuery(`(min-width: ${theme.breakpoints.sm}px)`);

    return (
        <AppShell
            classNames={{ main: classes.appShell }}
            styles={{
                main: {
                    background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
                    //[`@media (min-width: ${theme.breakpoints.sm}px)`]: {
                    //    background: 'yellow',
                    //},
                    //backgroundColor: matches ? 'teal' : 'red',
                    //paddingTop: matches ? '1rem' : 'inherit'
                },
            }}
            layout="alt"
            navbarOffsetBreakpoint="sm"
            asideOffsetBreakpoint="sm"
            navbar={
                <NavbarSide opened={opened} setOpened={setOpened} />
            }
            // footer={
            //     <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
            //         <Footer height={60} p="md">
            //             Application footer
            //         </Footer>
            //     </MediaQuery>
            // }
            header={
                <Header className={classes.header} height={64} p="md">
                    <Group spacing="xs" noWrap={true}>
                        <Burger
                            opened={opened}
                            onClick={() => setOpened((o) => !o)}
                            size="sm"
                            color={theme.colors.gray[6]}
                        />

                        <BrandIcon size={28} />
                        <Title order={2} size="h3" onClick={() => navigate('/dashboard')} sx={{ cursor: 'pointer' }}>FaktúryPlus<sup>+</sup></Title>
                    </Group>
                    {/*<div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>*/}
                    {/*    <Burger*/}
                    {/*        opened={opened}*/}
                    {/*        onClick={() => setOpened((o) => !o)}*/}
                    {/*        size="sm"*/}
                    {/*        color={theme.colors.gray[6]}*/}
                    {/*        mr="xl"*/}
                    {/*        pr="xs"*/}
                    {/*    />*/}

                    {/*    <BrandIcon size={28} />*/}
                    {/*    <Title order={2} size="h3" pl="xs" sx={{ cursor: 'default' }}>FaktúryPlus<sup>+</sup></Title>*/}
                    {/*</div>*/}
                </Header>
            }
        >
            <Notifications position="top-right" />
            <Outlet />
        </AppShell>
    );
}

export interface BicMapping {
    SK: {
        [bankCode: string]: string
    },
    CZ: {
        [bankCode: string]: string
    },
}

const bicMapping: BicMapping = {
    SK: {
        "0200": "SUBASKBX",
        "0720": "NBSBSKBX",
        "0900": "GIBASKBX",
        "1100": "TATRSKBX",
        "1111": "UNCRSKBX",
        "1119": "UNCRSKBX",
        "1120": "UNCRSKBX",
        "1121": "UNCRSKBX",
        "1130": "TATRSKBX",
        "1131": "TATRSKBX",
        "1140": "TATRSKBX",
        "1150": "TATRSKBX",
        "1160": "TATRSKBX",
        "1170": "TATRSKBX",
        "1180": "TATRSKBX",
        "1190": "TATRSKBX",
        "1200": "UNCRSKBX",
        "1300": "GIBASKBX",
        "1400": "PRVASKBA",
        "1500": "BREXSKBX",
        "1600": "AGBASKBX",
        "1700": "UNCRSKBX",
        "1800": "TATRSKBX",
        "1900": "SLOVSKBX",
        "2000": "POBNSKBA",
        "3000": "SLZBSKBA",
        "3100": "LUBASKBX",
        "5200": "OTPBSKBX",
        // "5600": "CITISKBA",
        "5600": "KOMASK2X",
        "5900": "PRVASKBA",
        // "6500": "PMBPSKBX",
        "6500": "POBNSKBA",
        // "7300": "CEKOSKBX",
        "7300": "INGBSKBX",
        // "7500": "SLOVSKBX",
        "7500": "CEKOSKBX",
        "7910": "POISSKBX",
        "7930": "WUSTSKBA",
        "7950": "SPSRSKBAXXX",
        "8100": "KOMBSKBA",
        "8120": "BSLOSK22",
        "8130": "CITISKBA",
        "8170": "KBSPSKBX",
        "8160": "EXSKSKBX",
        "8180": "SPSRSKBA",
        "8320": "JTBPSKBA",
        "8330": "FIOZSKBA",
        "8360": "BREXSKBX",
        "8370": "OBKLSKBA",
        "8420": "BFKKSKBB",
        "8430": "KODBSKBX",
        "8450": "BPKOSKBB",
        "9952": "TPAYSKBX",
        "9954": "KPAYSK22",
        "9955": "PANXSK22",
    },
    CZ: {
        "0100": "KOMBCZPP",
        "0300": "CEKOCZPP",
        "0600": "AGBACZPP",
        "0710": "CNBACZPP",
        "0800": "GIBACZPX",
        "2010": "FIOBCZPP",
        "2060": "CITFCZPP",
        "2070": "MPUBCZPP",
        "2220": "ARTTCZPP",
        "2250": "CTASCZ22",
        "2600": "CITICZPX",
        "2700": "BACXCZPP",
        "3030": "AIRACZPP",
        "3050": "BPPFCZP1",
        "3060": "BPKOCZPP",
        "3500": "INGBCZPP",
        "4000": "EXPNCZPP",
        "4300": "NROZCZPP",
        "5500": "RZBCCZPP",
        "5800": "JTBPCZPP",
        "6000": "PMBPCZPP",
        "6200": "COBACZPX",
        "6210": "BREXCZPP",
        "6300": "GEBACZPP",
        "6700": "SUBACZPP",
        "6800": "VBOECZ2X",
        "7910": "DEUTCZPX",
        "8030": "GENOCZ21",
        "8040": "OBKLCZ2X",
        "8090": "CZEECZPP",
        "8150": "MIDLCZPP",
        "8198": "FFCSCZP1",
        "8199": "MOUSCZP2",
        "8220": "PAERCZP1",
        "8250": "BKCHCZPP",
        "8255": "COMMCZPP",
        "8265": "ICBKCZPP",
        "8270": "FAPOCZP1",
        "8280": "BEFKCZP1",
        "8293": "MRPSCZPP",
        "8299": "BEORCZP2",
    }
};

function extractDetails(iban: string) {
    const countryCode = iban.substr(0, 2);
    const checkDigits = iban.substr(2, 2);
    let bankCode = "";
    let accountNumber = "";

    if (countryCode === "SK" || countryCode === "CZ") { //  && checkDigits === "31"
        bankCode = iban.substr(4, 4);
        accountNumber = iban.substr(8);
    }

    return {
        countryCode,
        bankCode,
        accountNumber
    };
}

function mapBankCodeToBic(bankCode: string, countryCode: keyof BicMapping) {
    return bicMapping[countryCode][bankCode] || "";
}

export function ibanToBic(iban: string) {
    const { countryCode, bankCode } = extractDetails(iban);

    if (countryCode in bicMapping) {
        return mapBankCodeToBic(bankCode, countryCode as keyof BicMapping);
    }

    return '';
}

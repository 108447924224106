import {isNotEmpty, useForm, UseFormReturnType} from "@mantine/form";
import {TFunction} from "i18next";
import React, {useEffect, useState} from "react";
import {Button, Flex, Grid, Loader, Modal, TextInput} from "@mantine/core";
import {IconDeviceFloppy} from "@tabler/icons-react";
import {useTranslation} from "react-i18next";
import {useFakturyPlusApi} from "../../api/FakturyPlusApiContext";
import {showErrorNotification} from "../../helpers/notifications";
import {useNavigate} from "react-router-dom";

export interface EditBankAccountFormProps {
    title: string;
    iban: string;
    swift: string;
}

export function initForm(t: TFunction): UseFormReturnType<EditBankAccountFormProps> {
    return useForm<EditBankAccountFormProps>({
        initialValues: {
            title: '',
            iban: '',
            swift: ''
        },
        validate: {
            title: isNotEmpty(t('Prosím vyplňte')),
        }
    });
}

export interface EditBankAccountModalProps {
    bankAccountId: string;
    opened: boolean;
    close: (reloadAccounts: boolean) => void;
    form: UseFormReturnType<EditBankAccountFormProps>;
}

export function EditBankAccountModal({ bankAccountId, opened, close, form }: EditBankAccountModalProps) {
    const api = useFakturyPlusApi();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        setLoading(true);

        api().bankAccountsApi().v1BankaccountsIdGet(bankAccountId)
            .then((response) => {
                if (response.data.data) {
                    let newValues: EditBankAccountFormProps = {
                        title: response.data.data.title ?? '',
                        iban: response.data.data.iban ?? '',
                        swift: response.data.data.swift ?? ''
                    };
                    form.setValues(newValues);
                    form.resetDirty(newValues);
                }
            })
            .catch((error) => {
                if ([403, 404].includes(error.response.status)) {
                    navigate('/bank-accounts');
                }

                showErrorNotification(t('Nastala neočakávaná chyba'), t('Nepodarilo sa načítať bankový účet.'), 'edit-bank-account-modal', () => navigate('/bank-accounts'));
            })
            .finally(() => {
                setLoading(false);
            });

    }, [bankAccountId])

    const saveBankAccount = (values: EditBankAccountFormProps) => {
        setSaving(true);

        api().bankAccountsApi().v1BankaccountsIdPut(bankAccountId, values).then((response) => {
            close(true);
        })
        .catch((error) => {
            if (error.response.data.message) {
                showErrorNotification(t('Nepodarilo sa uložiť bankový účet.'), error.response.data.message);
            } else {
                showErrorNotification('', t('Nepodarilo sa uložiť bankový účet.'));
            }
        })
        .finally(() => {
            setSaving(false);
        });
    }

    return (
        <Modal opened={opened} onClose={() => close(false)} title={t('Bankový účet')}>
            <form onSubmit={form.onSubmit(saveBankAccount)}>
                <Grid gutter="xs">
                    <Grid.Col md={12}>
                        <TextInput
                            withAsterisk
                            label={t('Názov účtu')}
                            description={t('Nezobrazuje sa vo faktúre')}
                            {...form.getInputProps('title')} />
                    </Grid.Col>
                    <Grid.Col md={12}>
                        <TextInput
                            label={t('IBAN')}
                            disabled
                            {...form.getInputProps('iban')} />
                    </Grid.Col>
                    <Grid.Col md={12}>
                        <TextInput
                            label={t('BIC/SWIFT')}
                            disabled
                            {...form.getInputProps('swift')} />
                    </Grid.Col>
                    <Grid.Col md={12}>
                        <Flex justify="end">
                            <Button
                                type="submit"
                                color="green.8"
                                leftIcon={(loading || saving) ? <Loader size="1rem" color="white" /> : <IconDeviceFloppy size="1rem" />}
                                disabled={loading || saving || !form.isDirty()}>
                                {t('Uložiť')}
                            </Button>
                        </Flex>
                    </Grid.Col>
                </Grid>
            </form>
        </Modal>
    );
}

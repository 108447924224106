import React, {MouseEvent} from 'react';
import {ActionIcon, Badge, createStyles, Group, MantineTheme, Menu, rem, Text, UseStylesOptions} from "@mantine/core";
import {formatDate, formatNumber} from "../../helpers/format";
import {downloadInvoicePdf} from "../../helpers/invoiceDownloads";
import {showErrorNotification, showSuccessNotification} from "../../helpers/notifications";
import {IconCopy, IconCreditCard,
    IconCreditCardOff, IconDots, IconDownload, IconTrash} from "@tabler/icons-react";
import {modals} from "@mantine/modals";
import {DataTableColumn} from "mantine-datatable/dist/types/DataTableColumn";
import {InvoiceDetailResponse, SettingProperty} from "../../api/faktury-plus";
import {TFunction} from "i18next";
import FakturyPlusApi from "../../api/FakturyPlusApi";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import {DataTableCellClickHandler} from "mantine-datatable/dist/types/DataTableCellClickHandler";

export function createInvoiceTableStyles(): (params: void, options?: UseStylesOptions<string>) => {
    // classes: {
    //     [key in keyof {
    //         header: { "&& th": { fontSize: string; fontWeight: number }; textTransform: string }
    //     }]: string
    // };
    // cx: (...args: any) => string;
    // theme: MantineTheme
    classes: { [key in keyof any]: string; };
    cx: (...args: any) => string;
    theme: MantineTheme;
} {
    return createStyles((theme) => ({
        header: {
            textTransform: 'uppercase',
            '&& th': {
                fontWeight: 500,
                fontSize: '0.75rem'
            }
        }
    }));
}

interface ReturnPropsType {
    columns: DataTableColumn<InvoiceDetailResponse>[];
    onCellClick: DataTableCellClickHandler<InvoiceDetailResponse>;
    onRowClick: (record: InvoiceDetailResponse, recordIndex: number, event: MouseEvent) => void;
    noRecordsText: string;
}

export function getTableProps(t: TFunction, api: () => FakturyPlusApi, navigate: NavigateFunction, reloadData: () => void): ReturnPropsType {
    return {
        columns: [
            {
                accessor: 'amount',
                title: t('Suma'),
                textAlignment: 'right',
                render: ({ totalPrice }) => <>
                    <Text span fw={500}>{formatNumber(totalPrice)}</Text>&nbsp;<Text span>{'\u20AC'}</Text>
                </>,
            },
            {
                accessor: 'status',
                title: t('Stav'),
                textAlignment: 'center',
                render: ({ id, isPaidOff, isOverdue }) => {
                    let status = { title: t('Vystavená'), color: 'gray' };
                    if (isPaidOff) {
                        status = { title: t('Zaplatená'), color: 'green' };
                    } else if (isOverdue) {
                        status = { title: t('Po splatnosti'), color: 'red' };
                    }

                    return (
                        <Menu
                            transitionProps={{ transition: 'pop' }}
                            withArrow
                            position="bottom"
                            withinPortal
                        >
                            <Menu.Target>
                                <Badge color={status.color} variant="light" sx={{ '&:hover': { filter: 'brightness(98%)' } }} onClick={(e) => {
                                    e.stopPropagation();
                                }}>{status.title}</Badge>
                            </Menu.Target>
                            <Menu.Dropdown>
                                <Menu.Item icon={isPaidOff ? <IconCreditCardOff size="1rem" stroke={1} /> : <IconCreditCard size="1rem" stroke={1} />} onClick={(e) => {
                                    e.stopPropagation();

                                    api().invoiceSettingsApi().v1InvoicesettingsIdPost(id!, {
                                        settingProperty: SettingProperty.IsPaidOff,
                                        settingValue: !isPaidOff
                                    }).then((response) => {
                                        reloadData();
                                    })
                                    .catch((error) => {
                                        if (error.response.data.message) {
                                            showErrorNotification(t('Nepodarilo sa zmeniť stav faktúry'), error.response.data.message);
                                        } else {
                                            showErrorNotification('', t('Nepodarilo sa zmeniť stav faktúry'));
                                        }
                                    });
                                }}>{t(isPaidOff ? 'Označiť ako nezaplatenú' : 'Označiť ako zaplatenú')}</Menu.Item>
                            </Menu.Dropdown>
                        </Menu>
                    );
                }
            },
            {
                accessor: 'variableSymbol',
                title: t('Faktúra č.')
            },
            {
                accessor: 'client.billingAddress.companyName',
                title: t('Odberateľ')
            },
            {
                accessor: 'issuanceDate',
                title: t('Vystavená'),
                render: ({ issuanceDate }) => formatDate(new Date(issuanceDate + 'Z'))
            },
            {
                accessor: 'actions',
                title: t('Možnosti'),
                textAlignment: 'right',
                width: rem(100),
                render: ({ id, variableSymbol }, index) => (
                    <Group spacing={4} position="right" noWrap>
                        <ActionIcon
                            variant="transparent"
                            onClick={(e) => {
                                e.stopPropagation();

                                downloadInvoicePdf(api(), id!, t('Faktúra {{invoiceNo}}', { invoiceNo: variableSymbol }), window, (error) => {
                                    if (error.response.data.message) {
                                        showErrorNotification(t('Nepodarilo sa vytvoriť PDF.'), error.response.data.message);
                                    } else {
                                        showErrorNotification('', t('Nepodarilo sa vytvoriť PDF.'));
                                    }
                                });
                            }}
                        >
                            <IconDownload size={16} />
                        </ActionIcon>
                        <Menu
                            transitionProps={{ transition: 'pop' }}
                            withArrow
                            position="bottom-end"
                            withinPortal
                        >
                            <Menu.Target>
                                <ActionIcon
                                    variant="transparent"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}>
                                    <IconDots size="1rem" stroke={1.5} />
                                </ActionIcon>
                            </Menu.Target>
                            <Menu.Dropdown>
                                <Menu.Item icon={<IconCopy size="1rem" stroke={1.5} />} onClick={(e) => {
                                    e.stopPropagation();
                                    navigate('/invoices/clone/' + id);
                                }}>{t('Vytvoriť kópiu')}</Menu.Item>
                                <Menu.Item icon={<IconTrash size="1rem" stroke={1.5} />} color="red" onClick={(e) => {
                                    e.stopPropagation();

                                    modals.openConfirmModal({
                                        title: `${t('Odstrániť faktúru')} ${variableSymbol}`,
                                        centered: true,
                                        children: (
                                            <Text size="sm">
                                                {t('Naozaj chcete odstrániť túto faktúru? Po odstránení bude natrvalo vymazaná.')}
                                            </Text>
                                        ),
                                        labels: { confirm: t('Odstrániť'), cancel: t('Ponechať') },
                                        confirmProps: { color: 'red' },
                                        onConfirm: () => api().invoicesApi().v1InvoicesIdDelete(id!).then(() => {
                                            showSuccessNotification('', t('Faktúra bola úspešne odstránená!'));
                                            reloadData();
                                        }).catch((error) => {
                                            if (error.response.data.message) {
                                                showErrorNotification(t('Nepodarilo sa odstrániť faktúru.'), error.response.data.message);
                                            } else {
                                                showErrorNotification('', t('Nepodarilo sa odstrániť faktúru.'));
                                            }
                                        }),
                                    });
                                }}>{t('Odstrániť')}</Menu.Item>
                            </Menu.Dropdown>
                        </Menu>
                    </Group>
                ),
            },
        ],
        onCellClick: ({ event: e, columnIndex }) => {
            if (columnIndex == 5) {
                e.stopPropagation();
            }
        },
        onRowClick: ({ id }) => {
            navigate('/invoices/' + id);
        },
        noRecordsText: t<string>('Zatiaľ ste nevytvorili žiadnu faktúru'),
        // emptyState={
        //     <Stack align="center" spacing="xs" sx={{ pointerEvents: 'all' }}>
        //         <Text color="dimmed" size="sm">
        //             {t('Zatiaľ ste nevytvorili žiadnu faktúru')}.
        //         </Text>
        //         <Button color="teal" component="a" href="/invoices/new" leftIcon={<IconPlus />}>{t('Vytvoriť novú')}</Button>
        //     </Stack>
        // }
    }
}

import {isNotEmpty, useForm, UseFormReturnType} from "@mantine/form";
import {TFunction} from "i18next";
import React, {useEffect, useState} from "react";
import {Button, Flex, Grid, Loader, Modal, TextInput} from "@mantine/core";
import {IconDeviceFloppy} from "@tabler/icons-react";
import {useTranslation} from "react-i18next";
import {ibanToBic} from "../../helpers/bicEctractor";
import {useFakturyPlusApi} from "../../api/FakturyPlusApiContext";
import {showErrorNotification} from "../../helpers/notifications";

export interface CreateBankAccountFormProps {
    title: string;
    iban: string;
    swift: string;
}

export function initForm(t: TFunction): UseFormReturnType<CreateBankAccountFormProps> {
    return useForm<CreateBankAccountFormProps>({
        initialValues: {
            title: '',
            iban: '',
            swift: ''
        },
        validate: {
            title: isNotEmpty(t('Prosím vyplňte')),
            iban: isNotEmpty(t('Prosím vyplňte')),
            swift: isNotEmpty(t('Prosím vyplňte')),
        }
    });
}

export interface CreateBankAccountModalProps {
    opened: boolean;
    close: (reloadAccounts: boolean, selectedAccountId?: string) => void;
    form: UseFormReturnType<CreateBankAccountFormProps>;
}

export function CreateBankAccountModal({ opened, close, form }: CreateBankAccountModalProps) {
    const api = useFakturyPlusApi();
    const { t } = useTranslation();

    const [saving, setSaving] = useState(false);

    const saveBankAccount = (values: CreateBankAccountFormProps) => {
        setSaving(true);

        api().bankAccountsApi().v1BankaccountsPost(values).then((response) => {
            close(true, response.data.data?.id!);
        })
        .catch((error) => {
            if (error.response.data.message) {
                showErrorNotification(t('Nepodarilo sa vytvoriť bankový účet.'), error.response.data.message);
            } else {
                showErrorNotification('', t('Nepodarilo sa vytvoriť bankový účet.'));
            }
        })
        .finally(() => {
            setSaving(false);
        });
    }

    useEffect(() => {
        form.setFieldValue('swift', ibanToBic(form.values.iban));
    }, [form.values.iban])

    return (
        <Modal opened={opened} onClose={() => close(false)} title={t('Bankový účet')}>
            <form onSubmit={form.onSubmit(saveBankAccount)}>
                <Grid gutter="xs">
                    <Grid.Col md={12}>
                        <TextInput
                            withAsterisk
                            label={t('Názov účtu')}
                            description={t('Nezobrazuje sa vo faktúre')}
                            {...form.getInputProps('title')} />
                    </Grid.Col>
                    <Grid.Col md={12}>
                        <TextInput
                            withAsterisk
                            label={t('IBAN')}
                            {...form.getInputProps('iban')} />
                    </Grid.Col>
                    <Grid.Col md={12}>
                        <TextInput
                            withAsterisk
                            label={t('BIC/SWIFT')}
                            {...form.getInputProps('swift')} />
                    </Grid.Col>
                    <Grid.Col md={12}>
                        <Flex justify="end">
                            <Button
                                type="submit"
                                color="green.8"
                                leftIcon={saving ? <Loader size="1rem" color="white" /> : <IconDeviceFloppy size="1rem" />}
                                disabled={saving}>
                                {t('Vytvoriť')}
                            </Button>
                        </Flex>
                    </Grid.Col>
                </Grid>
            </form>
        </Modal>
    );
}

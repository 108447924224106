import React, {useEffect, useState} from 'react';
import {ActionIcon, Button, Container, createStyles, Flex, Group, Menu, Paper, rem, Text, Title, Tooltip} from "@mantine/core";
import {IconEdit, IconPlus, IconTrash} from "@tabler/icons-react";
import {DataTable} from "mantine-datatable";
import {useTranslation} from "react-i18next";
import {useFakturyPlusApi} from "../../api/FakturyPlusApiContext";
import {BankAccountDetailResponse, } from "../../api/faktury-plus";
import {showErrorNotification, showSuccessNotification} from "../../helpers/notifications";
import {modals} from "@mantine/modals";
import {useDisclosure} from "@mantine/hooks";
import { CreateBankAccountModal, CreateBankAccountFormProps, initForm as initCreateBankAccountForm } from './CreateBankAccountModal';
import { EditBankAccountModal, initForm as initEditBankAccountForm} from "./EditBankAccountModal";

const useStyles = createStyles((theme) => ({
    header: {
        textTransform: 'uppercase',
        '&& th': {
            fontWeight: 500,
            fontSize: '0.75rem'
        }
    }
}));

export function BankAccounts() {
    const api = useFakturyPlusApi();
    const { t } = useTranslation();
    const { classes } = useStyles();

    const [loading, setLoading] = useState(true);

    const [records, setRecords] = useState<BankAccountDetailResponse[]>([]);

    const getData = () => {
        setLoading(true);

        api().bankAccountsApi().v1BankaccountsGet().then((response) => {
            if (response.data.data && response.data.data.length) {
                setRecords(response.data.data ?? []);
            } else {
                setRecords([])
            }
            setLoading(false);
        })
        .catch((error) => {
            setRecords([])
            setLoading(false);

            if (error.response.data.message) {
                showErrorNotification(t('Nepodarilo sa načítať bankové účty'), error.response.data.message, 'load-bank-accounts');
            } else {
                showErrorNotification('', t('Nepodarilo sa načítať bankové účty'), 'load-bank-accounts');
            }
        });
    }

    useEffect(() => {
        getData();
    }, []);

    const [createBankAccountModalOpened, { open: openCreateBankAccountModal, close: closeCreateBankAccountModal }] = useDisclosure(false);
    const createBankAccountForm = initCreateBankAccountForm(t);

    const openBankAccountCreationModal = () => {
        let values: CreateBankAccountFormProps = {
            title: '',
            iban: '',
            swift: ''
        }

        createBankAccountForm.setValues(values);
        // createBankAccountForm.resetDirty(values); // netreba

        openCreateBankAccountModal();
    }

    const closeBankAccountCreationModal = (reloadAccounts: boolean, selectedAccountId?: string) => {
        if (reloadAccounts) {
            getData();
        }
        closeCreateBankAccountModal();
    }

    const [editBankAccountModalOpened, { open: openEditBankAccountModal, close: closeEditBankAccountModal }] = useDisclosure(false);
    const editBankAccountForm = initEditBankAccountForm(t);
    const [editBankAccountModalId, setEditBankAccountModalId] = useState('');

    const openBankAccountEditModal = (id: string) => {
        setEditBankAccountModalId(id);
        openEditBankAccountModal();
    }

    const closeBankAccountEditModal = (reloadAccounts: boolean) => {
        if (reloadAccounts) {
            getData();
        }
        closeEditBankAccountModal();
    }

    console.log(records);
    return (
        <Container size="xl" pt="xl">
            <CreateBankAccountModal opened={createBankAccountModalOpened} close={closeBankAccountCreationModal} form={createBankAccountForm} />
            <EditBankAccountModal bankAccountId={editBankAccountModalId} opened={editBankAccountModalOpened} close={closeBankAccountEditModal} form={editBankAccountForm} />
            <Paper shadow="xs" p="xl" radius="md" mb="md">
                <Flex justify="space-between" align="baseline" mb="xl">
                    <Title order={1} size="h3">
                        {t('Bankové účty')}
                    </Title>
                    <Button size="xs" color="green.9" leftIcon={<IconPlus size="1rem" />} onClick={openBankAccountCreationModal}>{t('Nový bankový účet')}</Button>
                </Flex>

                <DataTable
                    // withBorder
                    // borderRadius="sm"
                    // withColumnBorders
                    striped
                    highlightOnHover
                    classNames={classes}
                    minHeight={150}
                    fetching={loading}
                    records={records}
                    columns={[
                        {
                            accessor: 'title',
                            title: t('Názov účtu'),
                        },
                        {
                            accessor: 'iban',
                            title: t('IBAN'),
                        },
                        {
                            accessor: 'swift',
                            title: t('BIC/SWIFT'),
                        },
                        {
                            accessor: 'actions',
                            title: t('Možnosti'),
                            textAlignment: 'right',
                            width: rem(100),
                            render: ({ id, title, invoicesCount }, index) => (
                                <Group spacing={4} position="right" noWrap>
                                    <ActionIcon
                                        variant="transparent"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            openBankAccountEditModal(id!);
                                        }}>
                                        <IconEdit size={16} stroke={1.5} />
                                    </ActionIcon>
                                    {(invoicesCount ?? 0) > 0 ? (
                                        <Tooltip label={t('Nie je možné zmazať účet ktorý sa používa.')}>
                                            <ActionIcon
                                                variant="transparent"
                                                color="gray"
                                                data-disabled
                                                sx={{ '&[data-disabled]': { pointerEvents: 'all', cursor: 'default' } }}
                                                onClick={(event) => event.preventDefault()}
                                            >
                                                <IconTrash size={16} stroke={1.5} />
                                            </ActionIcon>
                                        </Tooltip>
                                    ) : (
                                        <ActionIcon
                                            variant="transparent"
                                            color="red"
                                            onClick={(e) => {
                                                e.stopPropagation();

                                                modals.openConfirmModal({
                                                    title: `${t('Odstrániť bankový účet')} ${title}`,
                                                    centered: true,
                                                    children: (
                                                        <Text size="sm">
                                                            {t('Naozaj chcete odstrániť tento bankový účet? Po odstránení bude natrvalo vymazaný.')}
                                                        </Text>
                                                    ),
                                                    labels: { confirm: t('Odstrániť'), cancel: t('Ponechať') },
                                                    confirmProps: { color: 'red' },
                                                    onConfirm: () => api().bankAccountsApi().v1BankaccountsIdDelete(id!).then(() => {
                                                        showSuccessNotification('', t('Bankový účet bol úspešne odstránený!'));
                                                        getData();
                                                    }).catch((error) => {
                                                        if (error.response.data.message) {
                                                            showErrorNotification(t('Nepodarilo sa odstrániť bankový účet.'), error.response.data.message);
                                                        } else {
                                                            showErrorNotification('', t('Nepodarilo sa odstrániť bankový účet.'));
                                                        }
                                                    }),
                                                });
                                            }}>
                                            <IconTrash size={16} stroke={1.5} />
                                        </ActionIcon>
                                    )}
                                </Group>
                            ),
                        },
                    ]}
                    onCellClick={({ event: e, columnIndex }) => {
                        if (columnIndex == 3) {
                            e.stopPropagation();
                        }
                    }}
                    onRowClick={({id}) => {
                        openBankAccountEditModal(id!);
                    }}
                    noRecordsText={t<string>('Zatiaľ ste nevytvorili žiaden bankový účet')}
                />
            </Paper>
        </Container>
    );
}

import React, {useEffect, useState} from "react";
import {
    Button,
    Container,
    Flex,
    Grid,
    Group,
    MediaQuery,
    Paper,
    Popover,
    SegmentedControl,
    Select,
    Stack,
    Text,
    Title, useMantineTheme
} from '@mantine/core';
import {StatsGrid} from "./StatsGrid";
import RevenueChart from "./RevenueChart";
import {PerformanceResponse} from "../../api/faktury-plus";
import {IconPlus} from "@tabler/icons-react";
import {Trans, useTranslation} from "react-i18next";
import {useFakturyPlusApi} from "../../api/FakturyPlusApiContext";
import {showErrorNotification} from "../../helpers/notifications";
import {createInvoiceTableStyles, getTableProps} from "../Invoices/invoicesTableProps";
import {DataTable} from "mantine-datatable";
import {useNavigate} from "react-router-dom";
import {useCookies} from "react-cookie";
import {useMediaQuery} from "@mantine/hooks";

const useStyles = createInvoiceTableStyles();

export interface DashboardProps {
}

export function Dashboard({ ...others }: DashboardProps) {
    const theme = useMantineTheme();
    const { t } = useTranslation();
    const api = useFakturyPlusApi();
    const { classes } = useStyles();
    const navigate = useNavigate();

    const isLargerThanSm = useMediaQuery(`(min-width: ${theme.breakpoints.sm})`);

    const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
    const [loading, setLoading] = useState(true);
    const [performance, setPerformance] = useState<PerformanceResponse>();

    const [cookies, setCookie] = useCookies(['newYear']);
    const [newYearPopupOpened, setNewYearPopupOpened] = useState(false);

    const showNewYearPopup = () => {
        setTimeout(function () {
            setNewYearPopupOpened(true);
            setCookie('newYear', true);
        }, 1000);
    }

    useEffect(() => {
        if (!cookies.newYear) {
            showNewYearPopup();
        }
    }, [cookies]);

    if (!cookies.newYear) {
        showNewYearPopup();
    }

    const getData = (year?: number) => {
        setLoading(true);

        api().performanceApi().v1PerformanceGet(year)
            .then((response) => {
                console.log(response.data);

                if (!year && response.data.data?.availableYears?.length) {
                    setSelectedYear(response.data.data.availableYears[0]);
                }

                setPerformance(response.data.data);
            })
            .catch((error) => {
                if (error.response.data.message) {
                    showErrorNotification(t('Nepodarilo sa načítať prehľad'), error.response.data.message, 'load-dashboard');
                } else {
                    showErrorNotification('', t('Nepodarilo sa načítať prehľad'), 'load-dashboard');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        getData(selectedYear);
    }, [selectedYear]);

    const yearLabel = (year: number): string => {
        let currentYear = new Date().getFullYear();
        if (year == currentYear) {
            return t('Tento rok');
        } else if (year == currentYear - 1) {
            return t('Minulý rok');
        }

        return String(year);
    }

    const availableYears = performance?.availableYears?.map((year) => ({
        label: yearLabel(year),
        value: String(year)
    })) ?? [
        { label: 'Tento rok', value: String(new Date().getFullYear()) },
    ];
    // const availableYears = [
    //     { label: 'Tento rok', value: '2023' },
    //     { label: 'Minulý rok', value: '2022' },
    //     { label: '2021', value: '2021' },
    //     { label: '2020', value: '2020' },
    //     { label: '2019', value: '2019' },
    //     { label: '2018', value: '2018' },
    //     { label: '2017', value: '2017' },
    // ]

    return (
        <Container size="xl" pt="xl">
            <Grid>
                <Grid.Col span={12}>
                    <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
                        <Stack mb="sm">
                            <Title order={1} size="h3">
                                {t('Prehľad za obdobie')}
                            </Title>
                            <Popover width="target" position="bottom" offset={4} withArrow shadow="md" disabled={isLargerThanSm} opened={newYearPopupOpened} onChange={setNewYearPopupOpened}>
                                <Popover.Target>
                                    <Select
                                        data={availableYears}
                                        value={String(selectedYear)}
                                        onChange={(year) => setSelectedYear(Number(year))} />
                                </Popover.Target>
                                <Popover.Dropdown>
                                    <Text size="xs">
                                        <Trans i18nKey="Tu môžete zmeniť obdobie pre zobrazenie údajov z minulých rokov.">
                                            Tu môžete zmeniť obdobie pre zobrazenie údajov <span style={{whiteSpace: "nowrap"}}>z minulých</span> rokov.
                                        </Trans>
                                    </Text>
                                </Popover.Dropdown>
                            </Popover>
                        </Stack>
                    </MediaQuery>

                    <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
                        <Group>
                            <Title order={1} size="h3">
                                {t('Prehľad za obdobie')}
                            </Title>
                            <Popover width={230} position="right" withArrow shadow="md" disabled={!isLargerThanSm} opened={newYearPopupOpened} onChange={setNewYearPopupOpened}>
                                <Popover.Target>
                                    <SegmentedControl
                                        data={availableYears}
                                        value={String(selectedYear)}
                                        onChange={(year) => setSelectedYear(Number(year))} />
                                </Popover.Target>
                                <Popover.Dropdown>
                                    <Text size="xs">{t('Tu môžete zmeniť obdobie pre zobrazenie údajov z minulých rokov.')}</Text>
                                </Popover.Dropdown>
                            </Popover>
                        </Group>
                    </MediaQuery>
                </Grid.Col>
                <Grid.Col xl={2}>
                    <StatsGrid performance={performance} />
                </Grid.Col>
                <MediaQuery smallerThan="md" styles={{ display: 'none' }}>
                    <Grid.Col xl={10} display="flex">
                        <Paper shadow="xs" p="xl" radius="md" sx={{ flexGrow: 1 }}>
                            <RevenueChart performance={performance} />
                        </Paper>
                    </Grid.Col>
                </MediaQuery>
                <Grid.Col md={12}>
                    <Paper shadow="xs" p="xl" radius="md" mb="md">
                        <Flex justify="space-between" align="baseline" mb="xl">
                            <Title order={1} size="h3">
                                {t('Posledné faktúry')}
                            </Title>
                            <Button size="xs" color="green.9" component="a" href="/invoices/new" leftIcon={<IconPlus size="1rem" />}>{t('Nová faktúra')}</Button>
                        </Flex>

                        <DataTable
                            // withBorder
                            // borderRadius="sm"
                            // withColumnBorders
                            striped
                            highlightOnHover
                            classNames={classes}
                            minHeight={150}
                            fetching={loading}
                            records={performance?.invoices ?? []}
                            {...getTableProps(t, api, navigate, getData)}
                        />
                    </Paper>
                </Grid.Col>
            </Grid>
        </Container>
    );
}

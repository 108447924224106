import { Cookies } from 'react-cookie';
import i18n from '../i18n';
import {
    BankAccountsApi,
    Configuration,
    InvoicesApi,
    InvoiceSettingsApi,
    OrsrApi,
    PerformanceApi,
    TokenApi,
    UsersApi
} from './faktury-plus';

class FakturyPlusApi {
    private readonly authHeader: string | null;
    private cookies: Cookies;

    constructor(authHeader: string | null = null) {
        //console.log('AUTH HEADER:', authHeader);
        this.authHeader = authHeader;
        this.cookies = new Cookies();
    }

    // private accessToken = () => {
    //     // return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6IjEiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9lbWFpbGFkZHJlc3MiOiJkYW5pZWwua3VsYmFrQGdtYWlsLmNvbSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL25hbWUiOiJEYW5pZWwiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9zdXJuYW1lIjoiS3VsYmFrIiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvbW9iaWxlcGhvbmUiOiIwOTQwMzYzODk2IiwiZXhwIjoxNjc0NDIyNjg3LCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo3MDcxIiwiYXVkIjoiaHR0cHM6Ly9sb2NhbGhvc3Q6NzA3MSJ9.5ypv9g30Ooab82UmJUPLEmArx9QihQCPDt18DtOi0p4";
    //     return this.cookies.get('_auth');
    // };

    private configuration = () => {
        const openapiConfig = new Configuration();
        if (this.authHeader) {
            openapiConfig.baseOptions = {
                headers: { Authorization: this.authHeader, "Accept-Language": i18n.language },
            };
        } // else if (this.cookies.get())
        openapiConfig.basePath = 'https://api.fakturyplus.sk';
        // openapiConfig.basePath = 'https://localhost:7024';
        return openapiConfig;
    };

    public invoicesApi = () => {
        return new InvoicesApi(this.configuration());
    };

    public invoiceSettingsApi = () => {
        return new InvoiceSettingsApi(this.configuration());
    };

    public performanceApi = () => {
        return new PerformanceApi(this.configuration());
    };

    public tokenApi = () => {
        return new TokenApi(this.configuration());
    };

    public bankAccountsApi = () => {
        return new BankAccountsApi(this.configuration());
    };

    public usersApi = () => {
        return new UsersApi(this.configuration());
    };

    public orsrApi = () => {
        return new OrsrApi(this.configuration());
    };
}

export default FakturyPlusApi;

import * as React from 'react';
import { Chart } from 'react-chartjs-2';
import { PerformanceResponse } from '../../api/faktury-plus';
import { ChartOptions } from 'chart.js';
import { useTranslation } from 'react-i18next';
import {useMantineTheme} from "@mantine/core";

interface RevenueChartProps {
    performance?: PerformanceResponse;
}

const RevenueChart = (props: RevenueChartProps) => {
    const theme = useMantineTheme();
    const { t } = useTranslation();

    const months = [
        t('Január'),
        t('Február'),
        t('Marec'),
        t('Apríl'),
        t('Máj'),
        t('Jún'),
        t('Júl'),
        t('August'),
        t('September'),
        t('Október'),
        t('November'),
        t('December')
    ]

    // const labels = ['Január', 'Február', 'Marec', 'Apríl', 'Máj', 'Jún', 'Júl']; //, 'August', 'September', 'Október', 'November', 'December'];
    const labels = props.performance?.months?.map(mp => mp.month ? months[mp.month-1] : '') ?? [];


    const options: ChartOptions = {
        color: '#74819b',
        scales: {
            x: {
                grid: {
                    // drawOnChartArea: false,
                    offset: false,
                    color: '#edf2f9',
                    drawTicks: false,
                    display: false
                },
                ticks: {
                    color: '#9da9bb',
                    padding: 15
                },
                border: {
                    display: false
                }
            },
            y1: {
                type: 'linear' as const,
                display: true,
                position: 'left' as const,
                grid: {
                    color: '#edf2f9'
                },
                ticks: {
                    color: '#9da9bb',
                    padding: 10
                },
                border: {
                    display: false
                }
            },
            y2: {
                type: 'linear' as const,
                display: true,
                position: 'right' as const,
                grid: {
                    drawOnChartArea: false,
                },
                ticks: {
                    color: '#9da9bb',
                    padding: 10
                },
                border: {
                    display: false
                }
            },
        },
        plugins: {
            legend: {
                reverse: true
            }
        },
        // aspectRatio: 2
        responsive: true,
        maintainAspectRatio: false
    };

    const data = {
        labels,
        datasets: [
            {
                type: 'bar' as const,
                label: t('Obrat v mesiaci'),
                borderColor: `${theme.colors.green[3]}CC`, //'#81C784',
                backgroundColor: `${theme.colors.green[3]}AA`, //'#81C784',
                hoverBackgroundColor: `${theme.colors.green[3]}CC`,
                hoverBorderColor: `${theme.colors.green[4]}AA`,
                borderWidth: 2,
                // data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
                // data: [2400, 2400, 2800, 2400, 3200, 2200, 1640],
                data: props.performance?.months?.map(mp => mp.totalPrice) ?? [],
                yAxisID: 'y1'
            },
            {
                type: 'line' as const,
                label: t('Obrat za celé obdobie'),
                // borderColor: '#35a2eb',
                // backgroundColor: '#35a2eb',
                borderColor: `${theme.colors.blue[1]}CC`, // '#70899d',
                backgroundColor: `${theme.colors.blue[0]}CC`, //'#70899d',
                borderWidth: 2,
                fill: true,
                tension: 0.25,

                pointStyle: 'rect',
                pointRadius: 6,
                pointHoverRadius: 5,
                pointBorderWidth: 2,
                pointHoverBorderWidth: 2,
                // pointBackgroundColor: '#70899d',
                // pointStyle: false,

                // data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
                // data: [2400, 4800, 7600, 10000, 13200, 15400, 17040],
                data: props.performance?.months?.map(mp => mp.runningTotal) ?? [],
                yAxisID: 'y2'
            },
        ],
    };

    return (
        <Chart type='bar' options={options} data={data} />
    );
};

export default RevenueChart;

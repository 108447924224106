import React, {useState} from "react";
import {UseFormReturnType} from "@mantine/form";
import {EditInvoiceFormProps} from "./formDefinition";
import {
    ActionIcon,
    Alert,
    Collapse,
    Divider,
    Drawer,
    Grid,
    Group,
    rem,
    ScrollArea,
    Select,
    Text,
    TextInput
} from "@mantine/core";
import {IconAlertCircle, IconAt, IconPhone, IconSearch, IconSearchOff, IconWorld} from "@tabler/icons-react";
import {useTranslation} from "react-i18next";
import {AutofillResult, TaxType} from "../../api/faktury-plus";
import {OrsrAutofill} from "./OrsrAutofill";

export interface IssuerDrawerProps {
    form: UseFormReturnType<EditInvoiceFormProps>;
    opened: boolean;
    onClose: () => void;
}

export function IssuerDrawer({ form, opened, onClose, ...others }: IssuerDrawerProps) {
    const { t } = useTranslation();

    const [showSearch, setShowSearch] = useState(true);
    const [isFilling, setIsFilling] = useState(false);

    const fillData = (data?: AutofillResult) => {
        form.setValues({
            ...form.values,
            issuer: {
                // ...form.values.issuer,
                issuerName: '',
                taxId: '',
                taxType: TaxType.NoTaxes,
                vatId: '',
                registeredIn: '',
                phone: '',
                email: '',
                website: '',
                brn:  data?.brn ?? '',
                billingAddress: {
                    companyName: data?.companyName ?? '',
                    street: data?.street ?? '',
                    streetNumber: data?.streetNumber ?? '',
                    zip: data?.zip ?? '',
                    city: data?.city ?? '',
                    country: data?.country ?? '',
                }
            }
        });
    }

    return (
        <Drawer
            opened={opened}
            onClose={onClose}
            position="right"
            title={t('Dodávateľ')}
            size="md"
            scrollAreaComponent={ScrollArea.Autosize}
            sx={{
                '& .mantine-Drawer-header': { paddingLeft: rem(24), paddingRight: rem(19) },
                '& .mantine-Drawer-body': { paddingLeft: rem(24), paddingRight: rem(24) },
            }}
        >
            <Divider mb={rem(16)} />
            <Grid gutter="sm">
                <Grid.Col span={12} pb={0}>
                    <Group position="apart">
                        <Text size="md" color="dimmed">{t('Fakturačná adresa')}</Text>
                        <ActionIcon variant="light" color="blue" onClick={() => setShowSearch(!showSearch)}>
                            {showSearch ? (
                                <IconSearchOff size="1rem" />
                            ) : (
                                <IconSearch size="1rem" />
                            )}
                        </ActionIcon>
                    </Group>
                </Grid.Col>
                <Grid.Col span={12} py={showSearch ? undefined : '0'}>
                    <Collapse in={showSearch} transitionDuration={500}>
                        <OrsrAutofill
                            showSearch={showSearch}
                            setShowSearch={setShowSearch}
                            isFilling={isFilling}
                            setIsFilling={setIsFilling}
                            fillData={fillData}
                        />
                        <Alert icon={<IconAlertCircle size="1rem" />} color="gray">
                            {t('Automaticky doplnené údaje skontrolujte a doplňte daňové informácie (DIČ, Typ platiteľa, IČ DPH).')}
                        </Alert>
                    </Collapse>
                </Grid.Col>
                <Grid.Col span={12}>
                    <TextInput
                        label={t('Meno / Názov spoločnosti')}
                        disabled={isFilling}
                        {...form.getInputProps(`issuer.billingAddress.companyName`)} />
                </Grid.Col>
                <Grid.Col span={12}>
                    <TextInput
                        label={t('Vystavil')}
                        disabled={isFilling}
                        {...form.getInputProps(`issuer.issuerName`)} />
                </Grid.Col>
                <Grid.Col span={8}>
                    <TextInput
                        label={t('Ulica')}
                        disabled={isFilling}
                        {...form.getInputProps(`issuer.billingAddress.street`)} />
                </Grid.Col>
                <Grid.Col span={4}>
                    <TextInput
                        label={t('Číslo')}
                        disabled={isFilling}
                        {...form.getInputProps(`issuer.billingAddress.streetNumber`)} />
                </Grid.Col>
                <Grid.Col span={8}>
                    <TextInput
                        label={t('Mesto')}
                        disabled={isFilling}
                        {...form.getInputProps(`issuer.billingAddress.city`)} />
                </Grid.Col>
                <Grid.Col span={4}>
                    <TextInput
                        label={t('PSČ')}
                        disabled={isFilling}
                        {...form.getInputProps(`issuer.billingAddress.zip`)} />
                </Grid.Col>
                <Grid.Col span={12}>
                    <TextInput
                        label={t('Štát')}
                        disabled={isFilling}
                        {...form.getInputProps(`issuer.billingAddress.country`)} />
                </Grid.Col>
                <Grid.Col span={6}>
                    <TextInput
                        label={t('IČO')}
                        disabled={isFilling}
                        {...form.getInputProps(`issuer.brn`)} />
                </Grid.Col>
                <Grid.Col span={6}>
                    <TextInput
                        label={t('DIČ')}
                        disabled={isFilling}
                        {...form.getInputProps(`issuer.taxId`)} />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Select
                        label={t('Typ platiteľa')}
                        disabled={isFilling}
                        // placeholder="Pick one"
                        data={[
                            { value: TaxType.NoTaxes, label: t<string>('Neplatiteľ DPH') },
                            { value: TaxType.PaysTaxes, label: t<string>('Platiteľ DPH') },
                            { value: TaxType.RegisteredForTaxes, label: t<string>('Registrovaný podľa §7, 7a') },
                        ]}
                        {...form.getInputProps('issuer.taxType')}
                    />
                </Grid.Col>
                {form.values.issuer.taxType != TaxType.NoTaxes && (
                    <Grid.Col span={12}>
                        <TextInput
                            label={t('IČ DPH')}
                            disabled={isFilling}
                            {...form.getInputProps(`issuer.vatId`)} />
                    </Grid.Col>
                )}
                <Grid.Col span={12}>
                    <TextInput
                        label={t('Zapísaná v registri')}
                        {...form.getInputProps(`issuer.registeredIn`)} />
                </Grid.Col>
            </Grid>
            <Grid gutter="sm" mt={16} mb={8}>
                <Grid.Col span={12} pb={0}>
                    <Text size="md" color="dimmed">{t('Kontakt')}</Text>
                </Grid.Col>
                <Grid.Col span={12}>
                    <TextInput
                        label={t('Telefón')}
                        icon={<IconPhone size="1rem" />}
                        {...form.getInputProps(`issuer.phone`)} />
                </Grid.Col>
                <Grid.Col span={12}>
                    <TextInput
                        label={t('E-mail')}
                        icon={<IconAt size="1rem" />}
                        {...form.getInputProps(`issuer.email`)} />
                </Grid.Col>
                <Grid.Col span={12}>
                    <TextInput
                        label={t('Webstránka')}
                        icon={<IconWorld size="1rem" />}
                        {...form.getInputProps(`issuer.website`)} />
                </Grid.Col>
            </Grid>
        </Drawer>
    );
}
